import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";
import SimpleBar from "simplebar-react";

import NumericInput from 'react-numeric-input';

import customStyles from '../../assets/select/customStyles'
import Select from "react-select";

import moment from "moment";
import { DateTimePicker, Localization } from 'react-widgets'
import { DateLocalizer, NumberLocalizer } from 'react-widgets/IntlLocalizer';
import 'react-widgets/styles.css';

import React, { useState, useEffect, useRef } from 'react';

//filter pictures
import pic1 from '../../assets/images/mgsuite/Movements_16x16.png';

// import pic2 from '../../assets/images/mgsuite/Time_monitoring_16x16.png';
// import pic3 from '../../assets/images/mgsuite/alert_16x16.png';
// import pic4 from '../../assets/images/mgsuite/message_16x16.png';

//select calendar
import todayIcon from '../../assets/images/mgsuite/fullday.png';
import yesterdayIcon from '../../assets/images/mgsuite/fullday.png';
import thisWeekIcon from '../../assets/images/mgsuite/fullweek.png';
import lastWeekIcon from '../../assets/images/mgsuite/fullweek.png';
import thisMonthIcon from '../../assets/images/mgsuite/fullmonth.png';
import lastMonthIcon from '../../assets/images/mgsuite/fullmonth.png';

import TreeArea from "../CommonComponents/TreeArea.js";

import saveAs from 'file-saver';
import { Workbook } from 'exceljs';

import excelIcon from '../../assets/images/mgsuite/excel-icon.png';
import pdfIcon from '../../assets/images/mgsuite/pdf-icon.png';
import settingsIcon from '../../assets/images/mgsuite/settings-icon.png';

import { jsPDF } from "jspdf";
import 'jspdf-autotable'

import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  FilterRow,
  Scrolling,
  Export,
  Selection,
  Pager,
  RemoteOperations,
  HeaderFilter
} from 'devextreme-react/data-grid';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import 'devextreme/dist/css/dx.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import Button from 'devextreme-react/button';

import { locale } from "devextreme/localization";
import CustomDateLabel from "./components/CustomDateLabel";

// const allowedPageSizes = [200, 500, 1000, 'all'];

// let selectedArea=[]
let link = process.env.REACT_APP_API_URL;

let settingsopen

var dt1 = new Date();
dt1.setHours(0, 0, 0, 0);

var dt2 = new Date();
dt2.setHours(23, 59, 0, 0);

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}
var lotOccupationsStore = new CustomStore({
  key: 'id',
  load: (loadOptions) => {
    console.debug("LoadOptions %o", loadOptions);
      let params = '?';

      [
          'filter',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',     
          'take',
          'totalSummary', 
          'userData'
      ].forEach(function(i) {
          if(i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
      });
      params = params.slice(0, -1);

      return fetch(link + '/api/Logs/LotOccupationsDataGrid' + params, {headers: { Authorization: 'Bearer ' + localStorage.getItem('JWT') }
      })
          .then(handleErrors)
          .then(response => response.json())
          .then(response => {
              return {
                  data: response.data,
                  totalCount: response.totalCount,
                  summary: response.summary,
              };
          })
          .catch(() => { throw 'Network error' });
  },
});

var lotOccupationsDataSource = new DataSource({
  store: lotOccupationsStore,
});

var countingUnitLogStore = new CustomStore({
  key: 'id',
  load: (loadOptions) => {
    console.debug("LoadOptions %o", loadOptions);
      let params = '?';

      [
          'filter',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',     
          'take',
          'totalSummary', 
          'userData'
      ].forEach(function(i) {
          if(i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
      });
      params = params.slice(0, -1);

      return fetch(link + '/api/Logs/CountingUnitLogDataGrid' + params, {headers: { Authorization: 'Bearer ' + localStorage.getItem('JWT') }
      })
          .then(handleErrors)
          .then(response => response.json())
          .then(response => {
              return {
                  data: response.data,
                  totalCount: response.totalCount,
                  summary: response.summary,
              };
          })
          .catch(() => { throw 'Network error' });
  },
});

var countingUnitLogDataSource = new DataSource({
  store: countingUnitLogStore,
});








const Ereignisse = (props) => {

  // Columns for the grid with translation
  function getLotOccupationsColumns(hasLicensePlate) {

    let lotOccupationsColumns =

      [
        {
          "dataField": "carPark",
          "dataType": "string",
          "caption": props.t('elementeAllgemein.parkhaus'),
          "allowFiltering": false,
          "allowSearch": false,
          "allowSorting": false,
        },
        {
          "dataField": "levelArea",
          "dataType": "string",
          "caption": props.t('elementeAllgemein.ebene'),
          "allowFiltering": false,
          "allowSearch": false,
        },
        {
          "dataField": "elementName",
          "dataType": "string",
          "caption": props.t('allgemein.name'),
          "allowFiltering": true,
          "allowSearch": true,
          "filterOperations" : ['contains'],
        },
        {
          "dataField": "lotType",
          "dataType": "string",
          "caption": props.t('elementeAllgemein.typ'),
          "allowFiltering": true,
          "allowSearch": true,
          "filterOperations" : ['contains'],
        },
        {
          "dataField": "reserved",
          "dataType": "boolean",
          "caption": props.t('lot.reserviert'),
          "allowFiltering": true,
          "allowSearch": true,
          "allowSorting": false,
        },
        {
          "dataField": "exceeded",
          "dataType": "boolean",
          "caption": props.t('elementeAllgemein.areaWerte.Exceeded'),
          "allowFiltering": true,
          "allowSearch": true,
          "allowSorting": false,
        },
        {
          "dataField": "parkBeginn",
          "dataType": "datetime",
          "caption": props.t('lot.parkBeginn'),
          "allowFiltering": false,
          "allowSearch": false,
        },
        {
          "dataField": "parkEnd",
          "dataType": "datetime",
          "caption": props.t('lot.parkEnde'),
          "allowFiltering": false,
          "allowSearch": false,
        },
        {
          "dataField": "parkDurationInSeconds",
          "dataType": "double",
          "customizeText": parkdurationColumn_customizeText,
          "caption": props.t('allgemein.dauer') + ' DD HH:MM:SS',
          "allowFiltering": false,
          "allowSearch": false,
        },
      ]


    if (hasLicensePlate) {
      lotOccupationsColumns.splice(3, 0, {
        "dataField": "licensePlate",
        "dataType": "string",
        "caption": props.t('allgemein.kennzeichen'),
        "filterOperations" : ['contains'],
      })
    }

    return lotOccupationsColumns
  }

  function getCountingUnitLogColumns() {

    let countingUnitLogColumns =

      [
        {
          "dataField": "carPark",
          "dataType": "string",
          "caption": props.t('elementeAllgemein.parkhaus'),
          "allowFiltering": false,
          "allowSearch": false,
        },
        {
          "dataField": "elementName",
          "dataType": "string",
          "caption": props.t('allgemein.name'),
          "allowFiltering": true,
          "allowSearch": true,
          "filterOperations" : ['contains'],
        },
        {
          "dataField": "count",
          "dataType": "number",
          "caption": props.t('events.typen.Zaehlungen'),
          "allowFiltering": true,
          "allowSearch": true,
          "filterOperations" : ['contains'],
        },
        {
          "dataField": "lotTypeID",
          "dataType": "string",
          "caption": props.t('elementeAllgemein.typ'),
          "allowFiltering": true,
          "allowSearch": true,
          "filterOperations" : ['contains'],
        },
        {
          "dataField": "timestamp",
          "dataType": "datetime",
          "caption": props.t('events.spalten.zeitstempel'),
          "allowFiltering": false,
          "allowSearch": false,
        },
      ]

    return countingUnitLogColumns

  }
  function parkdurationColumn_customizeText(cellInfo) {
    let totalSeconds = cellInfo.value;

    let days = Math.floor(totalSeconds / 86400);
    totalSeconds %= 86400;
    
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = Math.floor(totalSeconds % 60);

    if (days < 10) {
      days = "0" + days
    }
    if (hours < 10) {
      hours = "0" + hours
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (seconds < 10) {
      seconds = "0" + seconds
    }

    return days + " " + hours + ":" + minutes + ":" + seconds
  }
  
  const [columns, setColumns] = useState()
  const [selectedType, setSelectedType] = useState("LotOccupations")

  const [carParkId, setCarParkId] = useState()
  const [areaId, setAreaId] = useState()

  const dataGridRef = React.createRef();

  const [dateTime1, setDateTime1] = useState(dt1);
  const [dateTime2, setDateTime2] = useState(dt2);

  const [timeHH, setTimeHH] = useState(0);
  const [timeMM, setTimeMM] = useState(0);
  const [timeDD, setTimeDD] = useState(0);

  const [timeHH2, setTimeHH2] = useState(0);
  const [timeMM2, setTimeMM2] = useState(0);
  const [timeDD2, setTimeDD2] = useState(0);

  const settingsContainer = useRef(null);

  // Custom date
  const [isCustomDate, setIsCustomDate] = useState(false);
  const selectRef = useRef(null);

  const openSelect = () => {
    selectRef.current.onMenuOpen();
    setIsCustomDate(true);
  };

  // Options for the select list for time

  const options = [
    { value: 'today', label: <div className="flex-iac settings-item"><img src={todayIcon} className="select-icon" /> {props.t('allgemein.zeitraumfilter.heute')}</div> },
    { value: 'yesterday', label: <div className="flex-iac settings-item"><img src={yesterdayIcon} className="select-icon" /> {props.t('allgemein.zeitraumfilter.gestern')}</div> },
    { value: 'thisweek', label: <div className="flex-iac settings-item"><img src={thisWeekIcon} className="select-icon" /> {props.t('allgemein.zeitraumfilter.dieseWoche')}</div> },
    { value: 'lastweek', label: <div className="flex-iac settings-item"><img src={lastWeekIcon} className="select-icon" /> {props.t('allgemein.zeitraumfilter.letzteWoche')}</div> },
    { value: 'thismonth', label: <div className="flex-iac settings-item"><img src={thisMonthIcon} className="select-icon" /> {props.t('allgemein.zeitraumfilter.diesenMonat')}</div> },
    { value: 'lastmonth', label: <div className="flex-iac settings-item"><img src={lastMonthIcon} className="select-icon" /> {props.t('allgemein.zeitraumfilter.letztenMonat')}</div> },
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: 'today', label: <div ><img src={todayIcon} className="select-icon" /> {props.t('allgemein.zeitraumfilter.heute')}</div>
  });

  $(document).on('click', function (e) {

    if (settingsopen == true && settingsContainer.current && !settingsContainer.current.contains(e.target) && e.target.id != 'settings-icon' && !$(e.target).is('.settings-item')
      && !$(e.target).children().hasClass('settings-item') && !$(e.target).hasClass('select-icon') && !$(e.target).parents('.rc-time-picker-panel').length == 1 && !$(e.target).hasClass('rc-time-picker-clear')
      && !$(e.target).hasClass('rc-time-picker-clear-icon')) {

      $("#settings-event-logs").hide()
      settingsopen = false
    }

  })

  useEffect(() => {

    $('#' + selectedType).css('background-color', '#e9ecef')

  }, [selectedType]);


  // Render the table grid

  function table() {

    locale(navigator.language);

    function settingsOpen() {
      $('#settings-event-logs').show()
      settingsopen = true
    }

    function exportCSV(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');

      exportDataGrid({
        component: dataGridRef.current.instance,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Data.xlsx');
        });
      });
      e.cancel = true;
    }

    function exportPDF() {
      const doc = new jsPDF();
      const dataGrid = dataGridRef.current.instance;

      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: dataGrid
      }).then(() => {
        doc.save('Data.pdf');
      });
    }

    return (
      <div >


        <div className="icons-container">
          <Button
            id='exportButton'
            icon={pdfIcon}
            onClick={exportPDF}
            style={{ marginRight: '10px' }}
          />
          <Button
            id='exportButton'
            icon={excelIcon}
            onClick={exportCSV}
          />
          <img src={settingsIcon} title="settings" id="settings-icon" onClick={() => settingsOpen()} />
        </div>


        <DataGrid
          ref={dataGridRef}
          // dataSource={logsSource}
          keyExpr="id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          className="left-container-se"
        >
          <Scrolling mode="standard" />
          <GroupPanel visible={true} />
          <Grouping />
          <FilterRow visible={true} />
          <Paging defaultPageSize={100} />
          <Pager
            visible={true}
            //  allowedPageSizes={allowedPageSizes}
            //  showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
          />
          <RemoteOperations filtering={true} sorting={true} paging={true} />


          {columns != null && (
            columns.map((val) => (
              <Column dataField={val.dataField} dataType={val.dataType} caption={val.caption} customizeText={val.customizeText}
               allowFiltering={val.allowFiltering} allowSearch={val.allowSearch} filterOperations={val.filterOperations} allowSorting={val.allowSorting}
               />
            ))
          )}

        </DataGrid>
      </div>
    );
  };


  // Highlight the option after selecting

  function selectType(name) {
    setSelectedType(name)
    $('.typ-container-se').css('background-color', 'unset').css('color', 'unset')
    $('#' + name).css('background-color', '#e9ecef').css('color', 'unset')
  }

  // Get the right id back from the area tree 

  function handleTreeArea(props) {
    setAreaId(props.areaId)
    setCarParkId(props.carParkId)
  }

  // Set the time after changing the dates in the select date element

  function dateTimeChange1(props) {
    setDateTime1(props)
    setIsCustomDate(true);
  }

  function dateTimeChange2(props) {
    setDateTime2(props)
    setIsCustomDate(true);
  }


  // Set the time in the right format after selection of the time

  function setTime(selected) {

    moment.updateLocale('de', {
      week: {
        dow: 1,
      },
    })

    let date1
    let date2

    switch (selected.value) {
      case 'today':
        date1 = moment()
        date2 = moment()
        break;
      case 'yesterday':
        date1 = moment().subtract(1, 'days')
        date2 = moment().subtract(1, 'days')
        break;
      case 'thisweek':
        date1 = moment().startOf('week');
        date2 = moment()
        break;
      case 'lastweek':
        date1 = moment().subtract(1, 'weeks').startOf('week');
        date2 = moment().subtract(1, 'weeks').endOf('week');
        break;
      case 'thismonth':
        date1 = moment().startOf('month');
        date2 = moment()
        break;
      case 'lastmonth':
        date1 = moment().subtract(1, 'months').startOf('month');
        date2 = moment().subtract(1, 'months').endOf('month');
    }

    dt1 = new Date(date1); dt1.setHours(0, 0, 0, 0);
    dt2 = new Date(date2); dt2.setHours(23, 59, 0, 0);

    setDateTime1(dt1)
    setDateTime2(dt2)
  }


  function changeTime(props) {
    setSelectedOption(props)
    setTime(props)
  }

  // Handle request for data for the table

  function handleRequest(props) {
    switch (selectedType) 
    {
      case "LotMovements":
        break;
      case "CountingUnitLog":
        {
        let filter = Array(['carparkid', '=', carParkId], ['areaId', '=', areaId], ['beginn', '=', dateTime1], ['end', '=', dateTime2]);

        countingUnitLogDataSource.filter(filter);
        setColumns(getCountingUnitLogColumns());
    
        console.debug('Filter: %o', countingUnitLogDataSource.filter());
        dataGridRef.current.instance.option("dataSource", countingUnitLogDataSource);
      }
        break;
      case "LotOccupations":
        {
          let filter = Array(['carparkid', '=', carParkId], ['areaId', '=', areaId], ['beginn', '=', dateTime1], ['end', '=', dateTime2]);
          if (getTimeLimitInSecondsMin() > 0) {
            filter.push(["parkmin", "=", getTimeLimitInSecondsMin()]);
          }
          if (getTimeLimitInSecondsMax() > 0) {
            filter.push(["parkmax", "=", getTimeLimitInSecondsMax()]);
          }
          lotOccupationsDataSource.filter(filter);
          setColumns(getLotOccupationsColumns(true));
      
          console.debug('Filter: %o', lotOccupationsDataSource.filter());
          dataGridRef.current.instance.option("dataSource", lotOccupationsDataSource);
        }
        break;
    }
  }

  function getTimeLimitInSecondsMin()
  {
    return getTimeLimitInSeconds(timeDD, timeHH, timeMM)
  }

  function getTimeLimitInSecondsMax()
  {
    return getTimeLimitInSeconds(timeDD2, timeHH2, timeMM2)
  }

  function getTimeLimitInSeconds(ddval, hhval, mmval) {
    let DD = 0
    let HH = 0
    let MM = 0

    ddval == null ? DD = 0 : DD = ddval
    hhval == null ? HH = 0 : HH = hhval
    mmval == null ? MM = 0 : MM = mmval

    return (DD * 1440 * 60) + (HH * 60 * 60) + MM * 60
  }

  // Render settings on the right side

  function settings() {

    return (
      <div id="settings-event-logs" className="right-container-se" ref={settingsContainer}>
        <div>
          <div className="content-name">{props.t('allgemein.einstellungen')}</div>
          <SimpleBar className="simplebar-se">

            <div className="content">
              <div className="content-group">
                <div className="content-group-name">{props.t('allgemein.typ')}</div>
                <div className="typ-container-se" onClick={() => selectType("LotOccupations")} id="LotOccupations">
                  <img src={pic1} className="typ-img-se" />
                  {props.t('events.typen.Stellplatzbelegungen')}
                </div>
                <div className="typ-container-se" onClick={() => selectType("CountingUnitLog")} id="CountingUnitLog">
                  <img src={pic1} className="typ-img-se" />
                  {props.t('events.typen.Zaehlungen')}
                </div >
                {/* <div className="typ-container-se" onClick={()=>selectType("messages")} id="messages">
     <img src={pic4} className="typ-img-se" />
     {props.t('events.typen.Meldungen')}
     </div> */}
              </div>

              <div className="content-group">
                <div className="content-group-name">{props.t('allgemein.zeitraum')}</div>
                  <div className={isCustomDate ? "select-date-input" : ""}>
                    <Select
                      ref={selectRef}
                      styles={customStyles}
                      isSearchable={false}
                      defaultValue={selectedOption}
                      onChange={changeTime}
                      options={options}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 3,
                        colors: {
                          ...theme.colors,
                          primary: '#20a0ff',
                        },
                      })}
                    />
                  </div>
                {isCustomDate && <CustomDateLabel hidden={!isCustomDate} openSelect={openSelect} showInput={setIsCustomDate}/>}
                <div style={{ width: '100%', flexDirection: 'column' }} className="flex-sb">

                  <Localization
                    date={new DateLocalizer({ culture: 'de-DE', firstOfWeek: 1 })}
                    number={new NumberLocalizer({ culture: 'de-DE' })}>

                    <div style={{ textAlign: 'left', padding: '5px', width: '100%', marginTop: '5px' }}>{props.t('allgemein.von')}</div>
                    <DateTimePicker
                      includeTime
                      parse={str => moment(str, 'DD/MM/YYYY HH:mm').toDate() <= new Date() ? moment(str, 'DD/MM/YYYY HH:mm').toDate() : new Date()}
                      style={{ width: '100%' }}
                      className="datetime-picker"
                      value={dateTime1}
                      max={new Date()}
                      time={true}
                      onChange={dateTimeChange1} />
                    <div style={{ textAlign: 'left', padding: '5px', width: '100%' }}>{props.t('allgemein.bis')}</div>
                    <DateTimePicker
                      includeTime
                      parse={str => moment(str, 'DD/MM/YYYY HH:mm').toDate() <= new Date() ? moment(str, 'DD/MM/YYYY HH:mm').toDate() : new Date()}
                      style={{ width: '100%' }}
                      className="datetime-picker"
                      value={dateTime2}
                      min={dateTime1}
                      max={new Date()}
                      time={true}
                      onChange={dateTimeChange2} />

                  </Localization>

                </div>
              </div>

              {selectedType == "LotOccupations" && (

                <div className="content-group">
                  <div className="content-group-name">{props.t('lot.parkDauer')}</div>

                  <div >
                    <div style={{ marginBottom: '5px' }}>{props.t('allgemein.minimum')}</div>
                    <div className="flex-sb" >
                      <div className="flex-sb ">
                        <div style={{ marginRight: '5px' }}>{props.t('events.tage')}</div>
                        <NumericInput value={timeDD} defaultValue={0} onChange={e => setTimeDD(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={999} />
                      </div>
                      <div className="flex-sb ">
                        <div style={{ marginRight: '5px', marginLeft: '5px' }}>{props.t('events.std')}</div>
                        <NumericInput value={timeHH} defaultValue={0} onChange={e => setTimeHH(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={23} />
                      </div>
                      <div className="flex-sb ">
                        <div style={{ marginRight: '5px', marginLeft: '5px' }}>{props.t('events.min')}</div>
                        <NumericInput value={timeMM} defaultValue={0} onChange={e => setTimeMM(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={59} />
                      </div>

                    </div>
                  </div>
                  <div style={{ marginTop: '15px' }} >
                    <div style={{ marginBottom: '5px' }} >{props.t('allgemein.maximum')}</div>
                    <div className="flex-sb" >
                      <div className="flex-sb ">
                        <div style={{ marginRight: '5px' }}>{props.t('events.tage')}</div>
                        <NumericInput value={timeDD2} defaultValue={0} onChange={e => setTimeDD2(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={999} />
                      </div>
                      <div className="flex-sb ">
                        <div style={{ marginRight: '5px', marginLeft: '5px' }}>{props.t('events.std')}</div>
                        <NumericInput value={timeHH2} defaultValue={0} onChange={e => setTimeHH2(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={23} />
                      </div>
                      <div className="flex-sb ">
                        <div style={{ marginRight: '5px', marginLeft: '5px' }}>{props.t('events.min')}</div>
                        <NumericInput value={timeMM2} defaultValue={0} onChange={e => setTimeMM2(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={59} />
                      </div>

                    </div>
                  </div>

                </div>

              )}

              <div className="content-group" >
                <div className="content-group-name">{props.t('elementeAllgemein.area')}</div>
                <div className="content-areas-se" style={{ height: '200px' }}>
                  <SimpleBar style={{ height: '200px' }}>
                    <TreeArea multi={false} page={"events"} onChange={(props) => handleTreeArea(props)}> </TreeArea>
                  </SimpleBar>
                </div>
                <button class="button" style={{ marginTop: '10px', marginBottom: '10px' }} onClick={handleRequest}>{props.t('allgemein.senden')}</button>

              </div>
            </div>
          </SimpleBar>
        </div>

      </div>
    )
  }


  return (

    <div className="pagewrapper-2">
      {table()}
      {settings()}
    </div>

  )

}

export default withNamespaces()(Ereignisse)
