import axios from "axios";

const updateMode = (data) => { 

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')

              axios.put(link+'/api/'+data.Type+'/Mode?mode='+data.Mode, data.Id, {
       
                headers: {
                  Authorization:'Bearer ' + jwt
               }
              
              })  .then((response) => {         
                // updateSvg()
              }); 
  
   //multiple ids

   //
 
    
  }


  export default (updateMode);