import { hideSvgModal } from "../../Functions/hideSvgModal.js";
import updateLightIntensity from "../../Functions/updateLightIntensity.js";
import updateMode from "../../Functions/updateMode.js";
import updateReserved from "../../Functions/updateReserved.js";

// type UpdateModeProps = {
//     mode: string,
//     contextId: string,
//     type: string,
//     contextName: string,
// }

// type UpdateReservedProps = {
//     reserved: string,
//     contextId: string,
//     type: string,
// }

// type UpdateLightIntensityProps = {
//     contextId: string,
//     contextName: string,
//     type: string,
// }

export const handleUpdateMode = ({ mode, contextId, type, contextName }) => {
  updateMode({ Id: [contextId], Name: contextName, Mode: mode, Type: type });
  hideSvgModal();
};

export const handleUpdateReserved = ({ reserved, contextId, type }) => {
  updateReserved({ Id: [contextId], Reserved: reserved, Type: type });
  hideSvgModal();
};

export const handleUpdateLightIntensity = ({
  contextId,
  contextName,
  value,
  type,
}) => {
  updateLightIntensity({
    Id: [contextId],
    Name: contextName,
    Value: value,
    Type: type,
  });
  hideSvgModal();
};
