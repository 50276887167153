import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import NumericInput from 'react-numeric-input';

let constructedValueInitial
let occupiedValueInitial

  const SettingsCountingArea = (props) =>  {

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')

    let data=props.data
  
    const [constructedValue, setConstructedValue] = useState();
    const [occupiedValue, setOccupiedValue] = useState();

function onChangeConstructed(value) {
    setConstructedValue(value)
  }

  function onChangeOccupied(value) {
    setOccupiedValue(value)
  }
  
  function updateCountingArea(type) {

    if (type=="Constructed") {

      if (constructedValueInitial != constructedValue) {

        axios.put(link+'/api/Area/ExternalConstructed?areaId='+data.id+'&capacity='+constructedValue, {
          headers: {
            Authorization:'Bearer ' + jwt
         }
          
          })  .then((response) => {  

            constructedValueInitial=constructedValue
              
          })
        
          .catch(error => {
         
         })

  } }

  else if (type=="Occupied") {

    if (occupiedValueInitial != occupiedValue) {

      axios.put(link+'/api/Area/ExternalOccupation?areaId='+data.id+'&occupation='+occupiedValue, {
        headers: {
          Authorization:'Bearer ' + jwt
       }
        
        })  .then((response) => {  

          occupiedValueInitial=occupiedValue
            
        })
      
        .catch(error => {
       
       })

}

}
    }

    

 
    useEffect(() => {

     setConstructedValue(null)
     setOccupiedValue(null)

    if (data.areaValues.constructed!=undefined) {
      setConstructedValue(data.areaValues.constructed)
      constructedValueInitial=data.areaValues.constructed
    }
   
    if (data.areaValues.displayValueForOccupied!=undefined) {
      setOccupiedValue(data.areaValues.displayValueForOccupied)
      occupiedValueInitial=data.areaValues.displayValueForOccupied
    }

  
 }, [props.data]);



return (
  <>
  <div className="settings-groups"> 
<div className="settings-name">{props.t('elementeAllgemein.areaWerte.Constructed')}</div> 
<NumericInput className="input-numeric input100" mobile={"auto"} value={constructedValue} min={0} max={1000} onChange={onChangeConstructed} />
<button class="button" style={{marginTop:'10px'}} onClick={()=>updateCountingArea("Constructed")}> {props.t('allgemein.speichern')}</button> 
</div> 
  <div className="settings-groups"> 
  <div className="settings-name">{props.t('elementeAllgemein.areaWerte.Occupied')}</div> 
  <NumericInput className="input-numeric input100" mobile={"auto"} value={occupiedValue} min={0} max={1000} onChange={onChangeOccupied} />
  <button class="button" style={{marginTop:'10px'}} onClick={()=>updateCountingArea("Occupied")}> {props.t('allgemein.speichern')}</button> 
  </div> 
  </>
)

}




export default withNamespaces()(SettingsCountingArea)