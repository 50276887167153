import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';


  const SettingsBarrierTable = (props) =>  {

return (
  <div className="settings-groups"> 
<table id="barrierTable">
<thead>
<th >{props.t('allgemein.info')}</th>
<th >{props.t('allgemein.modus')}</th>
<th >{props.t('elementeAllgemein.areaWerte.RealFree')}</th>
<th >{props.t('allgemein.anzeigewert')}</th>
<th >{props.t('elementeAllgemein.area')}</th>
<th >{props.t('elementeAllgemein.areaPfad')}</th>
</thead>
<tbody>
  <td>Data</td>
  <td>Data</td>
  <td>Data</td>
  <td>Data</td>
  <td>Data</td>
  <td>Data</td>
  </tbody>
</table>
</div> 
)
}




export default withNamespaces()(SettingsBarrierTable)