import React, { useState, useEffect} from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import SelectMultiple from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const MenuProps = { PaperProps: {style: {maxHeight: 250,marginTop:30,},},getContentAnchorEl: null};

let names=[]

const SettingsTags = (props) =>  {

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')
  let tags=JSON.parse(localStorage.getItem('tags'))

  let data=props.data
  let settingsType=props.type

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

  let multipleGroups=[]
  
  const [selectedMultiple, setSelectedMultiple] = useState(multipleGroups);

  function getNames() {

    names=[]
 
    for (let i =0;i < tags.length; i++) {

      // if (tags[i].elementType == settingsType && tags[i].carParkId == data.carParkId) {
      //   names.push (
      //     { value: tags[i].id, label: tags[i].title }
      //   )
      // }

      if (tags[i].elementType == settingsType) {
        names.push (
          { value: tags[i].id, label: tags[i].title }
        )
      }


      }  
       
  }

  getNames()


    function renderMultiple(selectedMultiple) {
      let array=[]

      for (let i =0;i < tags.length; i++) {
          if (selectedMultiple.includes(tags[i].id)) array.push(tags[i].title);
      }
     
      return array.join(', ')
     }
     
     const multipleChange = (event) => {
       setSelectedMultiple(event.target.value)
   };




  const multipleClose = (event) => {

        axios.put(link+'/api/'+settingsType+'/tags?elementId='+data.id, selectedMultiple, {

          headers: { "Content-type": "application/json" },
     
        })  .then((response) => {     
          multipleGroups=selectedMultiple
        })
    
        .catch(error => {
          setSelectedMultiple(multipleGroups)
       })
      
  
  };
  
  useEffect(() => {

      if (data.tagIds!=null || data.tagIds !=undefined) {
        for (let i =0; i < data.tagIds.length ;i++) {
          for (let j =0; j < names.length ;j++) {
            if (data.tagIds[i]==names[j].value) {
              multipleGroups.push(names[j].value)    
            }
          }
          }
      }
          setSelectedMultiple(multipleGroups)

  }, [props.data]);

    function render() {

            return (

              <SelectMultiple  className="select" style={{width:'100%'}} multiple value={selectedMultiple} onChange={multipleChange} onClose={multipleClose} input={<Input />} renderValue={renderMultiple} MenuProps={MenuProps} >
        {names.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            <Checkbox  color="primary" size="small" checked={selectedMultiple.indexOf(item.value) > -1} />
            <ListItemText primary={item.label}/>
          </MenuItem>
        ))} </SelectMultiple>
              
            
            )}   
 
            
    return(
      <div className="settings-groups"> 
      <div className="settings-name">Tags</div> 
      {render()}
      </div> 
    )
  }

  export default React.memo(withNamespaces()(SettingsTags))