import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";

import NumericInput from 'react-numeric-input';

let initialLT = []
let modifiedLT = []
let currentValue = null


const SettingsBufferHysteresis = (props) => {

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')
  let types = JSON.parse(localStorage.getItem('lotTypes'))

  const [makeUpdate, setMakeUpdate] = useState();
  let data = props.data

  useEffect(() => {

    currentValue = null
    initialLT = []
    modifiedLT = []


    for (let i = 0; i < types.length; i++) {

      let jsonObject = {}

      jsonObject.lotType = types[i].id
      jsonObject.buffer = 0
      jsonObject.hysteresis = 0

      initialLT.push(jsonObject)
      modifiedLT.push({ ...jsonObject })
    }



    for (let i = 0; i < data.lotTypeAreaValues.length; i++) {

      for (let j = 0; j < initialLT.length; j++) {

        if (data.lotTypeAreaValues[i].lotType == initialLT[j].lotType) {
          initialLT[j].buffer = data.lotTypeAreaValues[i].buffer
          initialLT[j].hysteresis = data.lotTypeAreaValues[i].hysteresis
        }
      }

      for (let j = 0; j < modifiedLT.length; j++) {

        if (data.lotTypeAreaValues[i].lotType == modifiedLT[j].lotType) {
          modifiedLT[j].buffer = data.lotTypeAreaValues[i].buffer
          modifiedLT[j].hysteresis = data.lotTypeAreaValues[i].hysteresis
        }
      }


    }






    setMakeUpdate(current => !current)

  }, [props.data]);








  function updateBufferHysteresis() {

    for (let i = 0; i < initialLT.length; i++) {
      for (let j = 0; j < modifiedLT.length; j++) {
        if (initialLT[i].lotType == modifiedLT[j].lotType) {
          if (initialLT[i].buffer != modifiedLT[j].buffer) {

            let type1 = "BufferLotType?"
            let type2 = "&lotTypeId="
            let type2_val = modifiedLT[j].lotType
            let type3 = "&buffer"
            let type3_val = modifiedLT[j].buffer

            axios.put(link + '/api/Area/' + type1 + 'elementId=' + data.id + type2 + type2_val + type3 + '=' + type3_val, {
              headers: {
                Authorization: 'Bearer ' + jwt
              }
            }).then((response) => {
              initialLT[i].buffer = modifiedLT[j].buffer
            })

              .catch(error => {

              })

          }

          if (initialLT[i].hysteresis != modifiedLT[j].hysteresis) {
            let type1 = "HysteresisLotType?"
            let type2 = "&lotTypeId="
            let type2_val = modifiedLT[j].lotType
            let type3 = "&hysteresis"
            let type3_val = modifiedLT[j].hysteresis

            axios.put(link + '/api/Area/' + type1 + 'elementId=' + data.id + type2 + type2_val + type3 + '=' + type3_val, {
              headers: {
                Authorization: 'Bearer ' + jwt
              }
            }).then((response) => {
              initialLT[i].hysteresis = modifiedLT[j].hysteresis
            })

              .catch(error => {

              })

          }
        }
      }
    }

  }



  function generateTypes() {
    let typesArray = []
    let typeName

    for (let i = 0; i < types.length; i++) {

      for (let j = 0; j < modifiedLT.length; j++) {

        if (types[i].id == modifiedLT[j].lotType) {

          let id = types[i].id
          let buffer = modifiedLT[j].buffer
          let hysteresis = modifiedLT[j].hysteresis

          function passData(id, type) {
            for (let i = 0; i < modifiedLT.length; i++) {
              if (modifiedLT[i].lotType == id) {
                if (type == "b") { modifiedLT[i].buffer = currentValue }
                else if (type == "h") { modifiedLT[i].hysteresis = currentValue }
              }
            }

          }

          function onChange(e) {
            currentValue = e
          }

          typeName = types[i].name

          typesArray.push(
            <div className="flex-sb" style={{ marginTop: '3px' }}>
              <div>{typeName}</div>
              <div className="flex-sb" style={{ width: '150px' }}>
                <NumericInput className="input-numeric input70" id="BHLTB" onChange={e => { onChange(e); passData(id, "b") }} value={buffer} mobile={"auto"} min={0} max={99} />
                <NumericInput className="input-numeric input70" id="BHLTH" onChange={e => { onChange(e); passData(id, "h") }} value={hysteresis} mobile={"auto"} min={0} max={99} />
              </div>
            </div>
          )

        }
      }
    }
    return typesArray
  }

  function reset(type2) {
    for (let i = 0; i < modifiedLT.length; i++) {
      if (type2 == "h") {
        modifiedLT[i].hysteresis = 0
      }
      else {
        modifiedLT[i].buffer = 0
      }
    }
    $('#BHLT' + type2).val(0)
    currentValue = 0
    setMakeUpdate(current => !current)
  }



  return (
    <div className="settings-groups">
      <div className="settings-name">{props.t('elementeAllgemein.labelPufferHysterese')}</div>
      <div className="flex-sb" style={{ fontSize: '10px', marginBottom: '3px', fontWeight: 'bold' }}>
        <div>{props.t('elementeAllgemein.kundengruppe')}</div>
        <div className="flex-sa" style={{ width: '150px' }}>
          <div style={{ width: '70px' }}>{props.t('elementeAllgemein.puffer')}</div>
          <div style={{ width: '70px' }}>{props.t('elementeAllgemein.hysterese')}</div>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <div className="flex-sb" style={{ fontSize: '10px', marginBottom: '3px', fontWeight: 'bold' }}>
          <div>{props.t('elementeAllgemein.typ')}</div>
          <div className="flex-sa" style={{ width: '150px' }}>
            <div style={{ width: '70px' }}>{props.t('elementeAllgemein.puffer')}</div>
            <div style={{ width: '70px' }}>{props.t('elementeAllgemein.hysterese')}</div>
          </div>
        </div>
        {generateTypes()}
        <div className="flex-sb" style={{ fontSize: '10px', marginTop: '3px' }}>
          <div></div>
          <div className="flex-sb" style={{ width: '150px' }}>
            <button class="button" style={{ width: '70px', cursor: 'pointer' }} onClick={() => reset("LT", "b")}>Reset</button>
            <button class="button" style={{ width: '70px', cursor: 'pointer' }} onClick={() => reset("LT", "h")}>Reset</button>
          </div>
        </div>
      </div>
      <button class="button" style={{ marginTop: '5px' }} onClick={updateBufferHysteresis}> {props.t('allgemein.speichern')}</button>
    </div>
  )
}


export default withNamespaces()(SettingsBufferHysteresis)

