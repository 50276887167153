import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import NumericInput from 'react-numeric-input';

let lightValueInitial

  const SettingsLightIntensity = (props) =>  {

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')

    let data=props.data
    let settingsType=props.type

    const [lightValue, setLightValue] = useState();

function onChangeLightIntensity(value) {
    setLightValue(value)
  }
  
  function updateLightIntensity() {

    if (lightValueInitial != lightValue) {

        axios.put(link+'/api/'+settingsType+'/LightIntensity?newIntensity='+lightValue, [data.id], {
          headers: {
            Authorization:'Bearer ' + jwt
         }
          
          })  .then((response) => {  

            lightValueInitial=lightValue
              
          })
        
          .catch(error => {
         
         })

  }}
 
    useEffect(() => {

     setLightValue(null)

    if (data.lightIntensity_Current!=undefined) {
        setLightValue(data.lightIntensity_Current)
        lightValueInitial=data.lightIntensity_Current
    }

    else if (data.lightIntensity!=undefined) {
        setLightValue(data.lightIntensity)
        lightValueInitial=data.lightIntensity
    }
  
 }, [props.data]);



return (
  <div className="settings-groups"> 
<div className="settings-name">{props.t('allgemein.helligkeit')} %</div> 
<NumericInput className="input-numeric input100" mobile={"auto"} value={lightValue} min={0} max={100} onChange={onChangeLightIntensity} />
<button class="button" style={{marginTop:'10px'}} onClick={updateLightIntensity}> {props.t('allgemein.speichern')}</button> 
</div> 
)
}




export default withNamespaces()(SettingsLightIntensity)