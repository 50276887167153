import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import {Link } from 'react-router-dom';
import user from '../../assets/images/users/profile.png';

const ProfileMenu = (props) => {

   const [menu, setMenu] = useState(false);
   const [userName, setUserName] = useState(localStorage.getItem('userName'));
  
  return (
    <React.Fragment>
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="flex-iac" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img src={user} style={{height:'22px',width:'22px',marginRight:'5px'}}/>
                        <span className="profile-name" style={{fontSize:'13px',marginLeft:'5px'}}>{userName}</span>
                        <i className="mdi mdi-chevron-down profile-name" style={{fontSize:'13px',marginLeft:'5px'}}></i>
                    </DropdownToggle>
                    <DropdownMenu right>  
                    <Link to="profile" className="dropdown-item">
                    <i className="bx bx-user" style={{fontSize:'16px',marginRight:'5px'}}></i>
                        <span>{props.t('allgemein.profil')}</span>   
                        </Link>
                        <a href="login" className="dropdown-item" onClick={()=>localStorage.clear()}>
                            <i className="bx bx-power-off text-danger" style={{fontSize:'16px',marginRight:'5px'}}></i>
                            <span>{props.t('Logout')}</span>
                        </a>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
  );
}



export default withNamespaces()(ProfileMenu);

