import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

import translationGr from './locales/gr/translation.json';
import translationENG from './locales/eng/translation.json';

// the translations
const resources = {
  gr: {
    translation: translationGr
  },
  eng: {
    translation: translationENG
  }
};


i18n 
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng"),
    fallbackLng: "gr", // use gr if detected lng is not available

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })


  document.getElementsByTagName("html")[0].setAttribute("lang", localStorage.getItem("i18nextLng")) 
  ;

export default i18n;