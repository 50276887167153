import React, { useState, useEffect } from "react";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import ReactLoading from "react-loading";

import $ from "jquery";
import Checkbox from "@material-ui/core/Checkbox";

//collapse/expand pictures
import dd1Icon from "../../assets/images/mgsuite/collapse.png";
import dd2Icon from "../../assets/images/mgsuite/expand.png";

import jwt_decode from "jwt-decode";

let dd1 = dd1Icon;
let dd2 = dd2Icon;

let oneParkingLot;

let parkMainId = 0;

let mainParkingLots = [];
let subParkingLots = [];
let areaParkingLots = [];

let sites = [];
let statefirstarea = {};

let statemain = {};
let statesub = {};
let statemainarea = {};
let statesubarea = {};

let countingAreas = [];

let pageLoaded = false;

let areaMultiple;
let selectedAreas = [];
let selectedAreasNames = [];

// Generate area tree for all areas

const TreeArea = (props) => {
  areaMultiple = props.multi;

  let link = localStorage.getItem("link");

  const [makeUpdate, setMakeUpdate] = useState(false);

  const [loaderShow, setLoaderShow] = useState(false);

  const [showLevelMain, setShowLevelMain] = useState({});
  const [showLevelSub, setShowLevelSub] = useState({});
  const [showLevelFirst, setShowLevelFirst] = useState(statefirstarea);

  function selectArea(carParkId, id, name, e) {
    if ($(e.target).is("img")) {
      return;
    }

    if (areaMultiple == true) {
      if (selectedAreas.includes(id)) {
        selectedAreas = selectedAreas.filter((value) => value != id);
        selectedAreasNames = selectedAreasNames.filter(
          (value) => value != name
        );
      } else {
        if (id == parkMainId) {
          selectedAreas = [];
          selectedAreas.push(id);
          selectedAreasNames = [];
          selectedAreasNames.push(name);
        } else {
          selectedAreas = selectedAreas.filter((value) => value != parkMainId);

          for (let i = 0; i < subParkingLots.length; i++) {
            if (subParkingLots[i].parentAreaId == id) {
              selectedAreas = selectedAreas.filter(
                (value) => value != subParkingLots[i].id
              );
              selectedAreasNames = selectedAreasNames.filter(
                (value) => value != subParkingLots[i].name
              );
              for (let j = 0; j < areaParkingLots.length; j++) {
                if (areaParkingLots[j].parentAreaId == subParkingLots[i].id) {
                  selectedAreas = selectedAreas.filter(
                    (value) => value != areaParkingLots[j].id
                  );
                  selectedAreasNames = selectedAreasNames.filter(
                    (value) => value != areaParkingLots[j].name
                  );
                }
              }
            } else if (subParkingLots[i].id == id) {
              selectedAreas = selectedAreas.filter(
                (value) => value != subParkingLots[i].parentAreaId
              );
              selectedAreasNames = selectedAreasNames.filter(
                (value) => value != subParkingLots[i].parentAreaName
              );
            }
          }

          for (let i = 0; i < areaParkingLots.length; i++) {
            if (areaParkingLots[i].parentAreaId == id) {
              selectedAreas = selectedAreas.filter(
                (value) => value != areaParkingLots[i].id
              );
              selectedAreasNames = selectedAreasNames.filter(
                (value) => value != areaParkingLots[i].name
              );
            } else if (areaParkingLots[i].id == id) {
              selectedAreas = selectedAreas.filter(
                (value) => value != areaParkingLots[i].parentAreaId
              );
              selectedAreasNames = selectedAreasNames.filter(
                (value) => value != areaParkingLots[i].parentAreaName
              );

              for (let j = 0; j < subParkingLots.length; j++) {
                if (areaParkingLots[i].parentAreaId == subParkingLots[j].id) {
                  selectedAreas = selectedAreas.filter(
                    (value) => value != subParkingLots[j].parentAreaId
                  );
                  selectedAreasNames = selectedAreasNames.filter(
                    (value) => value != subParkingLots[j].parentAreaName
                  );
                }
              }
            }
          }

          selectedAreas.push(id);
          selectedAreasNames.push(name);
        }
      }
    } else {
      if (id == selectedAreas[0]) {
        selectedAreas = [];
      } else {
        selectedAreas = [];
        selectedAreas[0] = id;
      }
    }

    if (areaMultiple == true) {
      props.onChange({
        carParkId: carParkId,
        areaId: selectedAreas[0],
        areaIds: selectedAreas,
        areaName: name,
        areaNames: selectedAreasNames,
      });
    } else {
      props.onChange({
        carParkId: carParkId,
        areaId: id,
        areaIds: selectedAreas[0],
        areaName: name,
        areaNames: selectedAreasNames[0],
      });
    }

    setMakeUpdate((current) => !current);
  }

  useEffect(() => {
    selectedAreas = [];
    setMakeUpdate((current) => !current);
  }, [props.page, props.type]);

  useEffect(() => {
    if (pageLoaded) {
      setLoaderShow(false);
    } else {
      setLoaderShow(true);
    }

    let tokenExpired = null;

    function delay(timer) {
      return new Promise((resolve) => {
        setTimeout(function () {
          if (localStorage.getItem("JWT")) {
            let decoded = jwt_decode(localStorage.getItem("JWT"));
            let diff = decoded.exp * 1000 - Date.now();

            if (diff <= 0) {
              tokenExpired = true;
            } else {
              tokenExpired = false;
            }
          }

          resolve();
        }, timer);
      });
    }

    async function getAreaData() {
      while (tokenExpired == null || tokenExpired == true) await delay(50);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("JWT");

      axios.get(link + "/api/Area/sites").then((response) => {
        sites = [];
        sites = response.data;

        for (let i = 0; i < sites.length; i++) {
          statefirstarea[i] = true;
        }

        setMakeUpdate((current) => !current);
      });

      axios
        .get(link + "/api/Area")
        .then((response) => {
          mainParkingLots = [];
          subParkingLots = [];
          areaParkingLots = [];

          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].areaType == 2) {
              mainParkingLots.push(response.data[i]);
            }

            if (response.data[i].areaType == 3) {
              subParkingLots.push(response.data[i]);
            }

            if (
              response.data[i].areaType == 4 ||
              response.data[i].areaType == 5
            ) {
              areaParkingLots.push(response.data[i]);
            }
          }

          for (let i = 0; i < areaParkingLots.length; i++) {
            for (let j = 0; j < mainParkingLots.length; j++) {
              if (areaParkingLots[i].parentAreaId === mainParkingLots[j].id) {
                subParkingLots.push(areaParkingLots[i]);
              }
            }
          }

          for (let i = 0; i < mainParkingLots.length; i++) {
            if (oneParkingLot) {
              statemain[i] = true;
            } else {
              statemain[i] = false;
            }

            statemainarea[i] = true;
          }

          for (let i = 0; i < subParkingLots.length; i++) {
            statesub[i] = false;
            statesubarea[i] = false;
          }

          mainParkingLots.sort(
            (a, b) => a.areaTreeSortOrder - b.areaTreeSortOrder
          );

          pageLoaded = true;
          setLoaderShow(false);
        })

        .catch((error) => {});
    }

    getAreaData();
  }, []);

  if (props.type == "loopMovements") {
    let tree = [];

    function renderChildren() {
      let children = [];
      let checked = false;

      for (let i = 0; i < countingAreas.length; i++) {
        if (selectedAreas.includes(countingAreas[i].id)) {
          checked = true;
        }
        children.push(
          <div
            className="park-container"
            onClick={(e) => selectArea(countingAreas[i].id, e)}
            id={countingAreas[i].id}
            key={"countingArea" + i}
            name={countingAreas[i].name}
          >
            <div
              className="flex-iac"
              style={{ height: "25px", paddingLeft: "30px" }}
            >
              <Checkbox
                checked={checked}
                color="primary"
                size="small"
                style={{ position: "absolute", right: "15px" }}
                id={countingAreas[i].id + "cb"}
              />
              <div style={{ marginLeft: "5px" }}>{countingAreas[i].name}</div>
            </div>
          </div>
        );
      }

      return children;
    }

    tree.push(
      <div>
        {loaderShow == true && (
          <div className="loader-mini">
            <ReactLoading
              type={"bars"}
              color={"#2a3042"}
              height={50}
              width={50}
              name={"Loading"}
            />
          </div>
        )}
        {loaderShow == false && <div>{renderChildren()}</div>}
      </div>
    );

    return tree;
  } else {
    let tree = [];

    function renderChildren(site) {
      let children = [];

      for (let i = 0; i < mainParkingLots.length; i++) {
        let correctSite = false;

        for (let j = 0; j < site.carParks.length; j++) {
          if (site.carParks[j].id == mainParkingLots[i].id) {
            correctSite = true;
          }
        }

        if (correctSite == true) {
          let dropdown = false;
          let dropdownIcon;
          let checked = false;

          if (selectedAreas.includes(mainParkingLots[i].id)) {
            checked = true;
          }

          for (let j = 0; j < subParkingLots.length; j++) {
            if (mainParkingLots[i].id == subParkingLots[j].parentAreaId) {
              dropdown = true;

              if (showLevelMain[i] == true) {
                dropdownIcon = dd2;
              } else {
                dropdownIcon = dd1;
              }
            }
          }

          children.push(
            <div
              className="park-container"
              onClick={(e) =>
                selectArea(
                  mainParkingLots[i].carParkId,
                  mainParkingLots[i].id,
                  mainParkingLots[i].name,
                  e
                )
              }
              id={mainParkingLots[i].id}
              key={"main" + i}
              name={mainParkingLots[i].name}
            >
              <div
                className="flex-iac"
                style={{ height: "25px", paddingLeft: "50px" }}
              >
                <Checkbox
                  checked={checked}
                  color="primary"
                  size="small"
                  style={{ position: "absolute", right: "15px" }}
                  id={mainParkingLots[i].id + "cb"}
                />
                {dropdown == true && (
                  <img
                    class="dropdown-icon"
                    src={dropdownIcon}
                    onClick={() =>
                      setShowLevelMain({
                        ...showLevelMain,
                        [i]: !showLevelMain[i],
                      })
                    }
                  />
                )}
                <div style={{ marginLeft: "5px" }}>
                  {mainParkingLots[i].name}
                </div>
              </div>
            </div>
          );

          for (let j = 0; j < subParkingLots.length; j++) {
            let dropdown = false;
            let dropdownIcon;
            let checked = false;

            if (selectedAreas.includes(subParkingLots[j].id)) {
              checked = true;
            }

            for (let x = 0; x < areaParkingLots.length; x++) {
              if (subParkingLots[j].id == areaParkingLots[x].parentAreaId) {
                dropdown = true;

                if (showLevelSub[j] == true) {
                  dropdownIcon = dd2;
                } else {
                  dropdownIcon = dd1;
                }
              }
            }

            if (mainParkingLots[i].id == subParkingLots[j].parentAreaId) {
              children.push(
                showLevelMain[i] == true && (
                  <div
                    className="park-container"
                    onClick={(e) =>
                      selectArea(
                        subParkingLots[j].carParkId,
                        subParkingLots[j].id,
                        subParkingLots[j].name,
                        e
                      )
                    }
                    id={subParkingLots[j].id}
                    key={"sub" + j}
                    name={subParkingLots[j].name}
                  >
                    <div
                      className="flex-iac"
                      style={{ height: "25px", paddingLeft: "70px" }}
                    >
                      <Checkbox
                        checked={checked}
                        color="primary"
                        size="small"
                        style={{ position: "absolute", right: "15px" }}
                        id={subParkingLots[j].id + "cb"}
                      />
                      {dropdown == true && (
                        <img
                          class="dropdown-icon"
                          src={dropdownIcon}
                          onClick={() =>
                            setShowLevelSub({
                              ...showLevelSub,
                              [j]: !showLevelSub[j],
                            })
                          }
                        />
                      )}
                      <div style={{ marginLeft: "5px" }}>
                        {subParkingLots[j].name}
                      </div>
                    </div>
                  </div>
                )
              );

              for (let x = 0; x < areaParkingLots.length; x++) {
                let checked = false;

                if (selectedAreas.includes(areaParkingLots[x].id)) {
                  checked = true;
                }

                if (subParkingLots[j].id == areaParkingLots[x].parentAreaId) {
                  children.push(
                    showLevelMain[i] == true && showLevelSub[j] == true && (
                      <div
                        id={areaParkingLots[x].id}
                        key={"area" + x}
                        name={areaParkingLots[x].name}
                        className="park-container"
                        onClick={(e) =>
                          selectArea(
                            areaParkingLots[x].carParkId,
                            areaParkingLots[x].id,
                            areaParkingLots[x].name,
                            e
                          )
                        }
                      >
                        <div
                          className="flex-iac"
                          style={{ height: "25px", paddingLeft: "90px" }}
                        >
                          <Checkbox
                            checked={checked}
                            color="primary"
                            size="small"
                            style={{ position: "absolute", right: "15px" }}
                            id={areaParkingLots[x].id + "cb"}
                          />
                          <div>{areaParkingLots[x].name}</div>
                        </div>
                      </div>
                    )
                  );
                }
              }
            }
          }
        }
      }

      return children;
    }

    //  let checked=false

    //  if (selectedAreas.includes(parkMainId)) {
    //   checked=true
    //  }

    tree.push(
      <div>
        {loaderShow == true && (
          <div className="loader-mini">
            <ReactLoading
              type={"bars"}
              color={"#2a3042"}
              height={50}
              width={50}
              name={"Loading"}
            />
          </div>
        )}

        {sites.map((item, idx) => {
          let dropdownIcon;

          if (showLevelFirst[idx] == true) {
            dropdownIcon = dd2;
          } else {
            dropdownIcon = dd1;
          }

          return (
            <>
              {/* <div className="park-container" onClick={(e)=>selectArea(parkMainId, e)} id={parkMainId} key={"first"+parkMainId} name={parkName}>  */}
              <div className="park-container">
                {/* <Checkbox checked={checked} color="primary" size="small"  style={{position:'absolute',right:'15px'}} id={parkMainId+"cb"}/> */}
                <div
                  className="flex-iac"
                  style={{ height: "25px", paddingLeft: "30px" }}
                >
                  <img
                    class="dropdown-icon"
                    src={dropdownIcon}
                    onClick={() =>
                      setShowLevelFirst({
                        ...showLevelFirst,
                        [idx]: !showLevelFirst[idx],
                      })
                    }
                  />
                  <div style={{ marginLeft: "5px" }}>{item.name}</div>
                </div>
              </div>

              {showLevelFirst[idx] == true && <>{renderChildren(item)}</>}
            </>
          );
        })}
      </div>
    );

    return tree;
  }
};

export default withNamespaces()(TreeArea);
