
import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import axios from "axios";
import $ from "jquery";
import PopUp from "./PopUp.js";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const CreateEditDeleteUser = (props) =>  {

let users = props.data
let link = localStorage.getItem('link')

const [popUpShow, setPopUpShow] = useState(false);
const [currentUserData, setCurrentUserData] = useState();
const [userName, setUserName] = useState(localStorage.getItem('userName'));
 const [userCanChange, setUserCanChange] = useState(localStorage.getItem('userCanChange'));
const popUpContainer = useRef(null);

useEffect(() => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
}, [localStorage.getItem('JWT')]);


$(document).on('click', function(e){

    if (popUpContainer.current && !popUpContainer.current.contains(e.target) && e.target.id!='settings-icon' && !$(e.target).is('.settings-item')
    && !$(e.target).children().hasClass('settings-item') && !$(e.target).hasClass('select-icon') && !$(e.target).parents('.rc-time-picker-panel').length==1 && !$(e.target).hasClass('rc-time-picker-clear') 
    && !$(e.target).hasClass('rc-time-picker-clear-icon') && e.target.id!='pop-up-button' ) {
   
      setPopUpShow(false)
    }

})

function editCreateUser(userData) {
  setCurrentUserData(userData)
  setPopUpShow(true)
 }

 
const deleteUser = (userData) => {

  let name=userData.userName
  let data={
    username: name
  }
 
   confirmAlert({
     message: props.t('allgemein.bestaetigen'), 
     buttons: [
       {
         label: props.t('allgemein.ja'),
         onClick: () => axios.post(link+'/api/User/delete',data, {
           headers: {
          'Content-Type': 'application/json',
       }
           
              })  .then((response) => {  
             
                  props.onChange()
              })
            
              .catch(error => {
             
             })
       },
       {
         label: props.t('allgemein.nein'),
        
       }
     ]
   });
 }

 const activateUser = (userData) => {

  let name=userData.userName
  let data={
    username: name
  }
 
   confirmAlert({
     message: props.t('allgemein.bestaetigen'), 
     buttons: [
       {
         label: props.t('allgemein.ja'),
         onClick: () => axios.post(link+'/api/User/setactive',data, {
           headers: {
          'Content-Type': 'application/json',
       }
           
              })  .then((response) => {  
             
                  props.onChange()
              })
            
              .catch(error => {
             
             })
       },
       {
         label: props.t('allgemein.nein'),
        
       }
     ]
   });
 } 

function usersList() {

    let usersList=[]

    for (let i = 0 ; i < users.length;i++) {

      if (users[i].userName!="multiGuide" && users[i].userName!=userName) {

        let activated
        let isMultiGuideAdmin
        let canChange

        users[i].activated==true ? activated=props.t('allgemein.ja') : activated=props.t('allgemein.nein')
        users[i].isMultiGuideAdmin==true ? isMultiGuideAdmin=props.t('allgemein.ja') : isMultiGuideAdmin=props.t('allgemein.nein')
        users[i].canChange==true ? canChange=props.t('allgemein.ja') : canChange=props.t('allgemein.nein')

        usersList.push(
            <tr>
            <td>{i+1}</td>
            <td>{users[i].userName}</td>
            <td>{users[i].eMail}</td>
            <td>{activated}</td>
            <td>{isMultiGuideAdmin}</td>
            <td>{canChange}</td>
            

          {userCanChange=='true' &&
         <td>
          <button className="button" id="pop-up-button" style={{width:'80px'}} onClick={()=>editCreateUser(users[i])}>{props.t('allgemein.Bearbeiten')}</button> 
          <button className="button" id="pop-up-button" style={{width:'80px',marginLeft:'10px'}} onClick={()=>deleteUser(users[i])} >{props.t('allgemein.Loeschen')}</button> 
          { users[i].activated==false &&
              <button className="button" id="pop-up-button" style={{width:'80px',marginLeft:'10px'}} onClick={()=>activateUser(users[i])}>Aktivieren</button> 
          }
          </td>
          }
           
            </tr>

             )
      }


       

    }
 
    return (
    <tbody>
    {usersList}
    </tbody>
    )
}


function popUpChange() {
  props.onChange()
  setPopUpShow(false)
}


return (

    <div>

    {popUpShow==true && (
    <div ref={popUpContainer}>
    {<PopUp type={"createEditDeleteUser"} data={currentUserData} onChange={popUpChange}> </PopUp>}
    </div>)} 

    <div className="einstellungen-box">
    <div className="box-name">{props.t('administration.createEditDeleteUser')}</div>  
    <div style={{overflow:'auto'}}>
    <table id="createEditDeleteUserTable">
    <thead>
    <tr>
          <td>#</td>
          <td>{props.t('login.labelBenutzername')}</td>
          <td>{props.t('administration.benutzer.labelEMail')}</td>
          <td>Aktiviert</td>
          <td>{props.t('administration.benutzertypen.admin')}</td>
          <td>{props.t('administration.benutzer.labelAenderung')}</td>
          
          {userCanChange=='true' &&
             <td>
             <button className="button" id="pop-up-button" style={{width:'80px'}} onClick={()=>editCreateUser()}>{props.t('allgemein.Neu')}</button> 
             </td>
          }


          
          </tr>
    </thead>
   {usersList()}
  </table>
  </div>
  </div>

  </div>
  )
}


export default withNamespaces()(CreateEditDeleteUser)