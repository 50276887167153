
import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import axios from "axios";

const PreCounter = (props) =>  {

let link = localStorage.getItem('link')

const [preCounterData, setPreCounterData] = useState([]);
const allRefs = useRef([]);
const allPreCounter = useRef();

useEffect(() => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
}, [localStorage.getItem('JWT')]);


function getPreCounter() {

    axios.get(link+'/api/PreCounter', {

        headers: {
            'Content-Type': 'application/json',
        }
      
      }
      )  .then((response) => {
 
        setPreCounterData(response.data)
           
      })   
      .catch(error => {
   
      })

}

useEffect(() => { 
    getPreCounter()
 }, []);


function editPreCounter(elementId,value) {

    const data = [{
        elementId:elementId,
        delay: parseInt(value)
    }]

 axios.put(link+'/api/PreCounter/ResetDelay', data, {

     headers: {
         'Content-Type': 'application/json',
     }
   
   }
   )  .then((response) => {

    setTimeout(getPreCounter, 1500)
        
   })   
   .catch(error => {

   })

 }

 function editAllPreCounter(value) {

   axios.put(link+'/api/PreCounter/ResetDelayAll?delay='+parseInt(value), {
    headers: {
        'Content-Type': 'application/json',
   }
    
    })  .then((response) => {  

        setTimeout(getPreCounter, 1500)
        
    })
  
    .catch(error => {
   
   })

 }



return (
    <div className="einstellungen-box">
    <div className="box-name"> {props.t('administration.preCounterSettings')}</div>
    <div style={{overflow:'auto'}}>
  <table id="preCounterTable">
  <thead>
    <th  style={{textAlign:'center'}}>{props.t('allgemein.name')}</th>
  <th style={{textAlign:'center'}}>{props.t('administration.preCounter.timeoutAktuell')}</th>
  <th  style={{textAlign:'center'}}>{props.t('administration.preCounter.timeoutNeu')}</th>
  <th  style={{textAlign:'center'}}></th>
  </thead>
  <tbody>
    {preCounterData.map((item,i)=>
    <tr>
     <td>{item.name}</td>
     <td>{item.resetDelayTimeInSeconds} {props.t('allgemein.sekundenKurz')}</td>
     <td><input type="number" min="0" style={{width:'50px',marginRight:'5px'}} ref={(ref) => allRefs.current.push(ref)}/>{props.t('allgemein.sekundenKurz')}</td>
     <td> <button class="button" onClick={()=>editPreCounter(item.id, allRefs.current[i].value)}>{props.t('allgemein.senden')}</button></td>
     </tr>   
 )}  
     <tr>
     <td>{props.t('administration.preCounter.allePrecounter')}</td>
     <td></td>
     <td><input type="number" min="0" style={{width:'50px',marginRight:'5px'}} ref={allPreCounter}/>{props.t('allgemein.sekundenKurz')}</td>
     <td> <button class="button" onClick={()=>editAllPreCounter(allPreCounter.current.value)}>{props.t('allgemein.senden')}</button></td>
     </tr>
</tbody>
</table>
</div>
</div>
  )
}


export default withNamespaces()(PreCounter)