
import React, { useState, useEffect, useRef } from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import axios from "axios";
import $ from "jquery";
import PopUp from "./PopUp.js";
import Checkbox from '@material-ui/core/Checkbox';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const CountingAreaReset = (props) => {

  let countingAreas = props.data
  let link = localStorage.getItem('link')

  const [popUpShow, setPopUpShow] = useState(false);
  const [currentcountingAreaData, setCurrentcountingAreaData] = useState();
  const popUpContainer = useRef(null);
  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
  }, [localStorage.getItem('JWT')]);

  useEffect(() => {
    axios.get(link+'/api/Area?areaType=5', {
    })  .then((response) => {
            localStorage.setItem('areaCountingAreaType',JSON.stringify(response.data))      
          })
  })


  $(document).on('click', function (e) {

    if (popUpContainer.current && !popUpContainer.current.contains(e.target) && e.target.id != 'settings-icon' && !$(e.target).is('.settings-item')
      && !$(e.target).children().hasClass('settings-item') && !$(e.target).hasClass('select-icon') && !$(e.target).parents('.rc-time-picker-panel').length == 1 && !$(e.target).hasClass('rc-time-picker-clear')
      && !$(e.target).hasClass('rc-time-picker-clear-icon') && e.target.id != 'pop-up-button') {

      setPopUpShow(false)
    }

  })

  function editCreateCountingArea(countingAreaData) {
    setCurrentcountingAreaData(countingAreaData)
    setPopUpShow(true)
  }


  const deleteCountingArea = (countingAreaData) => {

    let id = countingAreaData.id

    confirmAlert({
      message: props.t('allgemein.bestaetigen'),
      buttons: [
        {
          label: props.t('allgemein.ja'),
          onClick: () => axios.delete(link + '/api/CountingAreaReset/?id='+id).then((response) => {

            props.onChange()
          })

            .catch(error => {

            })
        },
        {
          label: props.t('allgemein.nein'),

        }
      ]
    });
  }

  function countingAreaResetList() {

    let countingAreaResetList = []

    for (let i = 0; i < countingAreas.length; i++) {
      const fetchedAreaPath = countingAreas[i].areaPath
      const areaPath = fetchedAreaPath.slice(0, -1).join(' -> ')
      const areaName = fetchedAreaPath[fetchedAreaPath.length-1]
      const daysOfWeek = [
        props.t('allgemein.tageKurz.Sunday'),
        props.t('allgemein.tageKurz.Monday'),
        props.t('allgemein.tageKurz.Tuesday'),
        props.t('allgemein.tageKurz.Wednesday'),
        props.t('allgemein.tageKurz.Thursday'),
        props.t('allgemein.tageKurz.Friday'),
        props.t('allgemein.tageKurz.Saturday')
      ]
      const chosenDaysOfWeek = countingAreas[i].weekdays.map(function(v,i) {
        return daysOfWeek[v]
      }).join(',')

      countingAreaResetList.push(
        <tr>
          <td>{areaPath}</td>
          <td>{areaName}</td>
          <td>{chosenDaysOfWeek}</td>
          <td>{countingAreas[i].resetTime}</td>
          <td>{countingAreas[i].resetValue}</td>
          <td>
            <Checkbox color="default" size="small" style={{ padding: '0px' }} checked={countingAreas[i].enabled} disabled />
          </td>
          <td>
            <button className="button" id="pop-up-button" style={{ width: '80px' }} onClick={() => editCreateCountingArea(countingAreas[i])}>{props.t('allgemein.Bearbeiten')}</button>
            <button className="button" id="pop-up-button" style={{ width: '80px', marginLeft: '10px' }} onClick={() => deleteCountingArea(countingAreas[i])} >{props.t('allgemein.Loeschen')}</button>
          </td>
        </tr>
      )

    }

    return (
      <tbody>
        {countingAreaResetList}
      </tbody>
    )
  }


  function popUpChange() {
    props.onChange()
    setPopUpShow(false)
  }

  return (
    <div>

      {popUpShow == true && (
        <div ref={popUpContainer}>
          {<PopUp type={"countingAreaReset"} data={currentcountingAreaData} onChange={popUpChange}> </PopUp>}
        </div>)}

      <div className="einstellungen-box">
        <div className="box-name">{props.t('administration.countingAreaReset')}</div>
        <div style={{ overflow: 'auto' }}>
          <table id="countingAreaResetTable">
            <thead>
              <tr>
                <th colspan="2" style={{ textAlign: 'left' }}>{props.t('elementeAllgemein.area')}</th>
                <th colspan="5" style={{ textAlign: 'left' }}>{props.t('allgemein.timer')}</th>
              </tr>
              <tr>
                <td>{props.t('elementeAllgemein.areaPfad')}</td>
                <td>{props.t('elementeAllgemein.area')}</td>
                <td>{props.t('occupancy.einstellung_helligkeitstimer.wochentage')}</td>
                <td>{props.t('administration.countingAreas.uhrzeit')}</td>
                <td>{props.t('administration.countingAreas.wert')}</td>
                <td>{props.t('allgemein.aktiv')}</td>
                <td>
                  <button className="button" id="pop-up-button" style={{ width: '50px' }} onClick={() => editCreateCountingArea()} >{props.t('allgemein.neu')}</button>
                </td>
              </tr>
            </thead>
            {countingAreaResetList()}
          </table>
        </div>
      </div>

    </div>
  )
}


export default withNamespaces()(CountingAreaReset)