import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';

  const SettingsLocation = (props) =>  {

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')
    let data=props.data

    const [name, setName] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [gpsLat, setGpsLat] = useState("");
    const [gpsLong, setGpsLong] = useState("");


  function updateLocation() {

    // let data= {
    //   name:name,
    //   street:street,
    //   city:city,
    //   gpsLat:gpsLat,
    //   gpsLong:gpsLong
    // }

    //     axios.put(link+'/api/Lot/Remark?elementId='+data.id, data, 

    //     {
    //       headers: {
    //         Authorization:'Bearer ' + jwt,
    //         'Content-Type': 'text/plain',
    //      }
          
    //       })  .then((response) => {  


              
    //       })
        
    //       .catch(error => {
         
    //      })


}
 
    useEffect(() => {

    // if (data.remark!=undefined) {
    //     setText(data.remark)
    // }

 }, [props.data]);

return (
    <div className="settings-groups"> 
    <div className="settings-name">{props.t('allgemein.standort')}</div> 
    <div className="location-div-first">{props.t('allgemein.name')}:  <input value={name} onChange={(e)=>setName(e.target.value)} /> </div>
    <div className="location-div">{props.t('allgemein.strasse')}:  <input value={street} onChange={(e)=>setStreet(e.target.value)} /> </div>
    <div className="location-div">{props.t('allgemein.stadt')}:  <input value={city} onChange={(e)=>setCity(e.target.value)} /> </div>
    <div className="location-div">GPS Lat:  <input value={gpsLat} onChange={(e)=>setGpsLat(e.target.value)} /> </div>
    <div className="location-div">GPS Long:  <input value={gpsLong} onChange={(e)=>setGpsLong(e.target.value)} /> </div>
   <button class="button" style={{marginTop:'10px'}} onClick={updateLocation}> {props.t('allgemein.speichern')}</button> 
      </div> 
)
}




export default withNamespaces()(SettingsLocation)