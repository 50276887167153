import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';

  const SettingsNoteBox = (props) =>  {

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')

    let data=props.data

    const [text, setText] = useState("");

  function updateText() {

        axios.put(link+'/api/Lot/Remark?elementId='+data.id, text, 

        {
          headers: {
            Authorization:'Bearer ' + jwt,
            'Content-Type': 'text/plain',
         }
          
          })  .then((response) => {  


              
          })
        
          .catch(error => {
         
         })


}
 
    useEffect(() => {

    if (data.remark!=undefined) {
        setText(data.remark)
    }

 }, [props.data]);

return (
    <div className="settings-groups"> 
    <div className="settings-name">{props.t('allgemein.hinweis')}</div> 
    <textarea className="textarea" value={text} onChange={(e)=>setText(e.target.value)}/>
   <button class="button" style={{marginTop:'10px'}} onClick={updateText}> {props.t('allgemein.speichern')}</button> 
      </div> 
)
}




export default withNamespaces()(SettingsNoteBox)