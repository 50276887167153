import React, { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import { withNamespaces } from "react-i18next";
import axios from "axios";

import { Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Checkbox from "@material-ui/core/Checkbox";

//modus pictures
import autoAut from "../../../../assets/images/mgsuite/Automatic_24x24.png";
import autoOcc from "../../../../assets/images/mgsuite/ManualOccupied_24x24.png";
import autoFree from "../../../../assets/images/mgsuite/ManualFree_24x24.png";

const showSecond = false;
const str = showSecond ? "HH : mm : ss" : "HH:mm";

const SettingsPopUp = (props) => {
  let link = localStorage.getItem("link");
  let types = JSON.parse(localStorage.getItem("lotTypes"));
  let data = props.data;
  let type = props.type;
  const [failShow, setFailShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [timerId, setTimerId] = useState();
  const [elementId, setElementId] = useState();

  let [weekdays, setWeekdays] = useState([]);

  const [timespanBegin, setTimespanBegin] = useState();
  const [timespanEnd, setTimespanEnd] = useState();

  const [mode, setMode] = useState();
  const [lotTypeID, setLotTypeID] = useState();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("JWT");
  }, [localStorage.getItem("JWT")]);

  useEffect(() => {
    if (props.popUpType == "modusZeit") {
      let timespanBegin = "00:00";
      let timespanEnd = "00:00";
      let weekdays = [];
      let elementId = -1;
      let timerId = -1;
      let mode = 0;

      if (data != null) {
        data.startTime != null
          ? (timespanBegin = data.startTime)
          : (timespanBegin = "00:00");
        data.endTime != null
          ? (timespanEnd = data.endTime)
          : (timespanEnd = "00:00");
        data.weekdays != null ? (weekdays = data.weekdays) : (weekdays = []);
        data.elementId != null
          ? (elementId = data.elementId)
          : (elementId = -1);
        data.timerId != null ? (timerId = data.timerId) : (timerId = -1);
        data.mode != null ? (mode = data.mode) : (mode = 0);
      }

      setTimerId(timerId);
      setElementId(elementId);
      setTimespanBegin(timespanBegin);
      setTimespanEnd(timespanEnd);
      setWeekdays(weekdays);
      setMode(mode);
    } else if (props.popUpType == "reservZeit") {
      let timespanBegin = "00:00";
      let timespanEnd = "00:00";
      let weekdays = [];
      let elementId = -1;
      let timerId = -1;

      if (data != null) {
        data.startTime != null
          ? (timespanBegin = data.startTime)
          : (timespanBegin = "00:00");
        data.endTime != null
          ? (timespanEnd = data.endTime)
          : (timespanEnd = "00:00");
        data.weekdays != null ? (weekdays = data.weekdays) : (weekdays = []);
        data.elementId != null
          ? (elementId = data.elementId)
          : (elementId = -1);
        data.timerId != null ? (timerId = data.timerId) : (timerId = -1);
      }

      setTimerId(timerId);
      setElementId(elementId);
      setTimespanBegin(timespanBegin);
      setTimespanEnd(timespanEnd);
      setWeekdays(weekdays);
    } else if (props.popUpType == "lotTypeZeit") {
      let timespanBegin = "00:00";
      let timespanEnd = "00:00";
      let weekdays = [];
      let elementId = -1;
      let timerId = -1;
      let lotTypeID = -1;

      if (data != null) {
        data.startTime != null
          ? (timespanBegin = data.startTime)
          : (timespanBegin = "00:00");
        data.endTime != null
          ? (timespanEnd = data.endTime)
          : (timespanEnd = "00:00");
        data.weekdays != null ? (weekdays = data.weekdays) : (weekdays = []);
        data.elementId != null
          ? (elementId = data.elementId)
          : (elementId = -1);
        data.timerId != null ? (timerId = data.timerId) : (timerId = -1);
        data.lotTypeID != null
          ? (lotTypeID = data.lotTypeID)
          : (lotTypeID = -1);
      }

      setTimerId(timerId);
      setElementId(elementId);
      setTimespanBegin(timespanBegin);
      setTimespanEnd(timespanEnd);
      setWeekdays(weekdays);
      setLotTypeID(lotTypeID);
    }
  }, []);

  let selectedType = props.popUpType;

  function timespanBeginChange(value) {
    setTimespanBegin(value.format(str));
  }

  function timespanEndChange(value) {
    setTimespanEnd(value.format(str));
  }

  const onWeekdaysChange = (e) => {
    if (e.target.checked === true) {
      setWeekdays([...weekdays, parseInt(e.target.value)]);
    } else if (e.target.checked === false) {
      let freshArray = weekdays.filter(
        (val) => val !== parseInt(e.target.value)
      );
      setWeekdays([...freshArray]);
    }
  };

  const onModeChange = (value) => {
    setMode(value);
  };

  const onLotTypeChange = (value) => {
    setLotTypeID(value);
  };

  function weekDays() {
    return (
      <div className="flex-iac">
        <div className="flex-iac">
          {props.t("allgemein.tageKurz.Monday")}
          <Checkbox
            color="primary"
            size="small"
            style={{ padding: "5px" }}
            value="1"
            onChange={(e) => onWeekdaysChange(e)}
            checked={weekdays.includes(1)}
          />
        </div>
        <div className="flex-iac">
          {props.t("allgemein.tageKurz.Tuesday")}
          <Checkbox
            color="primary"
            size="small"
            style={{ padding: "5px" }}
            value="2"
            onChange={(e) => onWeekdaysChange(e)}
            checked={weekdays.includes(2)}
          />
        </div>
        <div className="flex-iac">
          {props.t("allgemein.tageKurz.Wednesday")}
          <Checkbox
            color="primary"
            size="small"
            style={{ padding: "5px" }}
            value="3"
            onChange={(e) => onWeekdaysChange(e)}
            checked={weekdays.includes(3)}
          />
        </div>
        <div className="flex-iac">
          {props.t("allgemein.tageKurz.Thursday")}
          <Checkbox
            color="primary"
            size="small"
            style={{ padding: "5px" }}
            value="4"
            onChange={(e) => onWeekdaysChange(e)}
            checked={weekdays.includes(4)}
          />
        </div>
        <div className="flex-iac">
          {props.t("allgemein.tageKurz.Friday")}
          <Checkbox
            color="primary"
            size="small"
            style={{ padding: "5px" }}
            value="5"
            onChange={(e) => onWeekdaysChange(e)}
            checked={weekdays.includes(5)}
          />
        </div>
        <div className="flex-iac">
          {props.t("allgemein.tageKurz.Saturday")}
          <Checkbox
            color="primary"
            size="small"
            style={{ padding: "5px" }}
            value="6"
            onChange={(e) => onWeekdaysChange(e)}
            checked={weekdays.includes(6)}
          />
        </div>
        <div className="flex-iac">
          {props.t("allgemein.tageKurz.Sunday")}
          <Checkbox
            color="primary"
            size="small"
            style={{ padding: "5px" }}
            value="0"
            onChange={(e) => onWeekdaysChange(e)}
            checked={weekdays.includes(0)}
          />
        </div>
      </div>
    );
  }

  function modusZeitPopUp() {
    function createModusZeit() {
      const data = {
        elementId: elementId,
        startTime: timespanBegin,
        endTime: timespanEnd,
        weekdays: weekdays,
        mode: mode,
      };

      axios
        .post(link + "/api/" + type + "/mode-timer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          props.onChange();
        })
        .catch((error) => {
          setFailShow(true);
        });
    }

    function updateModusZeit() {
      const data = {
        elementId: elementId,
        timerId: timerId,
        startTime: timespanBegin,
        endTime: timespanEnd,
        weekdays: weekdays,
        mode: mode,
      };
      axios
        .put(link + "/api/" + type + "/mode-timer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          props.onChange();
        })
        .catch((error) => {
          setFailShow(true);
        });
    }

    function handleValidSubmit() {
      if (data.timerId == null) {
        createModusZeit();
      } else {
        updateModusZeit();
      }
    }

    return (
      <>
        <div className="box-name">{props.t("allgemein.zeitsteuerung")}</div>
        <AvForm
          className="form-horizontal"
          onValidSubmit={() => {
            handleValidSubmit();
          }}
          style={{ opacity: "1" }}
        >
          {props.error && props.error ? (
            <Alert color="danger">{props.error}</Alert>
          ) : null}

          <div style={{ width: "90%", margin: "auto" }}>
            <div style={{ marginTop: "10px" }}>
              <div className="content-group-name">
                {props.t("allgemein.zeitraum")}
              </div>
              <TimePicker
                showSecond={showSecond}
                className="time-input input-we2"
                onChange={timespanBeginChange}
                placeholder={timespanBegin}
              />
              &nbsp;-&nbsp;
              <TimePicker
                showSecond={showSecond}
                className="time-input input-we2"
                onChange={timespanEndChange}
                placeholder={timespanEnd}
              />
            </div>

            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                paddingBottom: "10px",
                borderBottom: "1px solid #dadadb",
              }}
            >
              <div className="content-group-name">
                {props.t("allgemein.wochentage")}
              </div>
              {weekDays()}
            </div>
            <div style={{ marginTop: "10px" }}>
              <div className="content-group-name">
                {props.t("allgemein.modus")}
              </div>
              <div className="label-container">
                <label class="input-container">
                  <input
                    type="radio"
                    checked={mode == 0}
                    id="mode-automatic"
                    name="radio-car"
                    class="input-element"
                    onClick={() => onModeChange(0)}
                  />
                  <img src={autoAut} className="settings-img" />{" "}
                  {props.t("lot.modes.Automatic")}
                </label>
              </div>
              <div className="label-container">
                <label class="input-container">
                  <input
                    type="radio"
                    checked={mode == 1}
                    id="mode-manual-free"
                    name="radio-car"
                    class="input-element"
                    onClick={() => onModeChange(1)}
                  />
                  <img src={autoFree} className="settings-img" />{" "}
                  {props.t("lot.modes.ManualFree")}
                </label>
              </div>
              <div className="label-container">
                <label class="input-container">
                  <input
                    type="radio"
                    checked={mode == 2}
                    id="mode-manual-occupied"
                    name="radio-car"
                    class="input-element"
                    onClick={() => onModeChange(2)}
                  />
                  <img src={autoOcc} className="settings-img" />{" "}
                  {props.t("lot.modes.ManualOccupied")}
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <button type="submit" className="button">
                {props.t("allgemein.speichern")}
              </button>
            </div>
            {failShow == true && (
              <div className="login-fail">{errorMessage}</div>
            )}
          </div>
        </AvForm>
      </>
    );
  }

  function reservZeitPopUp() {
    function createReservZeit() {
      const data = {
        elementId: elementId,
        startTime: timespanBegin,
        endTime: timespanEnd,
        weekdays: weekdays,
      };

      axios
        .post(link + "/api/" + type + "/reservation-timer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          props.onChange();
        })
        .catch((error) => {
          setFailShow(true);
        });
    }

    function updateReservZeit() {
      const data = {
        elementId: elementId,
        timerId: timerId,
        startTime: timespanBegin,
        endTime: timespanEnd,
        weekdays: weekdays,
      };
      axios
        .put(link + "/api/" + type + "/reservation-timer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          props.onChange();
        })
        .catch((error) => {
          setFailShow(true);
        });
    }

    function handleValidSubmit() {
      if (data.timerId == null) {
        createReservZeit();
      } else {
        updateReservZeit();
      }
    }

    return (
      <>
        <div className="box-name">{props.t("allgemein.zeitsteuerung")}</div>
        <AvForm
          className="form-horizontal"
          onValidSubmit={() => {
            handleValidSubmit();
          }}
          style={{ opacity: "1" }}
        >
          {props.error && props.error ? (
            <Alert color="danger">{props.error}</Alert>
          ) : null}

          <div style={{ width: "90%", margin: "auto" }}>
            <div style={{ marginTop: "10px" }}>
              <div className="content-group-name">
                {props.t("allgemein.zeitraum")}
              </div>
              <TimePicker
                showSecond={showSecond}
                className="time-input input-we2"
                onChange={timespanBeginChange}
                placeholder={timespanBegin}
              />
              &nbsp;-&nbsp;
              <TimePicker
                showSecond={showSecond}
                className="time-input input-we2"
                onChange={timespanEndChange}
                placeholder={timespanEnd}
              />
            </div>

            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                paddingBottom: "10px",
                borderBottom: "1px solid #dadadb",
              }}
            >
              <div className="content-group-name">
                {props.t("allgemein.wochentage")}
              </div>
              {weekDays()}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <button type="submit" className="button">
                {props.t("allgemein.speichern")}
              </button>
            </div>
            {failShow == true && (
              <div className="login-fail">{errorMessage}</div>
            )}
          </div>
        </AvForm>
      </>
    );
  }

  function lotTypeZeitPopUp() {
    function createLotTypeZeit() {
      const data = {
        elementId: elementId,
        startTime: timespanBegin,
        endTime: timespanEnd,
        weekdays: weekdays,
        lotTypeID: lotTypeID,
      };

      axios
        .post(link + "/api/" + type + "/lottype-timer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          props.onChange();
        })
        .catch((error) => {
          setFailShow(true);
        });
    }

    function updateLotTypeZeit() {
      const data = {
        elementId: elementId,
        timerId: timerId,
        startTime: timespanBegin,
        endTime: timespanEnd,
        weekdays: weekdays,
        lotTypeID: lotTypeID,
      };
      axios
        .put(link + "/api/" + type + "/lottype-timer", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          props.onChange();
        })
        .catch((error) => {
          setFailShow(true);
        });
    }

    function handleValidSubmit() {
      if (data.timerId == null) {
        createLotTypeZeit();
      } else {
        updateLotTypeZeit();
      }
    }

    return (
      <>
        <div className="box-name">{props.t("allgemein.zeitsteuerung")}</div>
        <AvForm
          className="form-horizontal"
          onValidSubmit={() => {
            handleValidSubmit();
          }}
          style={{ opacity: "1" }}
        >
          {props.error && props.error ? (
            <Alert color="danger">{props.error}</Alert>
          ) : null}

          <div style={{ width: "90%", margin: "auto" }}>
            <div style={{ marginTop: "10px" }}>
              <div className="content-group-name">
                {props.t("allgemein.zeitraum")}
              </div>
              <TimePicker
                showSecond={showSecond}
                className="time-input input-we2"
                onChange={timespanBeginChange}
                placeholder={timespanBegin}
              />
              &nbsp;-&nbsp;
              <TimePicker
                showSecond={showSecond}
                className="time-input input-we2"
                onChange={timespanEndChange}
                placeholder={timespanEnd}
              />
            </div>

            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                paddingBottom: "10px",
                borderBottom: "1px solid #dadadb",
              }}
            >
              <div className="content-group-name">
                {props.t("allgemein.wochentage")}
              </div>
              {weekDays()}
            </div>
            <div style={{ marginTop: "10px" }}>
              <div className="content-group-name">
                {props.t("allgemein.modus")}
              </div>
              {types.map((item) => (
                <div className="label-container">
                  <label class="input-container">
                    <input
                      type="radio"
                      checked={lotTypeID == item.id}
                      id="mode-automatic"
                      name="radio-car"
                      class="input-element"
                      onClick={() => onLotTypeChange(item.id)}
                    />
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <button type="submit" className="button">
                {props.t("allgemein.speichern")}
              </button>
            </div>
            {failShow == true && (
              <div className="login-fail">{errorMessage}</div>
            )}
          </div>
        </AvForm>
      </>
    );
  }

  function renderOption() {
    if (selectedType == "modusZeit") {
      return modusZeitPopUp();
    } else if (selectedType == "reservZeit") {
      return reservZeitPopUp();
    } else if (selectedType == "lotTypeZeit") {
      return lotTypeZeitPopUp();
    }
  }

  return (
    <div id="pop-up-settings-inner">
      <SimpleBar style={{ height: "100%" }}>{renderOption()}</SimpleBar>
    </div>
  );
};

export default withNamespaces()(SettingsPopUp);
