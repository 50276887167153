import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import $ from "jquery";
import SettingsPopUp from "./SettingsPopUp.js";
import { confirmAlert } from "react-confirm-alert"; // Import

const SettingsReserviertZeitTable = (props) => {
  let link = localStorage.getItem("link");

  let data = props.data;
  let type = props.type;

  const [reservZeits, setReservZeits] = useState();
  const [popUpShow, setPopUpShow] = useState(false);
  const [currentReservZeitData, setCurrentReservZeitData] = useState();
  const popUpContainer = useRef(null);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("JWT");
  }, [localStorage.getItem("JWT")]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await axios
      .get(link + "/api/" + type + "/reservation-timer?elementId=" + data.id)
      .then((response) => {
        setReservZeits(response.data);
      });
  }

  $(document).on("click", function (e) {
    if (
      popUpContainer.current &&
      !popUpContainer.current.contains(e.target) &&
      e.target.id != "settings-icon" &&
      !$(e.target).is(".settings-item") &&
      !$(e.target).children().hasClass("settings-item") &&
      !$(e.target).hasClass("select-icon") &&
      !$(e.target).parents(".rc-time-picker-panel").length == 1 &&
      !$(e.target).hasClass("rc-time-picker-clear") &&
      !$(e.target).hasClass("rc-time-picker-clear-icon") &&
      e.target.id != "pop-up-button"
    ) {
      setPopUpShow(false);
    }
  });

  function editCreateReservZeit(reservZeitData, elementId) {
    reservZeitData.elementId = elementId;
    setCurrentReservZeitData(reservZeitData);
    setPopUpShow(true);
  }

  const deleteReservZeit = (reservZeitData) => {
    let timerId = reservZeitData.timerId;

    confirmAlert({
      message: props.t("allgemein.bestaetigen"),
      buttons: [
        {
          label: props.t("allgemein.ja"),
          onClick: () =>
            axios
              .delete(
                link + "/api/" + type + "/reservation-timer?timerId=" + timerId
              )
              .then((response) => {
                // getData();
              })

              .catch((error) => {}),
        },
        {
          label: props.t("allgemein.nein"),
        },
      ],
    });
  };

  function reservZeitsList() {
    let reservZeitsList = [];

    for (let i = 0; i < reservZeits.length; i++) {
      const daysOfWeek = [
        props.t("allgemein.tageKurz.Sunday"),
        props.t("allgemein.tageKurz.Monday"),
        props.t("allgemein.tageKurz.Tuesday"),
        props.t("allgemein.tageKurz.Wednesday"),
        props.t("allgemein.tageKurz.Thursday"),
        props.t("allgemein.tageKurz.Friday"),
        props.t("allgemein.tageKurz.Saturday"),
      ];
      const chosenDaysOfWeek = reservZeits[i].weekdays
        .map(function (v, i) {
          return daysOfWeek[v];
        })
        .join(",");

      reservZeitsList.push(
        <tr key={"key_" + i}>
          <td>
            {reservZeits[i].startTime} - {reservZeits[i].endTime}
          </td>
          <td>{chosenDaysOfWeek}</td>
          <td>{props.t("allgemein.an")}</td>
          <td>
            <button
              className="button"
              id="pop-up-button"
              style={{ width: "80px" }}
              onClick={() => editCreateReservZeit(reservZeits[i], data.id)}
            >
              {props.t("allgemein.bearbeiten")}
            </button>
            <button
              className="button"
              id="pop-up-button"
              style={{ width: "80px", marginLeft: "10px" }}
              onClick={() => deleteReservZeit(reservZeits[i])}
            >
              {props.t("allgemein.loeschen")}
            </button>
          </td>
        </tr>
      );
    }

    return <tbody>{reservZeitsList}</tbody>;
  }

  function popUpChange() {
    getData();
    setPopUpShow(false);
  }

  return (
    <>
      {popUpShow == true && (
        <div ref={popUpContainer}>
          {
            <SettingsPopUp
              popUpType={"reservZeit"}
              type={type}
              data={currentReservZeitData}
              onChange={popUpChange}
            >
              {" "}
            </SettingsPopUp>
          }
        </div>
      )}
      <div className="settings-groups">
        <div className="settings-name">
          {props.t("lot.reserviert")} {props.t("allgemein.zeitsteuerung")}
        </div>
        <table id="barrierTable">
          <thead>
            <tr>
              <th>{props.t("allgemein.zeitraum")}</th>
              <th>{props.t("allgemein.wochentage")}</th>
              <th>{props.t("lot.reserviert")}</th>
              <th>
                <button
                  className="button"
                  id="pop-up-button"
                  style={{ width: "50px" }}
                  onClick={() => editCreateReservZeit({}, data.id)}
                >
                  {props.t("allgemein.neu")}
                </button>
              </th>
            </tr>
          </thead>
          {reservZeits && reservZeitsList()}
        </table>
      </div>
    </>
  );
};

export default withNamespaces()(SettingsReserviertZeitTable);
