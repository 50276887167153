import React from 'react'
import lastYearIcon from "../../../assets/images/mgsuite/fullyear.png";
import { withNamespaces } from 'react-i18next';

const CustomDateLabel = ({showInput, openSelect, hidden, t}) => {

  const handleClick = () => {
    openSelect();
    showInput(false);
  }

  return (
    <div 
        className='custom-date-input' 
        onClick={handleClick}
    >
      {!hidden && <img src={lastYearIcon} className="select-icon" /> }
      <div className='custom-date-input-text'>{t('allgemein.zeitraumfilter.sonstige')}</div>
    </div>
  )
}

export default withNamespaces()(CustomDateLabel);