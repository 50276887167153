import axios from "axios";

const updateTypes = (data) => { 

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')

  axios.put(link+'/api/'+data.Type+'/Type?typeId='+data.Value, data.Id, {
       
                headers: {
                  Authorization:'Bearer ' + jwt
               }
              
              })  .then((response) => {         
            
              }); 
  
  }




  export default (updateTypes);