import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import $ from "jquery";
import SettingsPopUp from "./SettingsPopUp.js";
import { confirmAlert } from "react-confirm-alert"; // Import

const SettingsLotTypeZeitTable = (props) => {
  let link = localStorage.getItem("link");
  let types = JSON.parse(localStorage.getItem("lotTypes"));
  let data = props.data;
  let type = props.type;

  const [lotTypeZeits, setLotTypeZeits] = useState();
  const [popUpShow, setPopUpShow] = useState(false);
  const [currentlotTypeZeitData, setCurrentLotTypeZeitData] = useState();
  const popUpContainer = useRef(null);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("JWT");
  }, [localStorage.getItem("JWT")]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await axios
      .get(link + "/api/" + type + "/lottype-timer?elementId=" + data.id)
      .then((response) => {
        setLotTypeZeits(response.data);
      });
  }

  $(document).on("click", function (e) {
    if (
      popUpContainer.current &&
      !popUpContainer.current.contains(e.target) &&
      e.target.id != "settings-icon" &&
      !$(e.target).is(".settings-item") &&
      !$(e.target).children().hasClass("settings-item") &&
      !$(e.target).hasClass("select-icon") &&
      !$(e.target).parents(".rc-time-picker-panel").length == 1 &&
      !$(e.target).hasClass("rc-time-picker-clear") &&
      !$(e.target).hasClass("rc-time-picker-clear-icon") &&
      e.target.id != "pop-up-button"
    ) {
      setPopUpShow(false);
    }
  });

  function editCreateLotTypeZeit(lotTypeZeitData, elementId) {
    lotTypeZeitData.elementId = elementId;
    setCurrentLotTypeZeitData(lotTypeZeitData);
    setPopUpShow(true);
  }

  const deleteLotTypeZeit = (lotTypeZeitData) => {
    let timerId = lotTypeZeitData.timerId;

    confirmAlert({
      message: props.t("allgemein.bestaetigen"),
      buttons: [
        {
          label: props.t("allgemein.ja"),
          onClick: () =>
            axios
              .delete(
                link + "/api/" + type + "/lottype-timer?timerId=" + timerId
              )
              .then((response) => {
                // getData();
              })

              .catch((error) => {}),
        },
        {
          label: props.t("allgemein.nein"),
        },
      ],
    });
  };

  function lotTypeZeitsList() {
    let lotTypeZeitsList = [];

    for (let i = 0; i < lotTypeZeits.length; i++) {
      const daysOfWeek = [
        props.t("allgemein.tageKurz.Sunday"),
        props.t("allgemein.tageKurz.Monday"),
        props.t("allgemein.tageKurz.Tuesday"),
        props.t("allgemein.tageKurz.Wednesday"),
        props.t("allgemein.tageKurz.Thursday"),
        props.t("allgemein.tageKurz.Friday"),
        props.t("allgemein.tageKurz.Saturday"),
      ];
      const chosenDaysOfWeek = lotTypeZeits[i].weekdays
        .map(function (v, i) {
          return daysOfWeek[v];
        })
        .join(",");

      lotTypeZeitsList.push(
        <tr key={"key_" + i}>
          <td>
            {lotTypeZeits[i].startTime} - {lotTypeZeits[i].endTime}
          </td>
          <td>{chosenDaysOfWeek}</td>
          <td>
            {types.filter((val) => val.id == lotTypeZeits[i].lotTypeID)[0].name}
          </td>
          <td>
            <button
              className="button"
              id="pop-up-button"
              style={{ width: "80px" }}
              onClick={() => editCreateLotTypeZeit(lotTypeZeits[i], data.id)}
            >
              {props.t("allgemein.bearbeiten")}
            </button>
            <button
              className="button"
              id="pop-up-button"
              style={{ width: "80px", marginLeft: "10px" }}
              onClick={() => deleteLotTypeZeit(lotTypeZeits[i])}
            >
              {props.t("allgemein.loeschen")}
            </button>
          </td>
        </tr>
      );
    }

    return <tbody>{lotTypeZeitsList}</tbody>;
  }

  function popUpChange() {
    getData();
    setPopUpShow(false);
  }

  return (
    <>
      {popUpShow == true && (
        <div ref={popUpContainer}>
          {
            <SettingsPopUp
              popUpType={"lotTypeZeit"}
              type={type}
              data={currentlotTypeZeitData}
              onChange={popUpChange}
            >
              {" "}
            </SettingsPopUp>
          }
        </div>
      )}
      <div className="settings-groups">
        <div className="settings-name">
          {props.t("allgemein.typ")} {props.t("allgemein.zeitsteuerung")}
        </div>
        <table id="barrierTable">
          <thead>
            <tr>
              <th>{props.t("allgemein.zeitraum")}</th>
              <th>{props.t("allgemein.wochentage")}</th>
              <th>{props.t("allgemein.typ")}</th>
              <th>
                <button
                  className="button"
                  id="pop-up-button"
                  style={{ width: "50px" }}
                  onClick={() => editCreateLotTypeZeit({}, data.id)}
                >
                  {props.t("allgemein.neu")}
                </button>
              </th>
            </tr>
          </thead>
          {lotTypeZeits && lotTypeZeitsList()}
        </table>
      </div>
    </>
  );
};

export default withNamespaces()(SettingsLotTypeZeitTable);
