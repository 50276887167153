import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';


  const SettingsLastActivities = (props) =>  {

return (
  <div className="settings-groups"> 
  <div className="settings-name">{props.t('dashboard.letzteBenutzeraktivitaeten')}</div> 
<table id="letzteBenutzeraktivitaeten">
<thead>
  <th >{props.t('allgemein.benutzer')}</th>
<th >{props.t('allgemein.aenderung')}</th>
<th >{props.t('allgemein.zeitstempel')}</th>
</thead>
<tbody>
  <td>Data</td>
  <td>Data</td>
  <td>Data</td>
  </tbody>
</table>
</div> 
)
}




export default withNamespaces()(SettingsLastActivities)