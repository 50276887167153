import React, { useState } from 'react';
import { Link } from "react-router-dom";

import LanguageDropdown from "../Header/LanguageDropdown";
import DefectItemsDropdown from "../Header/DefectItemsDropdown";
import ProfileMenu from "../Header/ProfileMenu";

import multiguidelogo from '../../assets/images/mgsuite/multiguidelogo.png'

import $ from "jquery";

//i18n
import { withNamespaces } from 'react-i18next';

function Header(props) {

  const [menuOpen, setMenuOpen] = useState(false);

  // closes menu if clicked somewhere else - responsive

  $(document).on('click', function(e){
    if (menuOpen==true && !$(e.target).is('#vertical-menu-btn') && !$(e.target).is('.fa') && window.innerWidth < 1367 ) {
      setMenuOpen(false)
      $('.vertical-menu').removeClass('vertical-menu-open')
     }
  })

  // opens and closes the menu - responsive

  function menuOff()
  {
    if (menuOpen==false) {
      $('.vertical-menu').addClass('vertical-menu-open')
       setMenuOpen(true)
    }

    else {
      $('.vertical-menu').removeClass('vertical-menu-open')
      setMenuOpen(false)
    }
      
  }

      return (
        <header>
          <div className="flex-sb" style={{width:'100%'}}>
            <div  className="flex-iac">
            <div onClick={() => {menuOff()}} className="flex-iac" id="burger-btn">
                  <i className="fa fa-fw fa-bars"></i>
                  </div>
               <div style={{marginLeft:'5px'}} className="flex-iac">
                <Link>
                  <img src={multiguidelogo} alt="multiGuide" className="logo" />
                </Link> 
              </div>
            </div>
            <div className="flex-iac">
              <LanguageDropdown />
               <DefectItemsDropdown /> 
              <ProfileMenu />
            </div>      
          </div>
        </header>

      );
    }

export default (withNamespaces()(Header))
