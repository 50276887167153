import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";

class Layout extends Component {

   // Adds name of page in the browser
  
  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
    document.title = currentage + " | MGSuite";
  }

  render() {
    return (
        <div>
          <Header/>
          <Sidebar/>
          <div className="main-content">
            {this.props.children}
          </div>
        </div>
    );
  }
}
export default (withRouter(Layout));
