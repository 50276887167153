
import React, {useState, useEffect} from 'react';
import axios from "axios";
import $ from "jquery";
import { withNamespaces } from 'react-i18next';
import ReactLoading from 'react-loading';

import Checkbox from '@material-ui/core/Checkbox';

import jwt_decode from "jwt-decode";


let mainParkingLots=[]
// let selectedAreas=[]
let selectedAreas=0

let pageLoaded=false

// Generate area tree for only main areas

  const TreeAreaMain= (props) =>  {

    let link = localStorage.getItem('link')

    const [makeUpdate, setMakeUpdate] = useState(false);

    const [loaderShow, setLoaderShow] = useState(false);

    function selectArea(id,e) {

      if($(e.target).is('img')) {
        return
      }

      selectedAreas=id

    //   if (selectedAreas.includes(id)) {
    //    selectedAreas = selectedAreas.filter((value)=>value!=id)
    //   }
 
    //  else {
    //    selectedAreas.push(id)
    //  }

     props.onChange({'carParkId':selectedAreas})
    
      setMakeUpdate(current => !current)
    }


    useEffect(() => {

      if (props.data!=null) {
        selectedAreas=props.data
        setMakeUpdate(current => !current)
      }

      if (pageLoaded) {
        setLoaderShow(false)
      }
      else {
        setLoaderShow(true)   
      }
  
  
      let tokenExpired=null
  
      function delay(timer) {
        return new Promise(resolve => {
            setTimeout(function () {      
              if (localStorage.getItem('JWT')) {
                let decoded = jwt_decode(localStorage.getItem('JWT'));
                let diff = decoded.exp * 1000 - Date.now() 
            
                if (diff <= 0) {
                  tokenExpired=true
                }
                else {
                  tokenExpired=false
                }
            
                }
              
              resolve()}, timer);
        });
    };
    
    async function getAreaData() {
        while (tokenExpired==null || tokenExpired==true)
            await delay(50);
  
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');

        axios.get(link+'/api/Area')  
        .then((response) => { 
  
          mainParkingLots=[]

          for (let i=0; i < response.data.length;i++) {
    
            if (response.data[i].areaType ==  2) {
              mainParkingLots.push(response.data[i])
            } 
    
          }


          mainParkingLots.sort((a, b)=> a.areaTreeSortOrder - b.areaTreeSortOrder);
    
          pageLoaded=true 
          setLoaderShow(false)
  
        })
      
        .catch(error => {
       
       })
  
      }
  
  
   getAreaData()
      
    }, [props.data]);
  

       let children=[]
       let tree=[]
     
       for (let i = 0; i < mainParkingLots.length; i++) {
     
         let checked=false
     
        //  if (selectedAreas.includes(mainParkingLots[i].id)) {
        //   checked=true
        //  }

            if (selectedAreas==mainParkingLots[i].id) {
          checked=true
         }
     
           children.push( 
             <div className="park-container" onClick={(e)=>selectArea(mainParkingLots[i].id, e)} id={mainParkingLots[i].id} key={"main"+i} name={mainParkingLots[i].name} > 
             <div className="flex-iac" style={{height:'25px',paddingLeft:'15px'}}>
               <Checkbox checked={checked} color="primary" size="small"  style={{position:'absolute',right:'15px'}} id={mainParkingLots[i].id+"cb"}/>
  
       <div style={{marginLeft:'5px'}}>{mainParkingLots[i].name}</div> 
           </div>
             </div>
           )
        
     }
     
     
     tree.push(
       <div>
      {loaderShow==true && (
        <div className="loader-mini">
        <ReactLoading type={"bars"} color={"#2a3042"} height={50} width={50} name={"Loading"} />
         </div> )}  
     {children} 
     </div> 
     )
  
     return (tree)

    }
  

  export default withNamespaces()(TreeAreaMain)


