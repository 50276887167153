
import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import axios from "axios";
import $ from "jquery";
import PopUp from "./PopUp.js";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const CreateEditDeleteTag = (props) =>  {

let areas=JSON.parse(localStorage.getItem('areas'))
let link = localStorage.getItem('link')

const [popUpShow, setPopUpShow] = useState(false);
const [currentTagData, setCurrentTagData] = useState();
const popUpContainer = useRef(null);
const [makeUpdate, setMakeUpdate] = useState(false);
const [tags,setTags]=useState(JSON.parse(localStorage.getItem('tags')));

useEffect(() => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
}, [localStorage.getItem('JWT')]);


$(document).on('click', function(e){

    if (popUpContainer.current && !popUpContainer.current.contains(e.target) && e.target.id!='settings-icon' && !$(e.target).is('.settings-item')
    && !$(e.target).children().hasClass('settings-item') && !$(e.target).hasClass('select-icon') && !$(e.target).parents('.rc-time-picker-panel').length==1 && !$(e.target).hasClass('rc-time-picker-clear') 
    && !$(e.target).hasClass('rc-time-picker-clear-icon') && e.target.id!='pop-up-button' ) {
   
      setPopUpShow(false)
    }

})

function editCreateTag(tagData) {
  setCurrentTagData(tagData)
  setPopUpShow(true)
 }

 
const deleteTag = (tagData) => {

  let id=tagData.id

   confirmAlert({
     message: props.t('allgemein.bestaetigen'), 
     buttons: [
       {
         label: props.t('allgemein.ja'),
         onClick: () => axios.delete(link+'/api/Tag/?id='+id, {
           headers: {
          'Content-Type': 'application/json',
       }
           
              })  .then((response) => {  

                axios.get(link+'/api/Tag', {
                })  .then((response) => { 
                        setTags(response.data)
                        localStorage.setItem('tags',JSON.stringify(response.data))  })
                     

              })

       
            
              .catch(error => {
             
             })
       },
       {
         label: props.t('allgemein.nein'),
        
       }
     ]
   });
 }

function tagsList() {

  function getTypeName(name) {
    let str=''
    switch(name) {
      case 'Area':
        str=props.t('elementeAllgemein.area')
        break;
      case 'NumericalDisplay':
        str=props.t('numDisplay.numDisplay')
        break;
      case 'Lot':
        str=props.t('lot.lot')
        break;
      case 'TextDisplay':
        str=props.t('textDisplay.textDisplay')
        break;
      case 'ZoneDisplay':
        str=props.t('zoneDisplay.zoneDisplay')
        break;

    }
    return str
  }

  function getParkName(id){
    // let strArr=[]
    // let idsArr=[ids]

    // for (let i=0;i < idsArr.length;i++) {
    // for (let j=0;j < areas.length;j++) {
    //   if (idsArr[i]==areas[j].id) {
    //     strArr.push(areas[j].name)
    //   }
    // } }

    let str=""
    for (let i=0;i < areas.length;i++) {
      if (id==areas[i].id) {
        str=areas[i].name
      }
    }

    return str

  }

    let tagsList=[]

    for (let i = 0 ; i < tags.length;i++) {


      let parkName=getParkName(tags[i].carParkId)
      let typeName=getTypeName(tags[i].elementType)

        tagsList.push(
            <tr>
            <td>{tags[i].id}</td>
            <td>{tags[i].title}</td>
            <td>{tags[i].category}</td>
            <td>{tags[i].externalName}</td>
            <td>{typeName}</td>
            <td>{parkName}</td>
            <td>
          <button className="button" id="pop-up-button" style={{width:'80px'}} onClick={()=>editCreateTag(tags[i])}>{props.t('allgemein.Bearbeiten')}</button> 
          <button className="button" id="pop-up-button" style={{width:'80px',marginLeft:'10px'}} onClick={()=>deleteTag(tags[i])} >{props.t('allgemein.Loeschen')}</button> 
            </td>
            </tr>
        )
    }
 
    return (
    <tbody>
    {tagsList}
    </tbody>
    )
}


function popUpChange() {

  axios.get(link+'/api/Tag', {
  })  .then((response) => { 
          setTags(response.data)
          localStorage.setItem('tags',JSON.stringify(response.data))
    
        })

  setPopUpShow(false)

}


return (

    <div>

    {popUpShow==true && (
    <div ref={popUpContainer}>
    {<PopUp type={"createEditDeleteTag"} data={currentTagData} onChange={()=>popUpChange()}> </PopUp>}
    </div>)} 

    <div className="einstellungen-box">
    <div className="box-name">Tags</div>  
    <div style={{overflow:'auto'}}>
    <table id="createEditDeleteTagTable">
    <thead>
    <tr>
          <td>ID</td>
          <td>{props.t('allgemein.titel')}</td>
          <td>{props.t('allgemein.kategorie')}</td>
          <td>{props.t('allgemein.externerName')}</td>
          <td>{props.t('kalender.elementTyp')}</td>
          <td>{props.t('elementeAllgemein.parkhaus')}</td>
          <td>
        <button className="button" id="pop-up-button" style={{width:'80px'}} onClick={()=>editCreateTag()}>{props.t('allgemein.Neu')}</button> 
          </td>
          </tr>
    </thead>
   {tagsList()} 
  </table>
  </div>
  </div>

  </div>
  )
}


export default withNamespaces()(CreateEditDeleteTag)