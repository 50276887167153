
import React, { useState, useEffect, useRef } from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import axios from "axios";
import $ from "jquery";
import PopUp from "./PopUp.js";
import Checkbox from '@material-ui/core/Checkbox';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const LightIntensityTimer = (props) => {

  let lightIntensityTimers = props.data
  let link = localStorage.getItem('link')

  const [popUpShow, setPopUpShow] = useState(false);
  const [currentLightIntensityTimerData, setCurrentLightIntensityTimerData] = useState();
  const popUpContainer = useRef(null);
  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
  }, [localStorage.getItem('JWT')]);


  $(document).on('click', function (e) {

    if (popUpContainer.current && !popUpContainer.current.contains(e.target) && e.target.id != 'settings-icon' && !$(e.target).is('.settings-item')
      && !$(e.target).children().hasClass('settings-item') && !$(e.target).hasClass('select-icon') && !$(e.target).parents('.rc-time-picker-panel').length == 1 && !$(e.target).hasClass('rc-time-picker-clear')
      && !$(e.target).hasClass('rc-time-picker-clear-icon') && e.target.id != 'pop-up-button') {

      setPopUpShow(false)
    }

  })

  function editCreateLightIntensityTimer(lightIntensityTimerData) {
    setCurrentLightIntensityTimerData(lightIntensityTimerData)
    setPopUpShow(true)
  }


  const deleteLightIntensityTimer = (lightIntensityTimerData) => {

    let id = lightIntensityTimerData.id

    confirmAlert({
      message: props.t('allgemein.bestaetigen'),
      buttons: [
        {
          label: props.t('allgemein.ja'),
          onClick: () => axios.delete(link + '/api/LightDimmer/?id='+id).then((response) => {

            props.onChange()
          })

            .catch(error => {

            })
        },
        {
          label: props.t('allgemein.nein'),

        }
      ]
    });
  }

  function lightIntensityTimerList() {

    let lightIntensityTimerList = []
    let parkGroupAdded = null
    let parkGroupAddedOnce = false
    let carParkAdded = null
    let carParkAddedOnce = false
    let levelAdded = null
    let levelAddedOnce = false
    let areaNameUsed = null

    for (let i = 0; i < lightIntensityTimers.length; i++) {

      const fetchedAreaPath = lightIntensityTimers[i].areaPath
      const areaName = fetchedAreaPath[fetchedAreaPath.length-1]
      const dimmers = lightIntensityTimers[i].dimmers
      const areaType = lightIntensityTimers[i].areaType

      let areaClass

      switch (lightIntensityTimers[i].areaType) {
        case 1:
          areaClass = "area10"
          break;
        case 2:
          areaClass = "area15"
          break;
        case 3:
          areaClass = "area35"
          break;
        case 4:
          areaClass = "area55"
          break;
        case 5:
          areaClass = "area55"
          break;
        default:
          areaClass = "area10"
          break;
      }

      if ( (parkGroupAdded != fetchedAreaPath[0])){

        parkGroupAdded = fetchedAreaPath[0]
        areaNameUsed = fetchedAreaPath[0]
        parkGroupAddedOnce = true

        lightIntensityTimerList.push(
          <tr>
            <td>{fetchedAreaPath[0]}</td>
            <td colspan="7"></td>
          </tr>
        )
      }

      if (  ( carParkAdded != fetchedAreaPath[1])){

        carParkAdded = fetchedAreaPath[1]
        areaNameUsed = fetchedAreaPath[1]
        carParkAddedOnce = true

        if(areaType != 2){
        lightIntensityTimerList.push(
          <tr>
            <td className="area15">&#9492;&#9472; {fetchedAreaPath[1]}</td>
            <td colspan="7"></td>
          </tr>
        )
        }
      }

      if (((fetchedAreaPath[2] != null && levelAdded != fetchedAreaPath[2]))){

        levelAdded = fetchedAreaPath[2]
        areaNameUsed = fetchedAreaPath[2]
        levelAddedOnce = true
        if(areaType != 3){
          if( fetchedAreaPath.length == 4) {
        lightIntensityTimerList.push(
          <tr>
            <td className="area35">&#9492;&#9472; {fetchedAreaPath[2]}</td>
            <td colspan="7"></td>
          </tr>
        )
        }
      }
      }

    const daysOfWeek = [
              props.t('allgemein.tageKurz.Sunday'),
              props.t('allgemein.tageKurz.Monday'),
              props.t('allgemein.tageKurz.Tuesday'),
              props.t('allgemein.tageKurz.Wednesday'),
              props.t('allgemein.tageKurz.Thursday'),
              props.t('allgemein.tageKurz.Friday'),
              props.t('allgemein.tageKurz.Saturday')
            ]

      // dimmers

      for (let j = 0; j < dimmers.length; j++) {


        const chosenDaysOfWeek = dimmers[j].weekdays.map(function(v,i) {
          return daysOfWeek[v]
        }).join(',')

        dimmers[j].areaId = lightIntensityTimers[i].areaId

        if (j == 0){
          lightIntensityTimerList.push(
            <tr>
              <td className={areaClass} >&#9492;&#9472; {areaName}</td>
              <td>{dimmers[j].lightIntensity}</td>
              <td>{dimmers[j].sensors ? "X" : ""}</td>
              <td>{dimmers[j].displays ? "X" : ""}</td>
              <td>{dimmers[j].beginn}</td>
              <td>{dimmers[j].end}</td>
              <td>{chosenDaysOfWeek}</td>

              <td>
                <button className="button" id="pop-up-button" style={{ width: '80px' }} onClick={() => editCreateLightIntensityTimer(dimmers[j])}>{props.t('allgemein.Bearbeiten')}</button>
                <button className="button" id="pop-up-button" style={{ width: '80px', marginLeft: '10px' }} onClick={() => deleteLightIntensityTimer(dimmers[j])} >{props.t('allgemein.Loeschen')}</button>
              </td>
            </tr>
          )
        } else {
        lightIntensityTimerList.push(
          <tr>
            <td></td>
            <td>{dimmers[j].lightIntensity}</td>
            <td>{dimmers[j].sensors ? "X" : ""}</td>
            <td>{dimmers[j].displays ? "X" : ""}</td>
            <td>{dimmers[j].beginn}</td>
            <td>{dimmers[j].end}</td>
            <td>{chosenDaysOfWeek}</td>

            <td>
              <button className="button" id="pop-up-button" style={{ width: '80px' }} onClick={() => editCreateLightIntensityTimer(dimmers[j])}>{props.t('allgemein.Bearbeiten')}</button>
              <button className="button" id="pop-up-button" style={{ width: '80px', marginLeft: '10px' }} onClick={() => deleteLightIntensityTimer(dimmers[j])} >{props.t('allgemein.Loeschen')}</button>
            </td>
          </tr>
        )
        }

      }
      // end dimmers
    }

    return (
      <tbody>
        {lightIntensityTimerList}
      </tbody>
    )
  }


  function popUpChange() {
    props.onChange()
    setPopUpShow(false)
  }

  return (
    <div>

      {popUpShow == true && (
        <div ref={popUpContainer}>
          {<PopUp type={"lightIntensityTimer"} data={currentLightIntensityTimerData} onChange={popUpChange}> </PopUp>}
        </div>)}

      <div className="einstellungen-box">
        <div className="box-name">{props.t('administration.helligkeitstimer')}</div>
        <div style={{ overflow: 'auto' }}>
          <table id="lightIntensityTimerTable">
            <thead>
            <tr>
                  <th colspan="1" style={{ textAlign: 'center' }}>{props.t('allgemein.bereich')}</th>
                  <th colspan="7" style={{ textAlign: 'center' }}>{props.t('allgemein.dimmer')}</th>
                </tr>
                <tr>
                  <td></td>
                  <td>{props.t('elementeAllgemein.helligkeitInProzent')}</td>
                  <td>{props.t('occupancy.einstellung_helligkeitstimer.gueltigFuerSensoren')}</td>
                  <td>{props.t('occupancy.einstellung_helligkeitstimer.gueltigFuerSchilder')}</td>
                  <td>{props.t('allgemein.von')}</td>
                  <td>{props.t('allgemein.bis')}</td>
                  <td>{props.t('occupancy.einstellung_helligkeitstimer.wochentage')}</td>
                <td className="create-new" >
                  <button className="button" id="pop-up-button" style={{ width: '50px' }} onClick={() => editCreateLightIntensityTimer()} >{props.t('allgemein.neu')}</button>
                </td>
              </tr>
            </thead>
            {lightIntensityTimerList()}
          </table>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(LightIntensityTimer)
