import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, { useState, useEffect, useRef } from 'react';
import logo from '../../assets/images/mgsuite/multiguidelogo.png'
import { ReactSVG } from 'react-svg'
import Draggable from 'react-draggable'

let wheelstep = 100

// colors for rectangle in the map

let color1 = "#008DD2"
let color2 = "#D9DADA"

const CarFinder = (props) => {

  let link = localStorage.getItem('link')
  let areas = JSON.parse(localStorage.getItem('areas'))

  const [licensePlate, setLicensePlate] = useState();
  const [lotData, setLotData] = useState([]);
  const [currentMap, setCurrentMap] = useState(null);
  const [changeMap, setChangeMap] = useState(false);
  const [currentPark, setCurrentPark] = useState({});
  const [svgBackground, setSvgBackground] = useState(logo);
  const [cameraLink, setCameraLink] = useState(null)
  const [cameraShow, setCameraShow] = useState(false)

  const [showList, setShowList] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showWarning, setShowWarning] = useState(true);
  const [makeUpdate, setMakeUpdate] = useState(false);

  const dropDownContainer = useRef(null)
  const cameraContainer = useRef(null)

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
  }, []);


  $(document).on('click', function (e) {

    if (cameraContainer.current && !cameraContainer.current.contains(e.target) && !$(e.target).is('#cameraButton')) {
      setCameraShow(false)
    }

    if (dropDownContainer.current && !dropDownContainer.current.contains(e.target)) {
      setShowList(false)
    }

  });


  // add rectangle to the parking lot if found and restart after new entry

  function markCar(car) {

    // interval until the svg is in the dom

    let interval

    function updateSVG() {
      if ($('.svg-carfinder').find('svg').length != 0) {
        clearInterval(interval);

        $("g").each(function (index) {
          if ($(this).attr('id') != null && $(this).find('g').find('path').length >= 10) {
            $(this).find('g').find('path').css('fill-opacity', "0.0").css('stroke-opacity', "0.0")
            $(this).find('rect').eq(0).attr("stroke", "").attr("stroke-opacity", "0").attr("stroke-width", "0")
          }

        });

        $("tspan").attr("fill", "").attr("stroke-width", "0")

        let parkplace = document.getElementById(car)

        $(parkplace).find('g').find('path').css('fill-opacity', "1.0").css('stroke-opacity', "1.0")
        $(parkplace).find('path').eq(0).attr('fill', color1)
        $(parkplace).find('path').eq(3).attr('fill', color2)
        $(parkplace).find('rect').eq(0).attr("stroke", color1).attr("stroke-opacity", "1.0").attr("stroke-width", "70")

        let view

        if ($("tspan:contains(" + car + ")").parent()[0]) {
          view = ($("tspan:contains(" + car + ")").parent()[0].getAttribute("font-size"))
        }

        else {
          view = 27
        }

        let strokewidth = 2 * (view / 27)

        $("tspan:contains(" + car + ")").attr("fill", "green").attr("stroke", color1).attr("stroke-width", strokewidth)

        setMakeUpdate(current => !current)

      }
    }

    interval = setInterval(updateSVG, 100)


  }


  function search() {

    function searchForCar() {


      axios.post(link + '/api/CarFinder/search', licensePlate, {
        headers: {
          'Content-Type': 'text/plain',
        }

      }).then((response) => {

        if (response.status == "200") {

          if (response.data.length < 1) {
            setShowDetails(false)
            setShowWarning(false)
            setShowError(true)
          }

          else {
            setLotData(response.data)
            setShowList(true)
          }

        }

        else {
          setShowDetails(false)
          setShowWarning(false)
          setShowError(true)
        }


      })

        .catch(error => {
          setShowError(true)
          setShowDetails(false)
        })

    }


    function showMap(data) {

      let name = data.name
      let areaName = null

      if (data.hasOwnProperty('levelAreaName')) {
        areaName = data.levelAreaName
      }

      let area
      let mapName

      for (let i = 0; i < areas.length; i++) {
        if (areas[i].id == data.levelAreaId) {
          mapName = areas[i].svgFileName
        }

        if (areas[i].id == data.carParkId) {
          area = areas[i].name
        }
      }

      setCameraLink(link + '/api/lot/videofeed/?id=' + data.id)
      setShowList(false)
      setSvgBackground(null)
      setCurrentMap(<ReactSVG wrapper="span" className="svg" src={'./svgs/' + `${mapName}.svg`} />)
      setCurrentPark({ area: area, areaName: areaName, name: name })
      setChangeMap(true)
      setShowWarning(false)
      setShowDetails(true)

      if (mapName != null) {
        markCar(name)
      }
    }


    function changeText(props) {
      setShowError(false)
      setLicensePlate(props)
    }

    return (
      <div className="flex-col search-carfinder" style={{ width: '100%' }}>
        <div className="flex-sb ">
          <input type="text" style={{ width: '70%' }} placeholder={props.t('allgemein.kennzeichen')} value={licensePlate} onChange={(e) => changeText(e.target.value)}></input>
          <button className="button" style={{ marginLeft: '10px' }} onClick={searchForCar}>{props.t('allgemein.suchen')}</button>
        </div>
        {showList == true && (
          <div id="dropdown-carfinder" ref={dropDownContainer}>
            <ul>
              {lotData.map(lot =>
                <li onClick={() => showMap(lot)}>{lot.licensePlate}</li>
              )}
            </ul>
          </div>
        )}


        {showWarning == true && (
          <div style={{ color: 'black', paddingTop: '10px' }}>{props.t('carfinder.warnung')}</div>
        )}
        {showError == true && (
          <div style={{ color: 'red', paddingTop: '10px' }}>{props.t('allgemein.fehlgeschlagen')}</div>
        )}
        {showDetails == true && (
          <div style={{ paddingTop: '5px', fontSize: '14px', fontWeight: 'bold' }} className="flex-col">
            <div>{props.t('lot.lot')}: {currentPark.name}, {props.t('elementeAllgemein.parkhaus')}: {currentPark.area} {currentPark.areaName != null ? ", " + props.t('elementeAllgemein.ebene') + ":" + currentPark.areaName : ""}</div>
            <button className="button" id="cameraButton" style={{ width: '120px' }} onClick={() => setCameraShow(true)}>{props.t('allgemein.videoAnzeigen')}</button>
          </div>
        )}
      </div>
    )
  }



  function map() {
    return (
      <>
        {search()}
        <div id="svgmap" style={{ backgroundImage: `url(${svgBackground})`, marginLeft: '0px', marginRight: '0px', paddingTop: '100px' }}>
          <TransformWrapper options={{ centerContent: false, animationTime: "0", maxScale: 30, defaultScale: 1 }} pan={{ velocity: false, animationTime: "0" }}
            wheel={{ step: wheelstep }} reset={{ animation: false, animationTime: "0" }}>
            <TransformComponent>
              {changeMap == true && (
                <div className="svg-carfinder">
                  {currentMap}
                </div>
              )}
            </TransformComponent>
          </TransformWrapper>
        </div>

        {cameraShow == true && (
          cameraLink != null && (
            <Draggable>
              <div ref={cameraContainer} className="camera-container">
                <div className="camera-name">{currentPark.name}</div>
                <img src={cameraLink} width="100%" height="100%" style={{ pointerEvents: 'none' }} alt="Not found" />
              </div>
            </Draggable>
          )
        )}

      </>
    )
  }


  function renderAll() {
    return (
      <div className="left-container-se left-part-carfinder" >
        {map()}
      </div>
    )
  }



  return (
    <div className="pagewrapper-2">
      {renderAll()}
    </div>
  )

}

export default withNamespaces()(CarFinder)
