import axios from "axios";
import { withNamespaces } from 'react-i18next';

import React, { useState } from 'react';

import ReactLoading from 'react-loading';

import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import BackgroundSlider from 'react-background-slider'

import { Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

import image1 from '../../assets/images/mgsuite/loginImage1-1.jpg';
import image2 from '../../assets/images/mgsuite/loginImage2-2.jpg';
import image3 from '../../assets/images/mgsuite/loginImage3-3.jpg';
import image4 from '../../assets/images/mgsuite/loginImage4-4.jpg';

import multiguidelogo from '../../assets/images/mgsuite/multiguidelogo2.png'

const Login = (props) => {

    let link = localStorage.getItem('link')

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const history = useHistory();

    const [usernameCPW, setUsernameCPW] = useState();
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();

    const [loaderShow, setLoaderShow] = useState(false);
    const [loginFailShow, setLoginFailShow] = useState(false);
    const [changePWFailShow, setChangePWFailShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const [loginShow, setLoginShow] = useState(true);

    function loginRest() {

        const data = {
            username: username,
            password: password
        }

        axios.post(link + '/api/authentication/login', data, {

            headers: {
                'Content-Type': 'application/json',
            }

        }
        ).then((response) => {

            // Save in local storage from the response after login

            localStorage.setItem('JWT', response.data.token)
            localStorage.setItem('JWTR', response.data.refreshToken)
            localStorage.setItem('userName', username)
            localStorage.setItem('userCanChange', response.data.isMultiGuideAdmin)
            setLoaderShow(false)

            // Get LotTypes, Tags and Area

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');

            axios.get(link + '/api/LotTypes', {
            }).then((response) => {
                localStorage.setItem('lotTypes', JSON.stringify(response.data))
            })

            axios.get(link + '/api/Tag', {
            }).then((response) => {
                localStorage.setItem('tags', JSON.stringify(response.data))
            })

            axios.get(link + '/api/Area', {
            }).then((response) => {
                localStorage.setItem('areas', JSON.stringify(response.data))
            })

            history.push('occupancy');
        })
            .catch(error => {
                if (error.response.data.NeedsToChangePassword == true) {
                    setLoginShow(false)
                }
                setLoaderShow(false)
                setLoginFailShow(true)
            })
    }


    function usernameChange(props) {
        setUsername(props)
        setLoginFailShow(false)
    }


    function passwordChange(props) {
        setPassword(props)
        setLoginFailShow(false)
    }


    // handleValidSubmit
    function handleValidSubmit(event, values) {
        setLoaderShow(true)
        loginRest()
    }


    function usernameCPWChange(props) {
        setUsernameCPW(props)
        setChangePWFailShow(false)
    }

    function oldPasswordChange(props) {
        setOldPassword(props)
        setChangePWFailShow(false)
    }

    function newPasswordChange(props) {
        setNewPassword(props)
        setChangePWFailShow(false)
    }



    function changePW() {

        const data = {
            username: usernameCPW,
            oldPassword: oldPassword,
            newPassword: newPassword
        }

        axios.post(link + '/api/User/change-password', data, {

            headers: {
                'Content-Type': 'application/json',
            }

        }
        ).then((response) => {
            setLoginShow(true)
        })
            .catch(error => {

                if (error.response.status == "400") {
                    setErrorMessage(props.t('login.fehlerPasswortAenderung'))
                }
                else if (error.response.status == "500") (
                    setErrorMessage(props.t('login.fehlerPasswortAenderung_2'))
                )

                setChangePWFailShow(true)
            })
    }







    function handleValidSubmit2(event, values) {
        changePW()
    }



    return (
        <div>

            <BackgroundSlider
                images={[image1, image2, image3, image4]}
                duration={6} transition={2} className="background-login" />

            {loginShow == true && (
                <div id="login-box">
                    <div style={{ width: '80%' }}>
                        <img src={multiguidelogo} className="login-logo" />
                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }} style={{ opacity: '1' }}>

                            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

                            <div className="form-group">
                                <AvField name="username" autocomplete="username" label={props.t('login.labelBenutzername')} value={username} className="form-control" placeholder={props.t('login.labelBenutzername_description')} type="text" required
                                    onChange={(e) => usernameChange(e.target.value)} />
                            </div>


                            <div className="form-group" style={{ marginTop: '25px' }}>
                                <AvField name="password" label={props.t('login.labelPasswort')} value={password} type="password" required placeholder={props.t('login.labelPasswort_description')}
                                    onChange={(e) => passwordChange(e.target.value)} />
                            </div>

                            {loginFailShow == true && (
                                <div className="login-fail">{props.t('login.fehlerUserOderPasswort')}</div>
                            )}

                            <div className="mt-3 form-group">
                                <button className="btn btn-primary btn-block waves-effect waves-light" type="submit" >{props.t('login.login')}</button>
                            </div>

                            <div style={{ fontSize: '11px', paddingTop: '5px', fontWeight: 'bold' }} className=" mt-4 text-center" >{props.t('login.passwortVergessen')}</div>

                            <div className="mt-4 text-center" onClick={() => setLoginShow(false)}>
                                <Link> <i className="mdi mdi-lock mr-1"></i>{props.t('login.ersteAnmeldung')}</Link>
                            </div>


                        </AvForm>

                    </div>
                </div>

            )}


            {loginShow == false && (

                <div id="login-box">
                    <div style={{ width: '80%' }}>

                        <img src={multiguidelogo} className="login-logo" />
                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit2(e, v) }} style={{ opacity: '1' }}>

                            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

                            <div className="form-group">
                                <AvField name="usernameCPW" autoComplete="new-username" label={props.t('login.labelBenutzername')} value={usernameCPW} className="form-control" placeholder={props.t('login.labelBenutzername_description')} type="username" required
                                    onChange={(e) => usernameCPWChange(e.target.value)} />
                            </div>

                            <div className="form-group" style={{ marginTop: '25px' }}>
                                <AvField autoComplete="new-password" name="passwordOld" label={props.t('login.labelPasswortAlt')} value={oldPassword} type="password" required placeholder={props.t('login.labelPasswortAlt_description')}
                                    onChange={(e) => oldPasswordChange(e.target.value)} />
                            </div>

                            <div className="form-group" style={{ marginTop: '25px' }}>
                                <AvField autoComplete="new-password" name="passwordNew" label={props.t('login.labelPasswortNeu')} value={newPassword} type="password" required placeholder={props.t('login.labelPasswortNeu_description')}
                                    onChange={(e) => newPasswordChange(e.target.value)} />
                            </div>

                            {changePWFailShow == true && (
                                <div className="login-fail">{errorMessage}</div>
                            )}

                            <div className="mt-3 form-group">
                                <button className="btn btn-primary btn-block waves-effect waves-light" type="submit" >{props.t('login.passwortAendern')}</button>
                            </div>

                            <div className="mt-4 text-center" onClick={() => setLoginShow(true)}>
                                <Link> <i className="mdi  mr-1"></i>{props.t('login.zurueckZumLogin')}</Link>
                            </div>
                        </AvForm>

                    </div>
                </div>

            )}


            {loaderShow == true && (
                <div className="login-loader">
                    <ReactLoading type={"bars"} color={"#ffffff"} height={80} width={80} name={"Loading"} />
                </div>
            )}
        </div>
    );
}


export default withNamespaces()(Login)

