

    const customStyles = {

        control: (provided) => ({
          ...provided,
          height:'25px',
          minHeight:'unset',   
          boxShadow:'unset' 
        }),
         
        option: (provided, {isFocused}) => ({
          ...provided,
          height:'25px',
          minHeight:'unset',
          padding: '8px 12px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center' ,
          boxShadow:'unset',
          borderRadius:'3px',
          width:'100%',
          minWidth:'unset', 
          backgroundColor: isFocused ? "transparent" : "transparent",
          cursor:'pointer',
          color:'black'
        }),
    
    
        menu: (provided) => ({
          ...provided,
        marginTop:'3px',
        zIndex:'100000',
        width:'100%',   
        }),

        menuList: (provided) => ({
          ...provided,
        width:'100%',   
        minWidth:'unset', 
        }),

        menuPortal: (provided) => ({
          ...provided,
        width:'100%',   
        minWidth:'unset', 
        }),
      
    
        container: (provided) => ({
          ...provided,
          height:'25px',
          minHeight:'unset'  ,
          boxShadow:'unset',
          width:'100%',   
        }),
    
        valueContainer: (provided) => ({
          ...provided,
          height:'25px',
          minHeight:'unset',
          position:'unset' ,
          boxShadow:'unset',
        }),
    
        indicatorsContainer: (provided) => ({
          ...provided,
          height:'25px', 
          boxShadow:'unset' 
        }),
    
        indicatorSeparator: (provided) => ({
          ...provided,
          marginTop:'4px',   
          marginBottom:'4px', 
          boxShadow:'unset',

        }),
      
      }
  
      
export default customStyles