import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import SelectSingle from "react-select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import SelectMultiple from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import customStyles from "../../assets/select/customStyles";

const MenuProps = {
  PaperProps: { style: { maxHeight: 250, marginTop: 30 } },
  getContentAnchorEl: null,
};

let names = [];
let optionsTypes = [];

// Dropdown with lot types - single and multiple option

const SelectType = (props) => {
  let jwt = localStorage.getItem("JWT");
  let link = localStorage.getItem("link");
  let types = JSON.parse(localStorage.getItem("lotTypes"));

  let data = props.data;
  let settingsType = props.type;

  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;

  let multipleTypes = [];
  let singleType = null;

  function getNames() {
    names = [];
    optionsTypes = [];

    if (settingsType == "Statistics") {
      names.push({ value: 0, label: props.t("allgemein.gesamt") });

      optionsTypes.push({
        value: 0,
        label: (
          <div className="flex-iac settings-item">
            {props.t("allgemein.gesamt")}
          </div>
        ),
      });
    }

    for (let i = 0; i < types.length; i++) {
      if (types[i].name.includes(".")) {
        names.push({ value: types[i].id, label: types[i].name });

        optionsTypes.push({
          value: types[i].id,
          label: <div className="flex-iac settings-item">{types[i].name}</div>,
        });
      } else {
        names.push({ value: types[i].id, label: types[i].name });

        optionsTypes.push({
          value: types[i].id,
          label: <div className="flex-iac settings-item">{types[i].name}</div>,
        });
      }
    }
  }

  getNames();

  function renderMultiple(selectedMultiple) {
    let array = [];

    if (settingsType == "Statistics") {
      if (selectedMultiple.includes(0)) array.push(props.t("allgemein.gesamt"));
    }

    for (let i = 0; i < types.length; i++) {
      if (types[i].name.includes(".")) {
        if (selectedMultiple.includes(types[i].id)) array.push(types[i].name);
      } else {
        if (selectedMultiple.includes(types[i].id)) array.push(types[i].name);
      }
    }

    return array.join(", ");
  }

  const multipleChange = (event) => {
    setSelectedMultiple(event.target.value);
  };

  const singleChange = (event) => {
    axios
      .put(
        link + "/api/" + settingsType + "/Type?typeId=" + event.value,
        [data.id],
        {}
      )
      .then((response) => {
        for (let i = 0; i < optionsTypes.length; i++) {
          if (event.value == optionsTypes[i].value) {
            singleType = optionsTypes[i];
          }
        }
        setSelectedSingle(singleType);
      })

      .catch((error) => {
        setSelectedSingle(singleType);
      });
  };

  const [selectedMultiple, setSelectedMultiple] = useState(multipleTypes);
  const [selectedSingle, setSelectedSingle] = useState(singleType);

  const multipleClose = (event) => {
    if (settingsType == "Statistics") {
      let array = selectedMultiple;
      array.sort(function (a, b) {
        return a - b;
      });
      props.onChange(array);
    } else {
      axios
        .put(
          link + "/api/" + settingsType + "/LotType?elementId=" + data.id,
          selectedMultiple,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          multipleTypes = selectedMultiple;
        })

        .catch((error) => {
          setSelectedMultiple(multipleTypes);
        });
    }
  };

  useEffect(() => {
    getNames();

    if (settingsType == "Statistics") {
      setSelectedMultiple([1]);
    }

    if (settingsType == "Area") {
      if (
        data.areaValues.lotType != null ||
        data.areaValues.lotType != undefined
      ) {
        for (let i = 0; i < optionsTypes.length; i++) {
          if (data.areaValues.lotType == optionsTypes[i].value) {
            singleType = optionsTypes[i];
          }
        }
      }
      setSelectedSingle(singleType);
    } else if (settingsType == "Lot") {
      if (data.lotType != null || data.lotType != undefined) {
        for (let i = 0; i < optionsTypes.length; i++) {
          if (data.lotType.id == optionsTypes[i].value) {
            singleType = optionsTypes[i];
          }
        }
      }
      setSelectedSingle(singleType);
    } else {
      if (settingsType == "Statistics") {
        return;
      }

      if (data.lotTypes != null || data.lotTypes != undefined) {
        for (let i = 0; i < data.lotTypes.length; i++) {
          for (let j = 0; j < optionsTypes.length; j++) {
            if (data.lotTypes[i].id == optionsTypes[j].value) {
              multipleTypes.push(optionsTypes[j].value);
            }
          }
        }
      }
      setSelectedMultiple(multipleTypes);
    }
  }, [props.data]);

  function render() {
    if (settingsType == "Area" || settingsType == "Lot") {
      return (
        <SelectSingle
          className="select"
          styles={customStyles}
          isSearchable={false}
          value={selectedSingle}
          onChange={singleChange}
          options={optionsTypes}
          theme={(theme) => ({
            ...theme,
            borderRadius: 3,
            colors: { ...theme.colors, primary: "#20a0ff" },
          })}
        />
      );
    } else {
      return (
        <SelectMultiple
          className="select"
          style={{ width: "100%" }}
          multiple
          value={selectedMultiple}
          onChange={multipleChange}
          onClose={multipleClose}
          input={<Input />}
          renderValue={renderMultiple}
          MenuProps={MenuProps}
        >
          {names.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              <Checkbox
                color="primary"
                size="small"
                checked={selectedMultiple.indexOf(item.value) > -1}
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}{" "}
        </SelectMultiple>
      );
    }
  }

  return <div>{render()}</div>;
};

export default React.memo(withNamespaces()(SelectType));
