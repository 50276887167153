import React from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';


const colors = {
    occupColor1:"#008DD2",
    occupColor2:"#D9DADA",
    occupResColor1:"#008DD2",
    occupResColor2:"#FFED00",
    occupExcColor1:"#E5097F",
    occupExcColor2:"#D9DADA",
    defColor1:"#E31E24",
    defColor2:"#D9DADA",
    freeColor1:null,
    freeColor2:null,
    unknownColor1:"white",
    unknownColor2:"#D9DADA",
    freeResColor1:"#FFED00",
    freeResColor2:"#D9DADA",
    defResColor1:"#E31E24",
    defResColor2:"#FFED00"
};


const Legend = (props) =>  {

    function car(color1,color2) {
      if (color1==null && color2==null || color1=="white" && color2=="white") {
       return (
         <svg class="legendCarSVG nocar" data-type="car_BY"><g id="car_BY" transform=" scale(0.02)">
         <path fill={color1} stroke="black" stroke-width="13.4745" d="M2074,607c-30,-24 -65,-35 -101,-41c-12,-9 -26,-16 -42,-17c-77,-3 -156,-4 -235,-4c-19,0 -36,3 -48,6c-257,-9 -506,-9 -751,-4c-13,-7 -60,-8 -97,-7l-147,3c-45,1 -78,17 -119,22c-61,8 -117,21 -142,51c-39,46 -58,159 -58,272c0,113 19,226 58,271c25,30 81,42 142,50c41,6 75,22 119,22l147,3c37,1 84,0 97,-7c245,4 494,5 751,-4c12,3 29,6 48,6c79,0 158,-1 235,-4c16,-1 30,-8 42,-17c36,-6 70,-18 101,-42c72,-56 107,-168 107,-280c0,-112 -35,-224 -107,-279z"></path>
         <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1600,587c-336,-11 -671,-8 -1003,16c-34,4 -33,12 -22,17c15,7 45,19 60,25c13,6 49,11 69,11c136,4 282,8 420,9c106,1 214,-7 274,-21c80,-19 201,-47 203,-57l-1,0zm1,598c-336,11 -671,8 -1003,-16c-34,-4 -33,-12 -22,-17c15,-7 45,-19 60,-25c13,-6 49,-11 69,-11c136,-4 282,-8 420,-9c106,-1 214,7 274,21c80,19 201,47 203,57l-1,0z"></path>
         <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M576,1161l-2,-1l-2,-1c0,-1 7,-4 8,-4c28,-11 54,-26 84,-30c34,-6 70,-5 105,-7c128,-4 257,-7 385,-7c83,2 169,4 250,22c56,14 113,26 169,44c5,2 10,4 15,5c-38,1 -76,2 -114,3c-31,1 -63,1 -94,2c-260,3 -523,-2 -782,-21c-7,-1 -15,-2 -21,-4l-1,-1zm1,-549l-2,1l-2,1c0,1 7,4 8,4c28,11 54,26 84,30c34,6 70,5 105,7c128,4 257,7 385,7c83,-2 169,-4 250,-22c56,-14 113,-26 169,-44c5,-2 10,-4 15,-5c-38,-1 -76,-2 -114,-3c-31,-1 -63,-1 -94,-2c-260,-3 -523,2 -782,21c-7,1 -15,2 -21,4l-1,1z"></path>
         <path fill= {color2} stroke="black" stroke-width="2.52646" d="M914,655l-45,-41l4,-18c94,-4 188,-6 283,-6l-19,68c-55,0 -110,0 -164,-2c-19,0 -39,-1 -58,-1l-1,0zm1,463l-45,41l4,18c94,4 188,6 283,6l-19,-68c-55,0 -110,0 -164,2c-19,0 -39,1 -58,1l-1,0zm-322,-422c-26,127 -29,255 0,382c-1,13 0,19 -5,22l-108,27c-29,7 -37,-8 -42,-25c-43,-141 -45,-288 0,-428c5,-16 12,-32 42,-25l109,27c5,2 4,8 5,22l-1,-2zm800,414c54,7 190,44 233,56c105,-69 112,-495 0,-559c-43,11 -175,46 -233,56c38,153 38,294 0,447zm-180,74l-40,-69c2,0 3,0 5,0c60,1 120,4 180,13c51,9 101,22 151,35l6,17c-100,2 -201,4 -301,4l-1,0zm-323,-65l-64,57c-52,-2 -104,-5 -156,-8c-29,-2 -60,-2 -89,-7l-2,-1c15,-7 32,-14 47,-20c29,-11 58,-14 88,-16c58,-2 116,-3 174,-5l2,0zm323,-529l-40,69c2,0 3,0 5,0c60,-1 120,-4 180,-13c51,-9 101,-22 151,-35l6,-17c-100,-2 -201,-4 -301,-4l-1,0zm-323,65l-64,-57c-52,2 -104,5 -156,8c-29,2 -60,2 -89,7l-2,1c15,7 32,14 47,20c29,11 58,14 88,16c58,2 116,3 174,5l2,0z"></path>
         <path fill="#C5C6C6" stroke="black" stroke-width="2.52646" d="M468,608c-39,10 -64,77 -74,113c-3,9 -15,15 -27,17c11,-56 51,-134 101,-149c13,1 13,4 19,8c4,2 -7,7 -19,10l0,1zm0,556c-39,-10 -64,-77 -74,-113c-3,-9 -15,-15 -27,-17c11,56 51,134 101,149c13,-1 13,-4 19,-8c4,-2 -7,-7 -19,-10l0,-1z"></path>
         <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1162,1111l9,0l45,76l-9,0l-45,-76zm823,73c33,-15 100,-75 107,-115c16,-7 33,-12 46,-17c-6,40 -63,120 -132,143c-15,0 -13,0 -24,0c-10,0 -6,-6 3,-11zm0,-595c33,15 100,75 107,115c16,7 33,12 46,17c-6,-40 -63,-120 -132,-143c-15,0 -13,0 -24,0c-10,0 -6,6 3,11zm-1091,69l14,1l-75,-65l-10,1l71,64l0,-1zm268,4l9,0l45,-76l-9,0l-45,76zm-26,0l9,0l21,-76l-9,0l-21,76zm-242,453l14,-1l-75,65l-10,-1l71,-64l0,1zm242,-4l9,0l21,76l-9,0l-21,-76z"></path>
         <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M2168,769c9,37 13,77 13,117c0,2 0,5 0,6l0,0c0,37 -5,75 -13,110c-9,12 -24,19 -30,19c-16,0 -16,-10 -13,-26c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -2,-26 13,-26c5,0 21,7 30,19l0,1z"></path>
         <path fill="#434242" stroke="black" stroke-width="2.52646" d="M2169,773c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-10,9 -24,14 -28,14c-7,0 -9,0 -6,-17c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -1,-17 6,-17c4,0 18,5 28,14l0,1z"></path>
         <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M661,1095c210,0 394,-3 604,2c9,0 6,-9 0,-11c-204,-5 -390,-2 -604,-2l0,11zm1508,-322c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-3,3 -7,5 -10,7c9,-40 13,-83 12,-126l0,0c0,-39 -4,-77 -12,-113c3,2 7,4 10,7zm-1508,-96c210,0 394,3 604,-2c9,0 6,9 0,11c-204,5 -390,2 -604,2l0,-11z"></path>
         <path fill="none" stroke="black" stroke-width="3.36862" d="M639,687c-10,51 -13,116 -13,191m639,216c46,1 87,7 126,15m-126,-430c46,-1 87,-7 126,-15m-476,-9l-12,0m243,3l18,0m470,-44c161,20 313,50 459,90m-467,-97c161,20 318,49 464,90m-894,-153c-10,13 -12,23 -15,42m411,6c12,2 23,6 32,14m-8,-57c-5,16 -10,29 -18,38m47,-37c106,12 217,16 330,17m-1483,25c98,-14 207,-21 319,-27c266,-9 534,-9 803,-3c117,3 237,10 359,20m-1389,-28c62,8 273,2 310,-7m-78,48c-8,-10 -10,-23 -9,-34c14,-13 38,-15 63,-19m292,48l40,0m-335,24l-19,-17m-497,292c2,-45 4,-76 9,-118c1,-12 3,-23 4,-32m11,153c2,-56 6,-120 18,-171m442,-123l35,-1m-235,490c-10,-51 -13,-116 -13,-191m-284,-21c3,80 10,147 21,194c6,24 10,36 23,38c19,3 46,7 64,7m143,-25l68,2m1475,-36l19,-5m-530,118c161,-20 318,-49 464,-90m-456,83c161,-20 313,-50 459,-90m-500,112c12,-2 23,-6 32,-14m-429,63c-10,-13 -12,-23 -15,-42m-362,-9c-8,10 -10,23 -9,34c14,13 38,15 63,19m-286,-12c62,-8 273,-2 310,7m-403,-45c98,14 207,21 319,27c266,9 534,9 803,3c117,-3 237,-10 359,-20m-329,31c106,-12 217,-16 330,-17m-359,17c-5,-16 -10,-29 -18,-38m-434,-2l40,0m-59,-69l18,0m-294,45l-19,17m64,-58l-12,0m-65,57l35,1m-495,-295c2,56 6,120 18,171m-42,-168c2,45 4,76 9,118c1,12 3,23 4,32m1772,-13l-11,36m-1535,-370l68,-2m-320,215c3,-80 10,-147 21,-194c6,-24 10,-36 23,-38c19,-3 46,-7 64,-7"></path>
         <path fill="#FEFEFE" stroke="black" stroke-width="2.52646" d="M1486,512c13,38 30,71 43,88c5,6 20,-1 27,-5c-7,-22 -37,-71 -43,-75c-7,-6 -17,-9 -27,-8zm0,748c13,-38 30,-71 43,-88c5,-6 20,1 27,5c-7,22 -37,71 -43,75c-7,6 -17,9 -27,8z"></path>
       </g></svg>
        )
      }
      else {
      return (
       <svg class="legendCarSVG" data-type="car_BY"><g id="car_BY" transform=" scale(0.02)">
       <path fill={color1} stroke="black" stroke-width="13.4745" d="M2074,607c-30,-24 -65,-35 -101,-41c-12,-9 -26,-16 -42,-17c-77,-3 -156,-4 -235,-4c-19,0 -36,3 -48,6c-257,-9 -506,-9 -751,-4c-13,-7 -60,-8 -97,-7l-147,3c-45,1 -78,17 -119,22c-61,8 -117,21 -142,51c-39,46 -58,159 -58,272c0,113 19,226 58,271c25,30 81,42 142,50c41,6 75,22 119,22l147,3c37,1 84,0 97,-7c245,4 494,5 751,-4c12,3 29,6 48,6c79,0 158,-1 235,-4c16,-1 30,-8 42,-17c36,-6 70,-18 101,-42c72,-56 107,-168 107,-280c0,-112 -35,-224 -107,-279z"></path>
       <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1600,587c-336,-11 -671,-8 -1003,16c-34,4 -33,12 -22,17c15,7 45,19 60,25c13,6 49,11 69,11c136,4 282,8 420,9c106,1 214,-7 274,-21c80,-19 201,-47 203,-57l-1,0zm1,598c-336,11 -671,8 -1003,-16c-34,-4 -33,-12 -22,-17c15,-7 45,-19 60,-25c13,-6 49,-11 69,-11c136,-4 282,-8 420,-9c106,-1 214,7 274,21c80,19 201,47 203,57l-1,0z"></path>
       <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M576,1161l-2,-1l-2,-1c0,-1 7,-4 8,-4c28,-11 54,-26 84,-30c34,-6 70,-5 105,-7c128,-4 257,-7 385,-7c83,2 169,4 250,22c56,14 113,26 169,44c5,2 10,4 15,5c-38,1 -76,2 -114,3c-31,1 -63,1 -94,2c-260,3 -523,-2 -782,-21c-7,-1 -15,-2 -21,-4l-1,-1zm1,-549l-2,1l-2,1c0,1 7,4 8,4c28,11 54,26 84,30c34,6 70,5 105,7c128,4 257,7 385,7c83,-2 169,-4 250,-22c56,-14 113,-26 169,-44c5,-2 10,-4 15,-5c-38,-1 -76,-2 -114,-3c-31,-1 -63,-1 -94,-2c-260,-3 -523,2 -782,21c-7,1 -15,2 -21,4l-1,1z"></path>
       <path fill= {color2} stroke="black" stroke-width="2.52646" d="M914,655l-45,-41l4,-18c94,-4 188,-6 283,-6l-19,68c-55,0 -110,0 -164,-2c-19,0 -39,-1 -58,-1l-1,0zm1,463l-45,41l4,18c94,4 188,6 283,6l-19,-68c-55,0 -110,0 -164,2c-19,0 -39,1 -58,1l-1,0zm-322,-422c-26,127 -29,255 0,382c-1,13 0,19 -5,22l-108,27c-29,7 -37,-8 -42,-25c-43,-141 -45,-288 0,-428c5,-16 12,-32 42,-25l109,27c5,2 4,8 5,22l-1,-2zm800,414c54,7 190,44 233,56c105,-69 112,-495 0,-559c-43,11 -175,46 -233,56c38,153 38,294 0,447zm-180,74l-40,-69c2,0 3,0 5,0c60,1 120,4 180,13c51,9 101,22 151,35l6,17c-100,2 -201,4 -301,4l-1,0zm-323,-65l-64,57c-52,-2 -104,-5 -156,-8c-29,-2 -60,-2 -89,-7l-2,-1c15,-7 32,-14 47,-20c29,-11 58,-14 88,-16c58,-2 116,-3 174,-5l2,0zm323,-529l-40,69c2,0 3,0 5,0c60,-1 120,-4 180,-13c51,-9 101,-22 151,-35l6,-17c-100,-2 -201,-4 -301,-4l-1,0zm-323,65l-64,-57c-52,2 -104,5 -156,8c-29,2 -60,2 -89,7l-2,1c15,7 32,14 47,20c29,11 58,14 88,16c58,2 116,3 174,5l2,0z"></path>
       <path fill="#C5C6C6" stroke="black" stroke-width="2.52646" d="M468,608c-39,10 -64,77 -74,113c-3,9 -15,15 -27,17c11,-56 51,-134 101,-149c13,1 13,4 19,8c4,2 -7,7 -19,10l0,1zm0,556c-39,-10 -64,-77 -74,-113c-3,-9 -15,-15 -27,-17c11,56 51,134 101,149c13,-1 13,-4 19,-8c4,-2 -7,-7 -19,-10l0,-1z"></path>
       <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1162,1111l9,0l45,76l-9,0l-45,-76zm823,73c33,-15 100,-75 107,-115c16,-7 33,-12 46,-17c-6,40 -63,120 -132,143c-15,0 -13,0 -24,0c-10,0 -6,-6 3,-11zm0,-595c33,15 100,75 107,115c16,7 33,12 46,17c-6,-40 -63,-120 -132,-143c-15,0 -13,0 -24,0c-10,0 -6,6 3,11zm-1091,69l14,1l-75,-65l-10,1l71,64l0,-1zm268,4l9,0l45,-76l-9,0l-45,76zm-26,0l9,0l21,-76l-9,0l-21,76zm-242,453l14,-1l-75,65l-10,-1l71,-64l0,1zm242,-4l9,0l21,76l-9,0l-21,-76z"></path>
       <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M2168,769c9,37 13,77 13,117c0,2 0,5 0,6l0,0c0,37 -5,75 -13,110c-9,12 -24,19 -30,19c-16,0 -16,-10 -13,-26c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -2,-26 13,-26c5,0 21,7 30,19l0,1z"></path>
       <path fill="#434242" stroke="black" stroke-width="2.52646" d="M2169,773c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-10,9 -24,14 -28,14c-7,0 -9,0 -6,-17c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -1,-17 6,-17c4,0 18,5 28,14l0,1z"></path>
       <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M661,1095c210,0 394,-3 604,2c9,0 6,-9 0,-11c-204,-5 -390,-2 -604,-2l0,11zm1508,-322c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-3,3 -7,5 -10,7c9,-40 13,-83 12,-126l0,0c0,-39 -4,-77 -12,-113c3,2 7,4 10,7zm-1508,-96c210,0 394,3 604,-2c9,0 6,9 0,11c-204,5 -390,2 -604,2l0,-11z"></path>
       <path fill="none" stroke="black" stroke-width="3.36862" d="M639,687c-10,51 -13,116 -13,191m639,216c46,1 87,7 126,15m-126,-430c46,-1 87,-7 126,-15m-476,-9l-12,0m243,3l18,0m470,-44c161,20 313,50 459,90m-467,-97c161,20 318,49 464,90m-894,-153c-10,13 -12,23 -15,42m411,6c12,2 23,6 32,14m-8,-57c-5,16 -10,29 -18,38m47,-37c106,12 217,16 330,17m-1483,25c98,-14 207,-21 319,-27c266,-9 534,-9 803,-3c117,3 237,10 359,20m-1389,-28c62,8 273,2 310,-7m-78,48c-8,-10 -10,-23 -9,-34c14,-13 38,-15 63,-19m292,48l40,0m-335,24l-19,-17m-497,292c2,-45 4,-76 9,-118c1,-12 3,-23 4,-32m11,153c2,-56 6,-120 18,-171m442,-123l35,-1m-235,490c-10,-51 -13,-116 -13,-191m-284,-21c3,80 10,147 21,194c6,24 10,36 23,38c19,3 46,7 64,7m143,-25l68,2m1475,-36l19,-5m-530,118c161,-20 318,-49 464,-90m-456,83c161,-20 313,-50 459,-90m-500,112c12,-2 23,-6 32,-14m-429,63c-10,-13 -12,-23 -15,-42m-362,-9c-8,10 -10,23 -9,34c14,13 38,15 63,19m-286,-12c62,-8 273,-2 310,7m-403,-45c98,14 207,21 319,27c266,9 534,9 803,3c117,-3 237,-10 359,-20m-329,31c106,-12 217,-16 330,-17m-359,17c-5,-16 -10,-29 -18,-38m-434,-2l40,0m-59,-69l18,0m-294,45l-19,17m64,-58l-12,0m-65,57l35,1m-495,-295c2,56 6,120 18,171m-42,-168c2,45 4,76 9,118c1,12 3,23 4,32m1772,-13l-11,36m-1535,-370l68,-2m-320,215c3,-80 10,-147 21,-194c6,-24 10,-36 23,-38c19,-3 46,-7 64,-7"></path>
       <path fill="#FEFEFE" stroke="black" stroke-width="2.52646" d="M1486,512c13,38 30,71 43,88c5,6 20,-1 27,-5c-7,-22 -37,-71 -43,-75c-7,-6 -17,-9 -27,-8zm0,748c13,-38 30,-71 43,-88c5,-6 20,1 27,5c-7,22 -37,71 -43,75c-7,6 -17,9 -27,8z"></path>
     </g></svg>
      )
    }
    }
  
  
   return (
     <div className="legend-div">
    <div className="legendname">{props.t('occupancy.legende.legende')}</div>
    <SimpleBar className="simplebar-legend">
    <div className="legend-content">
    <div className="legendinfo">{props.t('occupancy.legende.autodarstellung')}</div>
  
   <div className="cars">
  
     <div className="cars1"> 
    <div className="legendflex">
    {car(colors.occupColor1,colors.occupColor2)}
     <div className="cartext" name="occupied">{props.t('administration.autofarben.car_B')}</div>
    </div>
  
    <div className="legendflex">
    {car(colors.occupResColor1,colors.occupResColor2)}
    <div className="cartext" name="occupied reserved">{props.t('administration.autofarben.car_BY')}</div>
    </div>
  
    <div className="legendflex">
  {car(colors.occupExcColor1,colors.occupExcColor2)}
  <div className="cartext" name="occupied exceeded">{props.t('administration.autofarben.car_M')}</div>
  </div>
  
  <div className="legendflex">
  {car(colors.defColor1,colors.defColor2)}
  <div className="cartext" name="defect">{props.t('administration.autofarben.car_R')}</div>
  </div>
  </div>
  
  
  <div className="cars2">
  <div className="legendflex">
  {car(colors.freeColor1,colors.freeColor2)}
  <div className="cartext" name="free">{props.t('administration.autofarben.car_G')}</div>
  </div>
  
  <div className="legendflex">
  {car(colors.unknownColor1,colors.unknownColor2)}
  <div className="cartext" name="free">{props.t('administration.autofarben.car_U')}</div>
  </div>
  
  <div className="legendflex">
  {car(colors.freeResColor1,colors.freeResColor2)}
  <div className="cartext" name="free reserved">{props.t('administration.autofarben.car_Y')}</div>
  </div>
  
  <div className="legendflex">
  {car(colors.defResColor1,colors.defResColor2)}
  <div className="cartext" name="defect reserved">{props.t('administration.autofarben.car_RB')}</div>
  </div>
  
  
  </div>
  </div>
  
  
  <div className="legendinfo legendborder">{props.t('occupancy.legende.stellplatznamen')}</div>
  
  <div className="parkplacesname">
  <div className="legendflex ">
  <div className="pp">1001</div> {props.t('allgemein.modus')}: {props.t('lot.modes.Automatic')}
  </div>
  <div className="legendflex">
  <div className="pp color1pp">1001</div>	{props.t('allgemein.modus')}: {props.t('lot.modes.ManualFree')}
  </div>
  <div className="legendflex">
  <div className="pp color2pp">1001</div>	{props.t('allgemein.modus')}: {props.t('lot.modes.ManualOccupied')}
  </div>
  </div>
  
  
  {/* <div className="legendinfo legendborder">{props.t('occupancy.legende.stellplatzhintergrund')}</div>
  
  <div className="parkplacesbckg">
  <div className="legendflex">
  <div className="color1bckg bckg"></div>{props.t('elementeAllgemein.kundengruppen.Normal')}
  </div>
  <div className="legendflex">
  <div className="color2bckg bckg"></div>{props.t('elementeAllgemein.kundengruppen.Handicapped')}
  </div>
  <div className="legendflex">
  <div className="color3bckg bckg"></div>{props.t('elementeAllgemein.kundengruppen.Women')}
  </div>
  <div className="legendflex">
  <div className="color4bckg bckg"></div>{props.t('elementeAllgemein.kundengruppen.Vip')}
  </div>
  <div className="legendflex">
  <div className="color5bckg bckg"></div>{props.t('elementeAllgemein.kundengruppen.Vip2')}
  </div>
  </div> */}
  
  
  <div className="legendinfo legendborder">{props.t('occupancy.legende.belegungstabelle')}</div>
  
  <div className="legendareatable">
  
  <div className="legendareacont">
  {props.t('elementeAllgemein.areaWerte.Constructed_kurz')}
  <div className="legendareatext">
  {props.t('elementeAllgemein.areaWerte.Constructed_beschreibung')}
  </div>
  </div>
  
  <div className="legendareacont">
  {props.t('elementeAllgemein.areaWerte.Free_kurz')}
  <div className="legendareatext">
  {props.t('elementeAllgemein.areaWerte.Free_beschreibung')}<br></br>
  {props.t('elementeAllgemein.areaWerte.Free_beschreibungErweitert')}
  </div>
  </div>
  
  <div className="legendareacont">
  {props.t('elementeAllgemein.areaWerte.Occupied_kurz')} / {props.t('elementeAllgemein.areaWerte.Occupied_kurz')} %
  <div className="legendareatext">
  {props.t('elementeAllgemein.areaWerte.Occupied_beschreibung')}
  </div>
  </div>
  
  <div className="legendareacont">
  {props.t('elementeAllgemein.areaWerte.Precount_kurz')}
  <div className="legendareatext">
  {props.t('elementeAllgemein.areaWerte.Precount_beschreibung')}
  </div>
  </div>
  
  
  </div>
  
  
  </div>
  </SimpleBar>
  
   </div>
   )

}

  export default withNamespaces()(Legend)