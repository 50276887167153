import React from "react";
import { Route,Redirect,withRouter } from "react-router-dom";


const Authmiddleware = ({
	component: Component,
	layout: Layout
}) => 

(
		<Route
			render={props => {

				// if not logged in, redirect to login page
					
			if (!localStorage.getItem("JWT")) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}

				return (	
		     	<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);


	

export default withRouter(Authmiddleware);

