import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import Profile from "../pages/Authentication/Profile";
import DefectElements from "../pages/DefectElements/DefectElements";

// Pages
import Occupancy from "../pages/Occupancy/Occupancy";
import Events from "../pages/Events/Events";
import Statistics from "../pages/Statistics/Statistics";
import Dashboard from "../pages/Dashboard/Dashboard";
import Calendar from "../pages/Calendar/Calendar";
import Settings from "../pages/Settings/Settings";
import CarFinder from "../pages/CarFinder/CarFinder";
import AliveStatus from "../pages/SystemInfo/AliveStatus";

// Authentication related pages
import Login from "../pages/Authentication/Login";

const userRoutes = [
	{ path: "/occupancy", exact: true, component: Occupancy },

	{ path: "/profile", exact: true, component: Profile },
	{ path: "/defectElements", exact: true, component: DefectElements },
	{ path: "/events", exact: true, component: Events },
	{ path: "/statistics", exact: true, component: Statistics },
	{ path: "/dashboard", exact: true, component: Dashboard },
	//   { path: "/calendar",exact: true, component: Calendar },
	{ path: "/settings", exact: true, component: Settings },
	{ path: "/carfinder", exact: true, component: CarFinder},
	{ path: "/aliveStatus", exact: true, component: AliveStatus },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/occupancy" /> }
];

const authRoutes = [
	{ path: "/login", component: Login },
];

export { userRoutes, authRoutes };
