import React, { useState } from "react";
import insertToken from "./insertToken";
import TokenBlot from "./TokenBlot";

const Token = ({ title, slug, id, quillRef }) => {
  const tokenData = { title, slug};
  const text = `[${slug}]`;
  const onDragStart = event => {
    const json = JSON.stringify({ title, slug});
     event.dataTransfer.setData("text/plain", text);
     event.dataTransfer.setData("application/vnd.placeholder.token", json);
  };

  const onClickInput = quillRef => () => {
    const quill = quillRef && quillRef.current;
    if (quill) {
      insertToken(quill, tokenData, text);
    }
  };

  return (
    <li>
      <button
        className={TokenBlot.className}
          // draggable="true"
          // onDragStart={onDragStart}
         onClick={onClickInput(quillRef)}
        type="button"
      >
        {title}
      </button>
    </li>
  );
};

export default Token;