import React from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
          return (
          <div className="vertical-menu">
                        {props.type !== "condensed" ? (
                            <SimpleBar style={{ maxHeight: "100%"}}>
                                <SidebarContent />
                            </SimpleBar>
                        ) : <SidebarContent />}
                </div>
          );
        }

export default (withNamespaces()(Sidebar));
