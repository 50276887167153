import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

let defectCount

const DefectItemsDropdown = (props) => {

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

    const [menu, setMenu] = useState(false);
    const [data, setData] = useState([]);
    const [countNumber, setCountNumber] = useState(defectCount);

    // get defect list and the count of defect items

    useEffect(() => {

        axios.get(link + '/api/SystemInfo/DefectCount')
            .then((response) => {
                setCountNumber(response.data)
                defectCount = response.data
            })

        axios.get(link + '/api/SystemInfo/Defect')
            .then((response) => {
                let responseData = response.data
                responseData.sort((a, b) => a.name - b.name);
                setData(responseData)
            })


    }, []);


    // convert date to german date format

    function convertDate(date) {

        const currentDate = new Date(date);
        const currentDayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        let minutes = currentDate.getMinutes();
        let hours = currentDate.getHours();
        let seconds = currentDate.getSeconds();
        let month
        let day

        if (currentDayOfMonth < 10) {
            day = "0" + currentDayOfMonth
        }

        else {
            day = currentDayOfMonth
        }

        if (currentMonth < 10) {
            month = "0" + currentMonth
        }

        else {
            month = currentMonth
        }


        if (hours < 10) {
            hours = "0" + hours
        }

        if (minutes < 10) {
            minutes = "0" + minutes
        }

        if (seconds < 10) {
            seconds = "0" + seconds
        }

        const dateTimeString = day + "." + month + "." + currentYear + " " + hours + ":" + minutes + ":" + seconds

        return dateTimeString

    }

    // list of defect items

    return (
        <div id="dropdown-defect-elements">
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="dropdown d-inline-block"
                tag="li"
            >
                <DropdownToggle
                    className="btn header-item noti-icon waves-effect"
                    tag="button" id="page-header-notifications-dropdown">
                    <i className="bx bx-bell" style={{ fontSize: '22px', marginTop: '4px' }}></i>
                    <span className="badge badge-danger badge-pill">{countNumber}</span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-lg" right>
                    <div style={{ padding: '5px 15px 15px 15px' }}>
                        <Row className="align-items-center">
                            <Col>
                                <div className="m-0" style={{ fontSize: '13px' }}> {props.t('dashboard.defekteElemente')} </div>
                            </Col>
                            <Link to="/defectElements">
                                <div className="col-auto">
                                    <a style={{ fontSize: '11px' }}> {props.t('allgemein.alle')} </a>
                                </div>
                            </Link>
                        </Row>
                    </div>

                    <SimpleBar style={{ height: "230px", paddingLeft: '12px', paddingRight: '12px' }}>
                        <table>
                            <thead>
                                <tr >
                                    {/* <th >{props.t('allgemein.typ')}</th> */}
                                    <th >{props.t('allgemein.name')}</th>
                                    <th >{props.t('allgemein.defektSeit')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, idx) => {
                                    if (idx >= 50) {
                                        return
                                    }
                                    else {
                                        return (
                                            <>
                                                <tr>
                                                    {/* <td>{item.lastParkingDuration}</td> */}
                                                    <td>{item.name}</td>
                                                    <td>{item.defectSince != null ? convertDate(item.defectSince) : props.t('allgemein.unbekannt')}</td>
                                                </tr>
                                            </>
                                        )
                                    }


                                }

                                )}

                            </tbody>

                        </table>

                    </SimpleBar>

                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default withNamespaces()(DefectItemsDropdown);