import axios from "axios";

const updateLightIntensity = (data) => { 

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')
 
              axios.put(link+'/api/'+data.Type+'/LightIntensity?newIntensity='+data.Value, data.Id,{
       
                headers: {
                  Authorization:'Bearer ' + jwt
               }
              
              })  .then((response) => {         
                // updateSvg()
              }); 
  
   //multiple ids

   //
 
    
  }


  export default (updateLightIntensity);