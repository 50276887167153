import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";
import SimpleBar from "simplebar-react";
import React, { useReducer, useState, useEffect, useRef, useCallback } from 'react';

import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

import Select from "react-select";
import customStyles from '../../assets/select/customStyles'

import CalendarTheme from './Components/CalendarTheme.js'

import { DateTimePicker } from 'react-widgets'
import NumericInput from 'react-numeric-input';
import Checkbox from '@material-ui/core/Checkbox';

import InputColor from 'react-input-color';
import ReactLoading from 'react-loading';

import thisWeekIcon from '../../assets/images/mgsuite/fullweek.png';
import thisMonthIcon from '../../assets/images/mgsuite/fullmonth.png';
import thisYearIcon from '../../assets/images/mgsuite/fullyear.png';

import FormControlLabel from '@material-ui/core/FormControlLabel';

//  import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
//  import TextField from "@mui/material/TextField";

// install @mui/material and @mui/x-date-pickers by force, compatible with react 17.0 +

import moment from "moment";

import Tree from "./Components/Tree.js";

let lastElement
let dt1 
let dt2

let schedulesOpen

// Mockup schedules for the list of schedules

    let schedules=[
      {
          id: '1',
          title: 'Eintrag 1',
          category: 'time',
          body:'<p></p>',
          start: new Date(),
          end: new Date(new Date().setHours(new Date().getHours() + 30))
      },  
      {
          id: '2',
          title: 'Eintrag 2',
          category: 'time',
          start: new Date(new Date().setHours(new Date().getHours() + 10)),
          end: new Date(new Date().setHours(new Date().getHours() + 20))
      },  
      {
          id: '3',
          title: 'Eintrag 3',
          category: 'time',
          start: new Date(new Date().setHours(new Date().getHours() + 100)),
          end: new Date(new Date().setHours(new Date().getHours() + 120))
      },  
      {
          id: '4',
          title: 'Eintrag 4',
          category: 'time',
          start: new Date(new Date().setHours(new Date().getHours() + 56)),
          end: new Date(new Date().setHours(new Date().getHours() + 70))
      },  
      {
          id: '5',
          title: 'Eintrag 5',
          category: 'time',
          start: new Date(new Date().setHours(new Date().getHours() + 400)),
          end: new Date(new Date().setHours(new Date().getHours() + 500))
      }
      ,  
      {
          id: '6',
          title: 'Eintrag 6',
          category: 'time',
          start: new Date(new Date().setHours(new Date().getHours() + 600)),
          end: new Date(new Date().setHours(new Date().getHours() + 700))
      }
  

  ]


  let hoverSchedule

  // Hover over schedules to show info

  function showInfo() {

    let e = window.event;
    let posX = e.clientX;
    let posY = e.clientY;
    let addToTop
    let addToLeft
  
    if (posX > (window.innerWidth-($('#showinfo').width()/2)-5)) {
      addToLeft= window.innerWidth - $('#showinfo').width() - 5
    }
  
    else {
      addToLeft= posX - ($('#showinfo').width()/2)
    }
  
    if (posY > window.innerHeight/2 +30) {
      addToTop= posY - ($('#showinfo').height()+50)
    }
  
    else {
      addToTop= posY + 50
    }
  
    if (window.innerWidth<813) {
      addToLeft='50%'
      addToTop='50%'
      $('#showinfo').css('transform','translateX(-50%) translateY(-50%)')
    }
  
      hoverSchedule = {
      top:addToTop,
      left:addToLeft
    };
  
  } 
  
  let pageLoaded=false

  let currentData={}

  let selectedMultipleDays=[]
  let selectedLightOptions=[]

const Kalender= (props) => {

  let user=  localStorage.getItem('username')
  let pw=  localStorage.getItem('password')
  let link = localStorage.getItem('link')

  const calendarRef = useRef(null);

  // Add holidays to the calender, will be marked as red

   function addHolidays() {
    let interval = setInterval(markHolidays,1)

    function markHolidays() {
    if (calendarRef.current != null) {
     clearInterval(interval)

     let dates=[new Date('05 Apr 2021 00:00:00 GMT'),new Date('02 Apr 2021 00:00:00 GMT'),new Date('01 May 2021 00:00:00 GMT')]

     for (let j =0;j < dates.length; j++) {

    let date= dates[j]
    let holidayYear=moment(date).format('YYYY')
    let holidayMonth= moment(date).format('MM')

    let displayedYear= moment(calendarRef.current.calendarInst._renderDate._date).format('YYYY')
    let displayedMonth= moment(calendarRef.current.calendarInst._renderDate._date).format('MM')

    let prevYear= moment(calendarRef.current.calendarInst._renderDate._date).subtract(1, 'years').format('YYYY');
    let prevMonth= moment(calendarRef.current.calendarInst._renderDate._date).subtract(1, 'months').format('MM');

    let nextYear= moment(calendarRef.current.calendarInst._renderDate._date).add(1, 'years').format('YYYY');
    let nextMonth= moment(calendarRef.current.calendarInst._renderDate._date).add(1, 'months').format('MM');


    for (let i =0;i < $('.tui-full-calendar-weekday-grid-date').length;i++) {
      
      let element=$('.tui-full-calendar-weekday-grid-date')[i]
      let firstrow= ($(element).parent().parent().parent().parent())[0]

      if (holidayYear==displayedYear && holidayMonth==displayedMonth)  {
      if ($(element).text()==date.getUTCDate()) {
         if (!$(element).parents().hasClass('tui-full-calendar-extra-date')) {
          $(element).css('color','red').css('font-weight','bold')
         }
    } 
  } 

      if (holidayYear==displayedYear && holidayMonth==nextMonth && parseInt(holidayMonth) > parseInt(displayedMonth) || 
      displayedMonth=="12" && holidayMonth==nextMonth && holidayYear==nextYear  )  {

        if (firstrow != $('.tui-full-calendar-weekday-grid')[0]) {
        if ($(element).text()==date.getUTCDate()) {
          if ($(element).parents().hasClass('tui-full-calendar-extra-date')) {
            $(element).css('color','red').css('font-weight','bold').css('opacity','0.4')
           }
      } 
    }
    } 


    if (holidayYear==displayedYear && holidayMonth==prevMonth && parseInt(holidayMonth) < parseInt(displayedMonth) || 
    displayedMonth=="01" && holidayMonth==prevMonth && holidayYear==prevYear  )  {
 
     if (firstrow == $('.tui-full-calendar-weekday-grid')[0]) {
      if ($(element).text()==date.getUTCDate()) {
        if ($(element).parents().hasClass('tui-full-calendar-extra-date')) {
          $(element).css('color','red').css('font-weight','bold').css('opacity','0.4')
         }
    } 
    
  }
    }



}


     }


  }
}
}

  addHolidays()

// React states for the calender

  const [loaderShow, setLoaderShow] = useState(false);

  const [popUpShow, setPopUpShow] = useState(false);
  const [year, setYear] = useState(moment().year());
   const [month, setMonth] = useState();

   const [inHover, setHover] = useState(false);
   const [color, setColor] = useState({});
   const [align, setAlign] = useState("Links");
   const [fontFamily, setFontFamily] = useState( { value: 'Arial', width:'100%',label: <div style={{fontFamily:'arial'}} className="flex-iac settings-item">Arial</div> });
   const [fontSize, setFontSize] = useState("12")
   const [textDisplayMode, setTextDisplayMode] = useState({ value: 'countingNumber', label: props.t('textDisplay.belegungszahl') });
   const [lightValue, setLightValue] = useState("100");

   const [termin, setTermin] = useState(0);

   const schedulesContainer = useRef(null);
   const [changeOrientation, setChangeOrientation] = useState(false);

   const [step, setStep] = useState(1);
   const [title, setTitle] = useState("Neuer Eintrag");
   const [always, setAlways] = useState(false);
   const [wholeDay, setWholeDay] = useState(true);

   const [valueStart, setValueStart] = useState(new Date().setHours(0,0,0,0));
   const [valueEnd, setValueEnd] = useState(new Date().setHours(23,59,0,0));

   const [changeModus, setChangeModus] = useState({value:"Automatic",label: props.t('lot.modes.Automatic')});
   
   const [changeZoneModus, setChangeZoneModus] = useState({value:"0",label: props.t('zoneDisplay.leftArrow'),type:'Zone'})
   const [changeZoneModus_0, setChangeZoneModus_0] = useState({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})
   const [changeZoneModus_1, setChangeZoneModus_1] = useState({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})
   const [changeZoneModus_2, setChangeZoneModus_2] = useState({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})
   const [changeZoneModus_3, setChangeZoneModus_3] = useState({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})


   const [makeUpdate, setMakeUpdate]=useState(false)

   let lightOptions=[{value:'Sensoren', label:'Sensoren'}, {value:'Indoor Schilder', label:'Indoor Schilder'}, {value:'Outdoor Schilder', label:'Outdoor Schilder'}]

  // Day names with translation

   const dayNames=[
    { value: "Mon", label: props.t('allgemein.tageKurz.Monday') },
    { value: "Tue", label: props.t('allgemein.tageKurz.Tuesday') },
    { value: "Wed", label: props.t('allgemein.tageKurz.Wednesday') },
    { value: "Thu", label: props.t('allgemein.tageKurz.Thursday') },
    { value: "Fri", label: props.t('allgemein.tageKurz.Friday') },
    { value: "Sat", label: props.t('allgemein.tageKurz.Saturday') },
    { value: "Sun", label: props.t('allgemein.tageKurz.Sunday') },
   ]

   // All options for the calender settings

   const options = [
    { value: 'text', label: props.t('kalender.textAenderung') },
    { value: 'light', label: props.t('kalender.helligkeitsAenderung')},
    { value: 'modus', label: props.t('kalender.modusAenderung')},
  ];

  const alignOptions= [
    { value: 'left', label: props.t('allgemein.ausrichtung.Left') },
    { value: 'center', label: props.t('allgemein.ausrichtung.Centered') },
    { value: 'right', label: props.t('allgemein.ausrichtung.Right') },
  ]

  const optionsSerien = [
    { value: 'daily', label: props.t('allgemein.daily') },
    { value: 'weekly', label: props.t('allgemein.weekly')},
    { value: 'monthly', label: props.t('allgemein.monthly')},
    { value: 'yearly', label: props.t('allgemein.yearly')},
  ];

  const optionsSerienDaily = [
    { value: 'everyDay', label: "Jeden Tag"},
    { value: 'everyWorkingDay', label: "Jeden Arbeitstag"},
    { value: 'everyWeekend', label: "Jeden Wochenende"},
    { value: 'everyHoliday', label: "Feiertage"},
  ];

  const optionsModus = [
    { value: 'area', label: props.t('elementeAllgemein.area') },
    { value: 'Lot', label: props.t('lot.lot') },
    { value: 'text', label: props.t('textDisplay.textDisplay')},
    { value: 'numerical', label: props.t('numDisplay.numDisplay')},
    { value: 'zone', label: props.t('zoneDisplay.zoneDisplay')},
  ];

  const optionsTextFonts=[
    { value: 'Arial', width:'100%',label: <div style={{fontFamily:'arial'}} className="flex-iac settings-item">Arial</div> },
    { value: 'ArialNarrow', label: <div style={{fontFamily:'arial narrow'}} className="flex-iac settings-item">Arial Narrow</div> },
    { value: 'CourierNew', label: <div style={{fontFamily:'courier new'}} className="flex-iac settings-item">Courier New</div> },
    { value: 'Tahoma', label: <div style={{fontFamily:'tahoma'}} className="flex-iac settings-item">Tahoma</div> },
    { value: 'TimesNewRoman', label: <div style={{fontFamily:'times new roman'}} className="flex-iac settings-item">Times New Roman</div> },
    { value: 'Verdana', label: <div style={{fontFamily:'verdana'}} className="flex-iac settings-item">Verdana</div> },
  ];

  const optionsTime = [
    { value: 'thisweek', label: <div className="flex-iac settings-item"><img src={thisWeekIcon} className="select-icon"/> {props.t('allgemein.zeitraumfilter.dieseWoche')}</div> },
    { value: 'thismonth', label: <div className="flex-iac settings-item"><img src={thisMonthIcon} className="select-icon"/> {props.t('allgemein.zeitraumfilter.diesenMonat')}</div> },
    { value: 'nextmonth', label: <div className="flex-iac settings-item"><img src={thisMonthIcon} className="select-icon"/> Nächster Monat</div> },
    { value: 'thisquartal', label: <div className="flex-iac settings-item"><img src={thisMonthIcon} className="select-icon"/> Dieses Quartal</div> },
    { value: 'nextquartal', label: <div className="flex-iac settings-item"><img src={thisMonthIcon} className="select-icon"/> Nächstes Quartal</div> },
    { value: 'thisyear', label: <div className="flex-iac settings-item"><img src={thisYearIcon} className="select-icon"/> {props.t('allgemein.zeitraumfilter.diesesJahr')}</div> },
  ];

  const [selectedOptionTime, setSelectedOptionTime] = useState({
    value: 'thismonth', label: <div className="flex-iac settings-item"><img src={thisMonthIcon} className="select-icon"/> {props.t('allgemein.zeitraumfilter.diesenMonat')}</div>  });
  


 const [selectedOption, setSelectedOption] = useState( { value: 'text', label: props.t('kalender.textAenderung') })
 const [selectedOptionModus, setSelectedOptionModus] = useState( {  value: 'area', label: props.t('elementeAllgemein.area') })
 const [selectedOptionSerien, setSelectedOptionSerien] = useState( { value: 'daily', label: props.t('allgemein.daily') })
 const [selectedOptionSerienDaily, setSelectedOptionSerienDaily] = useState( {value: 'everyDay', label: "Jeden Tag"})

 const [dateTime1, setDateTime1] = useState(dt1);
 const [dateTime2, setDateTime2] = useState(dt2);


  function dateTimeChange1(props) {
    if (props>dateTime2) {
      setDateTime1(dateTime2) 
    }
    else {
      setDateTime1(props) 
    }

}

function dateTimeChange2(props) {
  setDateTime2(props) 
}

// Function to change month name in the calender with translation

   function switchMonth(thisMonth) {
    switch (thisMonth) {
      case 0:
        setMonth(props.t('calender.january'))
        break;
      case 1:
        setMonth(props.t('calender.february'))
        break;
      case 2:
        setMonth(props.t('calender.march'))
        break;
      case 3:
        setMonth(props.t('calender.april'))
        break;
      case 4:
        setMonth(props.t('calender.may'))
        break;
      case 5:
        setMonth(props.t('calender.june'))
        break;
      case 6:
        setMonth(props.t('calender.july'))
        break;
      case 7:
        setMonth(props.t('calender.august'))
        break;
      case 8:
        setMonth(props.t('calender.september'))
        break;
      case 9:
        setMonth(props.t('calender.october'))
        break;
      case 10:
        setMonth(props.t('calender.november'))
        break;
      case 11:
        setMonth(props.t('calender.december'))
        break;
    }
  }

// Update calender if orientation changes

window.addEventListener("orientationchange", function() {
  let interval = setInterval(updateCalendar,1)

  function updateCalendar() {
  if (calendarRef.current != null) {
   clearInterval(interval)
   setChangeOrientation(current => !current)
  }}

});

// Cancel settings view if clicked outside

$(document).on('click', function(e){

  if (schedulesOpen==true && schedulesContainer.current && !schedulesContainer.current.contains(e.target) && e.target.id!='mobile-schedules' 
  && !$(e.target).is('.settings-item') && !$(e.target).children().hasClass('settings-item')  && !$(e.target).hasClass('select-icon')  ) {
   $(".right-container-se").hide()
   schedulesOpen=false
  }

})

useEffect(() => {      
  switchMonth(moment().month())

 }, [props.lng]);

 useEffect(() => {   
   
 dt1 = new Date(); dt1.setHours(0,0,0,0); setDateTime1(dt1)
 dt2 = new Date(); dt2.setHours(23,59,0,0); setDateTime2(dt2)

}, []);

// Generate Options for the modus in the settings view

  function generateModusOptions() {

    let radioButtons

    if (selectedOptionModus.value=="area") {

      let checked1=true
      let checked2=false
      let checked3=false
      let checked4=false

      if (changeModus.type=="Area") {
        changeModus.value=="0" ? checked1=true : checked1=false
        changeModus.value=="1" ? checked2=true : checked2=false
        changeModus.value=="2" ? checked3=true : checked3=false
        changeModus.value=="3" ? checked4=true : checked4=false
      }

 
      radioButtons=
        <div id="optionModusRadio">
        <label className="flex-iac">
        <input type="radio" name="radio-area" checked={checked2} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"1",label: props.t('lot.modes.ManualFree'),type:'Area'})} /> <div>{props.t('lot.modes.ManualFree')}</div>
        </label>
        <label className="flex-iac">
        <input type="radio" name="radio-area" checked={checked3} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"2",label: props.t('lot.modes.ManualOccupied'),type:'Area'})}/> <div>{props.t('lot.modes.ManualOccupied')}</div>
        </label>
        <label className="flex-iac">
        <input type="radio" name="radio-area" checked={checked1} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"0",label: props.t('lot.modes.Automatic'),type:'Area'})}/> <div>{props.t('lot.modes.Automatic')}</div>
        </label>
        <label className="flex-iac" >
        <input type="radio" name="radio-area" checked={checked4} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"3",label: props.t('lot.modes.ManualBlocked'),type:'Area'})}/> <div>{props.t('lot.modes.ManualBlocked')}</div>
        </label>
        </div>  
      
    }


    else if (selectedOptionModus.value=="numerical") {

      let checked1=true
      let checked2=false
      let checked3=false
 
      if (changeModus.type=="Numerical") {
        changeModus.value=="0" ? checked1=true : checked1=false
        changeModus.value=="1" ? checked2=true : checked2=false
        changeModus.value=="2" ? checked3=true : checked3=false
      }

      radioButtons=
      <div id="optionModusRadio"> 
      <label className="flex-iac">
      <input type="radio" name="radio-numerical" checked={checked1} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"0",label: props.t('numDisplay.modes.Automatic'),type:'Numerical'})}/> <div>{props.t('numDisplay.modes.Automatic')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio" name="radio-numerical" checked={checked2} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"1",label: props.t('numDisplay.modes.Dark'),type:'Numerical'})}/> <div>{props.t('numDisplay.modes.Dark')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio" name="radio-numerical" checked={checked3} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"2",label: props.t('numDisplay.modes.XXX'),type:'Numerical'})}/> <div>{props.t('numDisplay.modes.XXX')}</div>
      </label>
      </div>
      
    }

    else if (selectedOptionModus.value=="Lot") {

      let checked1=true
      let checked2=false
      let checked3=false
      let checked4=false
 
      if (changeModus.type=="Lot") {
        changeModus.value=="0" ? checked1=true : checked1=false
        changeModus.value=="1" ? checked2=true : checked2=false
        changeModus.value=="2" ? checked3=true : checked3=false
        changeModus.value=="3" ? checked4=true : checked4=false
      }

    


      radioButtons=
      <div id="optionModusRadio">
      <label className="flex-iac">
      <input type="radio" name="radio-lot" checked={checked1} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"0",label: props.t('stateOutput.modes.Automatic'),type:'Lot'})}/> <div>{props.t('stateOutput.modes.Automatic')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio" name="radio-lot" checked={checked2} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"1",label: props.t('stateOutput.modes.ManualFree'),type:'Lot'})}/> <div>{props.t('stateOutput.modes.ManualFree')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio" name="radio-lot" checked={checked3} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"2",label: props.t('stateOutput.modes.ManualOccupied'),type:'Lot'})}/> <div>{props.t('stateOutput.modes.ManualOccupied')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio" name="radio-lot" checked={checked4} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"3",label: props.t('stateOutput.modes.ManualDark'),type:'Lot'})}/> <div>{props.t('stateOutput.modes.ManualDark')}</div>
      </label>
      </div>
      
    }

    else if (selectedOptionModus.value=="text") {

      let checked1=true
      let checked2=false
      let checked3=false
      let checked4=false
 
      if (changeModus.type=="Text") {
        changeModus.value=="0" ? checked1=true : checked1=false
        changeModus.value=="1" ? checked2=true : checked2=false
        changeModus.value=="2" ? checked3=true : checked3=false
        changeModus.value=="3" ? checked4=true : checked4=false
      }

    

      radioButtons=
      <div id="optionModusRadio">
      <label className="flex-iac">
      <input type="radio" name="radio-text" checked={checked1} style={{marginRight:'5px'}}  onChange={()=>setChangeModus({value:"0",label:props.t('lot.modes.Automatic'),type:'Text'})}/> <div>{props.t('lot.modes.Automatic')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio" name="radio-text" checked={checked2} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"1",label:props.t('lot.modes.ManualFree'),type:'Text'})}/> <div>{props.t('lot.modes.ManualFree')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio" name="radio-text" checked={checked3} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"2",label:props.t('lot.modes.ManualOccupied'),type:'Text'})}/> <div>{props.t('lot.modes.ManualOccupied')}</div>
      </label>
      <label className="flex-iac" >
      <input type="radio" name="radio-text" checked={checked4} style={{marginRight:'5px'}} onChange={()=>setChangeModus({value:"3",label:props.t('lot.modes.ManualBlocked'),type:'Text'})}/> <div>{props.t('lot.modes.ManualBlocked')}</div>
      </label>
      </div>  
      
    }

    else if (selectedOptionModus.value=="zone") {

      let checked1=true
      let checked2=false
      let checked3=false
      let checked4=false


      let checked1_0=false
      let checked2_0=false
      let checked3_0=false
 
      let checked1_1=false
      let checked2_1=false
      let checked3_1=false
 
      let checked1_2=false
      let checked2_2=false
      let checked3_2=false

      let checked1_3=false
      let checked2_3=false
      let checked3_3=false
 
 
      if (changeZoneModus.type=="Zone") {
        changeZoneModus.value=="0" ? checked1=true : checked1=false
        changeZoneModus.value=="1" ? checked2=true : checked2=false
        changeZoneModus.value=="2" ? checked3=true : checked3=false
        changeZoneModus.value=="3" ? checked4=true : checked4=false

        changeZoneModus_0.value=="0" ? checked1_0=true : checked1_0=false
        changeZoneModus_0.value=="1" ? checked2_0=true : checked2_0=false
        changeZoneModus_0.value=="2" ? checked3_0=true : checked3_0=false

        changeZoneModus_1.value=="0" ? checked1_1=true : checked1_1=false
        changeZoneModus_1.value=="1" ? checked2_1=true : checked2_1=false
        changeZoneModus_1.value=="2" ? checked3_1=true : checked3_1=false

        changeZoneModus_2.value=="0" ? checked1_2=true : checked1_2=false
        changeZoneModus_2.value=="1" ? checked2_2=true : checked2_2=false
        changeZoneModus_2.value=="2" ? checked3_2=true : checked3_2=false

        changeZoneModus_3.value=="0" ? checked1_3=true : checked1_3=false
        changeZoneModus_3.value=="1" ? checked2_3=true : checked2_3=false
        changeZoneModus_3.value=="2" ? checked3_3=true : checked3_3=false

      }


     
     

  
      radioButtons=
      <div id="optionModusRadio">

<div className="flex-iac" style={{marginBottom:'10px'}}>   
<label className="flex-iac" style={{marginRight:'5px'}}>
    <input type="radio" name="radio-zoneMode" checked={checked1} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus({value:"0",label: props.t('zoneDisplay.leftArrow'),type:'Zone'})}/> 
    <svg id="zone-arrow-1" fill={"gray"} viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z"/></svg> 
   </label> 

   <label className="flex-iac" style={{marginRight:'5px'}}>
    <input type="radio"name="radio-zoneMode" checked={checked2} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus({value:"1",label: props.t('zoneDisplay.aheadArrow'),type:'Zone'})}/> 
    <svg id="zone-arrow-2" fill={"gray"}  viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z"/></svg> 
   </label> 

   <label className="flex-iac" style={{marginRight:'5px'}}>
    <input type="radio" name="radio-zoneMode" checked={checked3} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus({value:"2",label: props.t('zoneDisplay.rightArrow'),type:'Zone'})}/> 
    <svg id="zone-arrow-3" fill={"gray"}  viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z"/></svg> 
   </label>  

   <label className="flex-iac" style={{marginRight:'5px'}}>
    <input type="radio" name="radio-zoneMode" checked={checked4} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus({value:"3",label: props.t('zoneDisplay.symbol'),type:'Zone'})}/> 
    <svg id="zone-arrow-4" fill={"gray"} viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
   </label>    

  
    </div>

    {changeZoneModus.value=="0" && 

      <>
      <label className="flex-iac">
      <input type="radio" name="radio-zone_0" checked={checked1_0} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_0({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})} /> <div>{props.t('zoneDisplay.modes.Automatic')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio"  name="radio-zone_0"  checked={checked2_0} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_0({value:"1",label:props.t('zoneDisplay.modes.ManualOn'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOn')}</div>
      </label>
      <label className="flex-iac">
      <input type="radio"  name="radio-zone_0"  checked={checked3_0} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_0({value:"2",label:props.t('zoneDisplay.modes.ManualOff'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOff')}</div>
      </label>
      </>
    }

 {changeZoneModus.value=="1" && 

<>
<label className="flex-iac">
<input type="radio" name="radio-zone_1" checked={checked1_1} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_1({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})} /> <div>{props.t('zoneDisplay.modes.Automatic')}</div>
</label>
<label className="flex-iac">
<input type="radio"  name="radio-zone_1"  checked={checked2_1} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_1({value:"1",label:props.t('zoneDisplay.modes.ManualOn'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOn')}</div>
</label>
<label className="flex-iac">
<input type="radio"  name="radio-zone_1"  checked={checked3_1} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_1({value:"2",label:props.t('zoneDisplay.modes.ManualOff'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOff')}</div>
</label>
</>
}

{changeZoneModus.value=="2" && 

<>
<label className="flex-iac">
<input type="radio" name="radio-zone_2" checked={checked1_2} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_2({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})} /> <div>{props.t('zoneDisplay.modes.Automatic')}</div>
</label>
<label className="flex-iac">
<input type="radio"  name="radio-zone_2"  checked={checked2_2} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_2({value:"1",label:props.t('zoneDisplay.modes.ManualOn'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOn')}</div>
</label>
<label className="flex-iac">
<input type="radio"  name="radio-zone_2"  checked={checked3_2} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_2({value:"2",label:props.t('zoneDisplay.modes.ManualOff'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOff')}</div>
</label>
</>
}

{changeZoneModus.value=="3" && 

<>
<label className="flex-iac">
<input type="radio" name="radio-zone_3" checked={checked1_3} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_3({value:"0",label:props.t('zoneDisplay.modes.Automatic'),type:'Zone'})} /> <div>{props.t('zoneDisplay.modes.Automatic')}</div>
</label>
<label className="flex-iac">
<input type="radio"  name="radio-zone_3"  checked={checked2_3} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_3({value:"1",label:props.t('zoneDisplay.modes.ManualOn'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOn')}</div>
</label>
<label className="flex-iac">
<input type="radio"  name="radio-zone_3"  checked={checked3_3} style={{marginRight:'5px'}} onChange={()=>setChangeZoneModus_3({value:"2",label:props.t('zoneDisplay.modes.ManualOff'),type:'Zone'})}/> <div>{props.t('zoneDisplay.modes.ManualOff')}</div>
</label>
</>
} 

      </div>


      
    }

    return (radioButtons)

  }

  // Open settings view when dates are selected in the calender

function popUp() {

  function changeLight() {

    function handleLightOptions(opt) {
      if (selectedLightOptions.includes(opt)) {
        selectedLightOptions.splice(selectedLightOptions.indexOf(opt), 1)
      }
      else {
        selectedLightOptions.push(opt)
      }
    
      setMakeUpdate(current => !current)
    }
    

return (

  <div className="flex-sb2">
  <div style={{width:'45%'}}>    
  <div className="content-group-name">Bereich</div> 
<div className="list-elements" style={{height:'280px'}}>
    <SimpleBar style={{height:'100%'}}>
     <Tree type={"Light"} modus={selectedOptionModus.value}></Tree>
     </SimpleBar>
 </div> 
</div> 

<div style={{width:'50%'}}>
<div className="content-group-name">{props.t('elementeAllgemein.helligkeitInProzent')}</div> 
  <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100} value={lightValue} onChange={e=>setLightValue(e)} />
  <div style={{marginTop:'15px'}}className="content-group-name">{props.t('occupancy.einstellung_helligkeitstimer.gueltigFuer')}</div> 

  {lightOptions.map(item=> {
    let checked=selectedLightOptions.includes(item.value) ? true : false
     return (
      <FormControlLabel 
  onChange={()=>handleLightOptions(item.value)}value="start" control={<Checkbox checked={checked} color="primary" size="small" />} label={item.label} labelPlacement="end" />
     )
 
     })}
  




     </div>
     </div> )
  }



  function changeModusOptions() {

 
      return (
      <div className="flex-col">

<div className="content-group-name" style={{width:'100%'}}>Element Typ</div> 


{selectedOption.value=="modus" && 
          
          <div id="changeModusRadio" style={{width:'100%',display:'flex',flexWrap:'wrap'}}>
                {optionsModus.map(item=>{
                  return (
            <label className="flex-iac" style={{marginRight:'10px'}}>
            <input type="radio" name="radio-display" style={{marginRight:'5px'}} onChange={()=>setSelectedOptionModus(item)} /> <div>{item.label}</div>
            </label>
                  )
                })}
            </div> }

   

      <div style={{width:'100%'}} className="flex-sb2"> 

    <div style={{width:'45%'}}> 

<div className="content-group-name">Elemente</div> 
<div className="list-elements" style={{height:'220px'}}>
    <SimpleBar style={{height:'100%'}}>
    <Tree type={"Modus"} modus={selectedOptionModus.value}></Tree>
     </SimpleBar>
     </div>
 </div> 

 <div style={{width:'50%'}}>
    <div className="content-group-name">{props.t('allgemein.modus')}</div> 
      {generateModusOptions()}
        </div>
        </div>

       
       </div>
      )
      
      
  
  }

  function changeText() {

   let checkedCounting=false
   let checkedText=false

   textDisplayMode.value=="countingNumber" ? checkedCounting=true : checkedCounting=false
   textDisplayMode.value=="text" ? checkedText=true : checkedText=false


      return (
      <div className="flex-sb2">
    <div style={{width:'45%'}}>    
    <div className="content-group-name">Text Display</div> 
<div className="list-elements" style={{height:'280px'}}>
    <SimpleBar style={{height:'100%'}}>
    <Tree type={"Text"} modus={selectedOptionModus.value}></Tree>
     </SimpleBar>
 </div> 
 </div> 

 <div style={{width:'50%'}}>
 <div className="content-group-name">{props.t('allgemein.modus')}</div> 
         
         <div className="flex-iac">
         <label className="flex-iac">
            <input class="input-element" type="radio" checked={checkedCounting} name="radio-display" style={{marginRight:'5px'}} onChange={()=>setTextDisplayMode({ value: 'countingNumber', label: props.t('textDisplay.belegungszahl') })}/> <div>{props.t('textDisplay.belegungszahl')}</div>
            </label>
            </div>
            <div className="flex-iac" style={{marginBottom:'10px',width:'100%'}}>
            <label className="flex-iac" style={{width:'100%'}}>
            <input  type="radio" checked={checkedText} name="radio-display" style={{marginRight:'5px'}}  onChange={()=>setTextDisplayMode({ value: 'text', label: props.t('textDisplay.text') })}/> <div style={{marginRight:'5px'}}>{props.t('textDisplay.text')}*</div>  
            <div style={{width:'100%'}}><input type="text" style={{width:'100%'}}/></div>
            </label>
            </div>

            <div className="content-group-name">Ausrichtung</div> 

            <div className="flex-iac" style={{marginBottom:'10px'}}>

            {alignOptions.map(item=> {
    let checked=align.value==item.value ? true : false
   return (
    <label className="flex-iac" style={{marginRight:'5px'}}> <input style={{marginRight:'5px'}} checked={checked} type="radio" name="radio-align"  onChange={()=>setAlign(item)}/>{item.label}</label>  
   )
     })}

            </div>
          

        <div className="content-group-name">Farbe</div> 
        <InputColor initialValue="#5e72e4" onChange={setColor} style={{width:'30px'}} />


            <div className="content-group-name" style={{marginTop:'10px'}}>Schriftart</div> 
            <div className="flex-iac">
            <Select className="select select-text-mini" styles={customStyles} isSearchable={false} onChange={props=>setFontFamily(props)}
              options={optionsTextFonts} value={fontFamily} theme={theme => ({...theme, borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff'}})}/>
              <NumericInput className="input-numeric calendar-num" mobile={"auto"} value={fontSize} onChange={e=>setFontSize(e)} min={1} max={50} />
              </div>
      
     </div>

     </div>

      )
    }
      


  function renderOption() {

    if (selectedOption.value=="light") {
      return (
        changeLight()
      )
    }

    else if (selectedOption.value=="text") {
      return (
        changeText()
      )
    }

    else if (selectedOption.value=="modus") {
      return (
        changeModusOptions()
      )
    }


  }


  function change(props) {
    setSelectedOption(props)
   }


  function clickX() {

    if (lastElement!=undefined) {
      lastElement.clearGuideElement()
   }

    setPopUpShow(false)
    $('.tui-full-calendar-layout').css('pointer-events','unset')
    $('#menu').css('pointer-events','unset')
  }



function renderStep() {

  function changeModusOption(props) {
    setSelectedOptionModus(props)
   }


let dateStyle
let timeStyle= {
  width:'30%'
}


if (wholeDay) {
  timeStyle={
    opacity:'0.5',
    pointerEvents:'none',
    width:'30%'
   }
}

if (always) {
  dateStyle={
    opacity:'0.5',
    pointerEvents:'none'
   }

   timeStyle={
    opacity:'1',
    pointerEvents:'none',
    width:'30%'
   }
}

function arrayRemove(arr, value) { 
    
  return arr.filter(function(ele){ 
      return ele != value; 
  });
}


function handleDays(day) {
  if (selectedMultipleDays.includes(day)) {
    selectedMultipleDays.splice(selectedMultipleDays.indexOf(day), 1)
  }
  else {
    selectedMultipleDays.push(day)
  }

  setMakeUpdate(current => !current)
}

function timeEndChange(newValue) {
  if (newValue<valueStart) {
    setValueEnd(valueStart)
  }
  else {
    setValueEnd(newValue)
  }

  }

 




    switch(step) {
      case 1:
     
        let checkedAlways
        let checkedTermin

        if (always==true) {
          checkedAlways=true 
          checkedTermin=false
        } else {
          checkedAlways=false
          checkedTermin=true
        }

        return (
          <div style={{marginTop:'15px'}}>
            <div className="content-group-name">Name</div>
          <input type="text" style={{width:'100%'}} value={title} onChange={(e)=> setTitle(e.target.value) } />
                  

                  <div className="content-group-name" style={{marginTop:'15px'}}>Termin</div>
                  <div  style={dateStyle}>

                    <div className="flex-sb">
                    <div style={{width:'50px'}}>Beginn :</div>
                 <DateTimePicker className="title-calendar-datetime" value={dateTime1} min={new Date()} time={false} onChange={dateTimeChange1}/>
                 <div style={timeStyle}>
{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
 <TimePicker ampm={false} label="" inputFormat="HH:mm" value={valueStart} onChange={(newValue) => {setValueStart(newValue)}} renderInput={(params) => <TextField {...params} />} /> 
   </LocalizationProvider> */}
</div>
</div>

<div className="flex-sb"  style={{marginTop:'15px'}}>
<div style={{width:'50px'}}>Ende :</div>
<DateTimePicker className="title-calendar-datetime" minDate={dateTime1} value={dateTime2} min={dateTime1} time={false} onChange={dateTimeChange2}/> 
<div style={timeStyle}>
{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
 <TimePicker ampm={false} label="" inputFormat="HH:mm" value={valueEnd} onChange={timeEndChange} renderInput={(params) => <TextField {...params} />} /> 
   </LocalizationProvider> */}
</div>
</div>
              
                  </div>
                 <div className="flex-sb" style={{marginTop:'15px'}}>   

          <div className="flex-sb">
          <label className="flex-iac">
        <input type="radio" name="radio-always" style={{marginRight:'5px'}} checked={checkedAlways} onChange={()=>setAlways(true)} /> <div>Immer</div>
        </label>
        <label className="flex-iac" style={{marginLeft:'10px'}}>
        <input type="radio" name="radio-always" style={{marginRight:'5px'}} checked={checkedTermin} onChange={()=>setAlways(false)} /> <div>Endet nach <input type="number" value={termin} onChange={e=>setTermin(e.target.value)} style={{width:'40px',marginLeft:'10px',marginRight:'10px'}} /> Terminen </div>
        </label>
          </div>

          <div style={{marginTop:'-10px'}}><Checkbox color="primary" size="small" checked={wholeDay} onChange={()=>setWholeDay(current => !current)} />Ganztägig</div>
      
        
           </div>

                 <div className="content-group-name" style={{marginTop:'15px'}}>Serienmuster</div>

                 <Select styles={customStyles} isSearchable={false} defaultValue={selectedOptionSerien} options={optionsSerien} onChange={(props)=>setSelectedOptionSerien(props)} theme={theme => ({...theme, borderRadius: 3,
          colors: {...theme.colors, primary: '#20a0ff', },})}/> 
      
           {selectedOptionSerien.value=="daily" && 
                 <div style={{marginTop:'15px'}}>
      <Select styles={customStyles} isSearchable={false}  defaultValue={selectedOptionSerienDaily} options={optionsSerienDaily} onChange={(props)=>setSelectedOptionSerienDaily(props)} theme={theme => ({...theme, borderRadius: 3,
        colors: {...theme.colors, primary: '#20a0ff', },})}/> </div> }

{selectedOptionSerien.value=="weekly" && 
<div className="flex-iac" style={{marginTop:'15px',marginLeft:'-5px'}}>
  {dayNames.map(item=> {
   let checked=selectedMultipleDays.includes(item.value) ? true : false

    return (
      <div className="flex-c">
      <Checkbox color="primary" checked={checked} size="small" onChange={()=>handleDays(item.value)} />{item.label} 
      </div>
    )

    }
  )
  }
     </div>

}
          

      <button class="button button-left" onClick={closePopUp}>Abbrechen</button>   
         <button class="button button-right" onClick={()=>setStep(2)}>{props.t('allgemein.weiter')}</button>       
          </div>
        )
      case 2:
        return (
          <div style={{marginTop:'15px'}}>
                <div className="content-group-name">Änderung</div>
            <Select styles={customStyles} isSearchable={false} defaultValue={selectedOption} onChange={change} options={options} theme={theme => ({...theme, borderRadius: 3,
          colors: {...theme.colors, primary: '#20a0ff', },})}/> 

    

<div style={{marginTop:'15px',display:'flex',width:'100%',justifyContent:'space-between'}} >
<button class="button button-left" onClick={()=>setStep(1)}>{props.t('allgemein.zurueck')}</button>   
<button class="button button-right" onClick={()=>setStep(3)}>{props.t('allgemein.weiter')}</button>       
</div>
  

          </div>

        )
      case 3:
        return (
          <div style={{width:'100%',marginTop:'15px'}}>
          {renderOption()}
          <div style={{marginTop:'15px',display:'flex',width:'100%',justifyContent:'space-between'}} >
<button class="button button-left" onClick={()=>setStep(2)}>{props.t('allgemein.zurueck')}</button>   
<button class="button button-right" onClick={()=>setStep(4)}>{props.t('allgemein.weiter')}</button>       
</div>    
          </div>
        )
          case 4:
            return (
              <div style={{marginTop:'15px'}}>
           <div>Name: {title}</div>
{always==true 
? <div>Kein Enddatum</div>
: <>
<div style={{display:'flex'}}><div style={{width:'50px'}}>Beginn: </div> <div>{moment(dateTime1).format('DD.MM.YYYY')}</div>

{wholeDay==true 
? <div style={{marginLeft:'5px'}}>00:00</div>
: <div style={{marginLeft:'5px'}}>
{moment(valueStart).format('HH:mm')} 
</div> }

</div> 
<div style={{display:'flex'}}><div style={{width:'50px'}}>Ende: </div> <div>{moment(dateTime2).format('DD.MM.YYYY')}</div>
{wholeDay==true 
? <div style={{marginLeft:'5px'}}>23:59</div>
: <div style={{marginLeft:'5px'}}>
{moment(valueEnd).format('HH:mm')} 
</div> }

</div> 
<div> Endet nach {termin} Terminen </div> 
</>
             }

        
<div className="content-group-name" style={{marginTop:'10px'}}>Serienmuster</div> 
<div style={{display:'flex'}}>
            {selectedOptionSerien.label} 
            {selectedOptionSerien.value=="daily" &&
            <div style={{marginLeft:'5px'}}>
            ({selectedOptionSerienDaily.label})
            </div>
            }
            {selectedOptionSerien.value=="weekly" &&
            <div style={{marginLeft:'5px'}}>
            ({selectedMultipleDays.join(",")})
            </div>
            }
            </div>
              
           
            <div>
               <div className="content-group-name" style={{marginTop:'10px'}}>Wert zu ändern: </div> 
               <div style={{display:'flex'}}>
              {selectedOption.label}
              {selectedOption.value=="modus" &&
              <div style={{marginLeft:'5px'}}>({selectedOptionModus.label})</div>
               }
                   </div>
              </div>
             

              {selectedOption.value=="modus" &&
               <>
                <div className="content-group-name" style={{marginTop:'10px'}}>Details: </div> 
                
                {selectedOptionModus.value=="zone" ?
                <>
                <div>{props.t('zoneDisplay.leftArrow')}: {changeZoneModus_0.label} </div>
                <div>{props.t('zoneDisplay.aheadArrow')}: {changeZoneModus_1.label} </div>
                <div>{props.t('zoneDisplay.rightArrow')}: {changeZoneModus_2.label} </div>
                <div>{props.t('zoneDisplay.symbol')}: {changeZoneModus_3.label} </div>
                </> 
                : <div>Modus: {changeModus.label}</div>
              }

               <div>Element: </div>
   
               </>
               }

               {selectedOption.value=="text" &&
               <>
                <div className="content-group-name" style={{marginTop:'10px'}}>Details: </div> 
               <div>Element: </div>
                <div>Anzeige: {textDisplayMode.label}</div>
                <div>Farbe: RGB ({color.r},{color.g},{color.b})</div>
               <div>Ausrichtung: {align.label}</div> 
                <div>Schrift: {fontFamily.value} , Größe: {fontSize} </div> 
               </>
               }

               {selectedOption.value=="light" &&
               <>
                <div className="content-group-name" style={{marginTop:'10px'}}>Details: </div> 
                <div>Bereich: </div>
               <div>Helligkeit: {lightValue}</div>
               <div>Gültig für: {selectedLightOptions.join(",")}</div>
               </>
               }

               <button class="button button-left" onClick={()=>setStep(3)}>{props.t('allgemein.zurueck')}</button>  
               <button class="button button-right" onClick={closePopUp}>{props.t('allgemein.speichern')}</button> 
              </div>
            )
        default:
          return(
            <></>
          )
    }

  
}



function closePopUp() {
  clickX()
  setStep(1)
}

let widthStyle

if (step==2 || step==3) {
  widthStyle={width:'65%'}
}
else {
  widthStyle={width:'70%'}
}


  return (
    <div id="popUp">
        <div className="div-name">Eintrag</div>
      <div className="flex-sa2">
    <div style={widthStyle}>
        {/* <button class="tui-full-calendar-button tui-full-calendar-popup-close" onClick={closePopUp}><span class="tui-full-calendar-icon tui-full-calendar-ic-close"></span></button> */}
    {renderStep()}
    </div>
    {(step==2 || step==3) &&
        <div style={{width:'30%'}}>
        <div className="content-group-name" style={{marginTop:'15px'}}>Übersicht</div> 
        <div>Name: {title}</div>


            {always==true 
        ? <div>Kein Enddatum</div>
        : <>
        <div style={{display:'flex'}}><div style={{width:'50px'}}>Beginn: </div> <div>{moment(dateTime1).format('DD.MM.YYYY')}</div>

        {wholeDay==true 
        ? <div style={{marginLeft:'5px'}}>00:00</div>
        : <div style={{marginLeft:'5px'}}>
        {moment(valueStart).format('HH:mm')} 
        </div> }

        </div> 
        <div style={{display:'flex'}}><div style={{width:'50px'}}>Ende: </div> <div>{moment(dateTime2).format('DD.MM.YYYY')}</div>
        {wholeDay==true 
        ? <div style={{marginLeft:'5px'}}>23:59</div>
        : <div style={{marginLeft:'5px'}}>
        {moment(valueEnd).format('HH:mm')} 
        </div> }
        
         </div> 
        <div> Endet nach {termin} Terminen </div> 
        </>

      }




            <div className="content-group-name" style={{marginTop:'10px'}}>Serienmuster</div> 
            <div style={{display:'flex',flexWrap:'wrap'}}>
            <div style={{marginRight:'5px'}}>
            {selectedOptionSerien.label} 
            </div>
            {selectedOptionSerien.value=="daily" &&
            <>
              ({selectedOptionSerienDaily.label})
            </>
            }
            {selectedOptionSerien.value=="weekly" &&
            <>
            ({selectedMultipleDays.join(",")})
            </>
            }
            </div>
              
             {step==3 &&
            <div>
               <div className="content-group-name" style={{marginTop:'10px'}}>Wert zu ändern: </div> 
               <div style={{display:'flex',flexWrap:'wrap'}}>
               <div style={{marginRight:'5px'}}>
              {selectedOption.label}
              </div>
              {selectedOption.value=="modus" &&
              <>({selectedOptionModus.label})</>
               }
                   </div>
              </div>
             }
        </div>
    }
    </div>
</div>
  )
}

// Arrows to navigate in the calendar

let handleClickTodayButton = () => {
    calendarRef.current.getInstance().today(); 

    if (calendarRef.current!=undefined) {
      setYear(moment(calendarRef.current.calendarInst._renderDate._date).format('YYYY'))
      }

    switchMonth(moment(calendarRef.current.calendarInst._renderDate._date).month())

}

let handleClickPrevButton = () => {
    calendarRef.current.getInstance().prev();

    if (calendarRef.current!=undefined) {
      setYear(moment(calendarRef.current.calendarInst._renderDate._date).format('YYYY'))
      }

      switchMonth(moment(calendarRef.current.calendarInst._renderDate._date).month())

};

let handleClickNextButton = () => {
    calendarRef.current.getInstance().next();

    if (calendarRef.current!=undefined) {
      setYear(moment(calendarRef.current.calendarInst._renderDate._date).format('YYYY'))
      }

      switchMonth(moment(calendarRef.current.calendarInst._renderDate._date).month())

  };

// Render calendar from the library

  const kalenderS = React.useMemo(() => {
    
    let handleBeforeCreateSchedule = (e) => {

      if(e.start._date<new Date) {
       lastElement= e.guide  
       lastElement.clearGuideElement()
       return
      }

  
      setPopUpShow(true)
         $('.tui-full-calendar-layout').css('pointer-events','none')
         $('#menu').css('pointer-events','none')
    
        let date1=moment(e.start._date)
        let date2=moment(e.end._date)       

        dt1 = new Date(date1.format('YYYY,MM,DD')); dt1.setHours(0,0,0,0);
        dt2 = new Date(date2.format('YYYY,MM,DD')); dt2.setHours(23,59,0,0);

        setDateTime1(dt1)
        setDateTime2(dt2)
    
        if (lastElement!=undefined) {
           lastElement.clearGuideElement()
          // setMakeUpdate(current => !current)
        }
      
        lastElement= e.guide  

    };


    

    let handleBeforeUpdateSchedule = (e) => {
      setPopUpShow(true)
      return
      // console.group('onBeforeUpdateSchedule');
      e.schedule.start = e.start;
      e.schedule.end = e.end;
      // console.log(calendarRef.current)
      // calendarRef.current.calendarInst.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
    };

    let handleBeforeDeleteSchedule = (e) => {
      // console.log('onBeforeDeleteSchedule', e);
      // console.log(calendarRef.current)
      calendarRef.current.calendarInst.deleteSchedule(e.schedule.id, e.schedule.calendarId);
    };

    let handleClickSchedule = (e) => {
      // console.log('onClickSchedule', e);
      const { calendarId, id } = e.schedule;
      const el =  calendarRef.current.calendarInst.getElement(id, calendarId);
      // console.log(el.getBoundingClientRect());
    };

      return (
      <div style={{height:'calc(100% - 50px)'}}>
    <Calendar
    ref={calendarRef}
    height="100%"
    disableDblClick={true}
    disableClick={false}
    isReadOnly={false}
    usageStatistics={false}
    taskView={false}
    useDetailPopup={true}
    useCreationPopup={false}
    view="month"
    theme= {CalendarTheme} 


  template={{
  
    popupDetailDate(isAllDay, start, end) {
      let startDate= moment(start._date).format('DD.MM.YYYY')
      let endDate= moment(end._date).format('DD.MM.YYYY')
      let isSameDate
      if(startDate===endDate) {
        isSameDate=true
      }
      else {
        isSameDate=false
      }
      let endFormat = (isSameDate ? '' : 'DD.MM.YYYY ') + 'HH:mm';
      return (moment(start._date).format('DD.MM.YYYY HH:mm') + ' - ' + moment(end._date).format(endFormat));
    },

    popupEdit: function() {
      return props.t('allgemein.Bearbeiten');
  },
    popupDelete: function() {
      return props.t('allgemein.Loeschen');
  }

  }}

    month={{
        daynames: [props.t('allgemein.tageKurz.Monday'), props.t('allgemein.tageKurz.Tuesday'), props.t('allgemein.tageKurz.Wednesday'),
        props.t('allgemein.tageKurz.Thursday'),props.t('allgemein.tageKurz.Friday'),props.t('allgemein.tageKurz.Saturday'),props.t('allgemein.tageKurz.Sunday')],
        startDayOfWeek: 0,
       showTimezoneCollapseButton: true,
       timezonesCollapsed: true
    }}

    schedules={schedules}

   onBeforeCreateSchedule={handleBeforeCreateSchedule}
   onClickSchedule={handleClickSchedule}
   onBeforeUpdateSchedule={handleBeforeUpdateSchedule}
  onBeforeDeleteSchedule={handleBeforeDeleteSchedule}
  />
  </div>
  )}, [changeOrientation])

// Header of the calendar

  function header() {
    
    function openSchedules() {
      schedulesOpen=true
      $('.right-container-se').show()
    }

    return(
      <div id="menu" className="flex-sb" style={{height:'50px'}}>
      <span id="renderRange" class="render-range" style={{fontSize:'22px',fontWeight:'bold', marginLeft:'15px'}}>{year}  {month}</span> 
      <span id="menu-navi"  className="flex-iac">
      <img className="mobile-schedules-icon" id="mobile-schedules" src={thisMonthIcon} style={{marginRight:'10px'}} onClick={openSchedules}/>
     <div class="btn btn-default btn-sm move-today" data-action="move-today" style={{fontSize:'13px',fontWeight:'bold'}} onClick={handleClickTodayButton}>
       {props.t('allgemein.zeitraumfilter.heute')}</div>
       <div class="btn btn-sm move-day" data-action="move-next" onClick={handleClickPrevButton}>
       <svg class="MuiSvgIcon-root" style={{height:'24px',width:'24px'}} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
         <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
       </div> 
        <div class="btn btn-sm move-day" data-action="move-next" onClick={handleClickNextButton}>
      <svg style={{height:'24px',width:'24px'}}class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
           <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>
       </div>    
     </span>
   </div>
    )
  }

// Render hover over schedules view

  function showInfoSchedule() {

    return(
      <div id="showinfo" style={hoverSchedule}>
        <div className="flexcontainer">
     <div className="tablehovername">{currentData.title}</div>
 <div className="table2-container" style={{marginTop:'10px'}}>
   <table className="table2">
   <thead className="thead2">
     <tr>
       <th className="th2" style={{fontSize:'11px'}}>{props.t('kalender.tableTimerList.headerAenderung')}</th>
       <th className="th2" style={{fontSize:'11px'}}>{props.t('kalender.tableTimerList.headerElemente')}</th>
       <th className="th2" style={{fontSize:'11px'}}>{props.t('kalender.tableTimerList.headerWert')}</th>
     </tr>
   </thead>

 </table>
 </div>
 </div>
   </div>
   )
  }

// Render calendar with all the elements

   function calendar() {
     return(
      <div className="left-container-se" >
      <div style={{height:'100%'}}>
  {header()}
  {kalenderS}
  </div>
  {popUpShow==true && (
  <div>{popUp()}</div>)}  
  </div>
     )
   }


  // Render all schedules on the side
   function allSchedules() {

    function mouseMove(data) {
      currentData=data
      showInfo()
     $('#showinfo').show()
     setHover(current => !current)
     }
    
  
     function mouseLeave() {
      $('#showinfo').hide()
      setHover(false)
     }
  
     function clickSchedule() {
      setPopUpShow(true)
      $('.tui-full-calendar-layout').css('pointer-events','none')
      $('#menu').css('pointer-events','none')
     }
  
     
     function changeTime(props) {
      setSelectedOptionTime(props)
     }
  
     function schedulesClick() {
      clickSchedule()
      // $('.right-container-se').hide()
     }

     function renderSchedules(){

      let array=[]

     for (let i =0; i < schedules.length;i++) {

      function getZero(num) {
        let str
          (num<10) ? str="0"+num.toString() : str=num
          return str
      }

      let start=schedules[i].start
      let end=schedules[i].end

      let day=parseInt(start.getDate())
      let month=parseInt(start.getMonth())+1
 
      let year=start.getFullYear()
      let weekDay

      switch (start.getDay()) {
        case 0:
          weekDay= props.t('allgemein.tage.Sunday') 
          break; 

        case 1:
          weekDay= props.t('allgemein.tage.Monday') 
          break;

        case 2:
          weekDay= props.t('allgemein.tage.Tuesday') 
          break;  
          
        case 3:
          weekDay= props.t('allgemein.tage.Wednesday') 
          break; 
          
        case 4:
          weekDay= props.t('allgemein.tage.Thursday') 
          break; 
          
        case 5:
          weekDay= props.t('allgemein.tage.Friday') 
          break; 
          
        case 6:
          weekDay= props.t('allgemein.tage.Saturday') 
          break; 
 
        default:
          weekDay= ""

      }


      let fullDate=getZero(day)+"."+getZero(month)+"."+year

      let startHours=parseInt(start.getHours())
      let startMinutes=parseInt(start.getMinutes())
      let startHM = getZero(startHours) + ":" + getZero(startMinutes)

      let endHours=parseInt(end.getHours())
      let endMinutes=parseInt(end.getMinutes())
      let endHM = getZero(endHours) + ":" + getZero(endMinutes)

      array.push(
        <div>
        <div className="flex-sb day-cal">
        <div>{fullDate}</div>
        <div>{weekDay}</div>
        </div>
    
        <div className="flex-iac schedule-cal"  onMouseLeave={mouseLeave} onMouseMove={()=>mouseMove(schedules[i])} onClick={schedulesClick}>
          <div>{startHM} - {endHM}</div>
           <div style={{marginLeft:'40px'}}>{schedules[i].title}</div>
        </div>
        </div>
      )



     }

        
       return(
         <div>
          {array}
        </div>
       )
      }
  

    return (
      <div className="right-container-se" ref={schedulesContainer}>
      <div className="content-name">{props.t('allgemein.eintraege')}</div>
  <div style={{margin:'5px 5px 5px 5px'}}>
  <Select styles={customStyles}isSearchable={false} defaultValue={selectedOptionTime} onChange={changeTime} options={optionsTime} theme={theme => ({...theme, borderRadius: 3,
     colors: { ...theme.colors, primary: '#20a0ff',},})} />
     </div>
    {renderSchedules()}
   </div>
    )}

    return (
      <div>
        <div className="pagewrapper-2">
         {calendar()}
        {allSchedules()}   
       </div>
       {showInfoSchedule()} 
      </div>
      )}  
      
export default withNamespaces()(Kalender)
