
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import ReactLoading from 'react-loading';

import $ from "jquery";
import Checkbox from '@material-ui/core/Checkbox';

//collapse/expand pictures
import dd1Icon from '../../../assets/images/mgsuite/collapse.png';
import dd2Icon from '../../../assets/images/mgsuite/expand.png';

import jwt_decode from "jwt-decode";

let dd1 = dd1Icon
let dd2 = dd2Icon

let oneParkingLot

let mainParkingLots = []
let subParkingLots = []
let areaParkingLots = []

let sites = []
let statefirstarea = {}

let statemain = {}
let statesub = {}
let statemainarea = {}
let statesubarea = {}
let pageLoaded = false
let selectedAreas = []

// Generate area tree for all areas

const AllAreasTree = (props) => {

  let link = localStorage.getItem('link')

  const [makeUpdate, setMakeUpdate] = useState(false);

  const [loaderShow, setLoaderShow] = useState(false);

  const [showLevelMain, setShowLevelMain] = useState({});
  const [showLevelSub, setShowLevelSub] = useState({});
  const [showLevelFirst, setShowLevelFirst] = useState(statefirstarea);

  function selectArea(carParkId, id, name, e) {

    if ($(e.target).is('img')) {
      return
    }
    selectedAreas[0]=id

    props.onChange({'areaIdForDimmer':selectedAreas[0]})

    setMakeUpdate(current => !current)
  }

  useEffect(() => {

    selectedAreas = []

    if (props.data != -1) {
      selectedAreas[0]=props.data
      setMakeUpdate(current => !current)
    }

    if (pageLoaded) {
      setLoaderShow(false)
    }
    else {
      setLoaderShow(true)
    }


    let tokenExpired = null

    function delay(timer) {
      return new Promise(resolve => {
        setTimeout(function () {
          if (localStorage.getItem('JWT')) {
            let decoded = jwt_decode(localStorage.getItem('JWT'));
            let diff = decoded.exp * 1000 - Date.now()

            if (diff <= 0) {
              tokenExpired = true
            }
            else {
              tokenExpired = false
            }

          }

          resolve()
        }, timer);
      });
    };

    async function getAreaData() {
      while (tokenExpired == null || tokenExpired == true)
        await delay(50);

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');

      axios.get(link + '/api/Area/sites')
        .then((response) => {
          sites = []
          sites = response.data

          for (let i = 0; i < sites.length; i++) {
            statefirstarea[i] = true
          }

          setMakeUpdate(current => !current)


        })

      axios.get(link + '/api/Area')
        .then((response) => {

          mainParkingLots = []
          subParkingLots = []
          areaParkingLots = []

          for (let i = 0; i < response.data.length; i++) {

            if (response.data[i].areaType == 2) {
              mainParkingLots.push(response.data[i])
            }

            if (response.data[i].areaType == 3) {
              subParkingLots.push(response.data[i])
            }

            if (response.data[i].areaType == 4 || response.data[i].areaType == 5) {
              areaParkingLots.push(response.data[i])
            }

          }

          for (let i = 0; i < areaParkingLots.length; i++) {


            for (let j = 0; j < mainParkingLots.length; j++) {

              if (areaParkingLots[i].parentAreaId === mainParkingLots[j].id) {
                subParkingLots.push(areaParkingLots[i])
              }
            }



          }

          for (let i = 0; i < mainParkingLots.length; i++) {
            if (oneParkingLot) {
              statemain[i] = true
            }
            else {
              statemain[i] = false
            }

            statemainarea[i] = true
          }

          for (let i = 0; i < subParkingLots.length; i++) {
            statesub[i] = false
            statesubarea[i] = false
          }


          mainParkingLots.sort((a, b) => a.areaTreeSortOrder - b.areaTreeSortOrder);

          pageLoaded = true
          setLoaderShow(false)




        })

        .catch(error => {

        })

    }


    getAreaData()

  }, [props]);


    let tree = []

    function renderChildren(site) {

      let children = []

      for (let i = 0; i < mainParkingLots.length; i++) {

        let correctSite = false

        for (let j = 0; j < site.carParks.length; j++) {
          if (site.carParks[j].id == mainParkingLots[i].id) {
            correctSite = true
          }
        }

        if (correctSite == true) {


          let dropdown = false
          let dropdownIcon
          let checked = false

          if (selectedAreas[0] == mainParkingLots[i].id) {
            checked = true
          }

          for (let j = 0; j < subParkingLots.length; j++) {
            if (mainParkingLots[i].id == subParkingLots[j].parentAreaId) {
              dropdown = true

              if (showLevelMain[i] == true) {
                dropdownIcon = dd2
              }
              else {
                dropdownIcon = dd1
              }
            }
          }

          children.push(
            <div className="park-container" onClick={(e) => selectArea(mainParkingLots[i].carParkId, mainParkingLots[i].id, mainParkingLots[i].name, e)} id={mainParkingLots[i].id} key={"main" + i} name={mainParkingLots[i].name} >
              <div className="flex-iac" style={{ height: '25px', paddingLeft: '50px' }}>
                {dropdown == true && (
                  <img className="dropdown-icon" src={dropdownIcon} onClick={() => setShowLevelMain({ ...showLevelMain, [i]: (!showLevelMain[i]) })} />
                )}
                <div style={ checked ? { backgroundColor: "#20a0ff", color: 'white', marginLeft: '5px' } : {marginLeft: '5px'}} >{mainParkingLots[i].name}</div>
              </div>
            </div>
          )

          for (let j = 0; j < subParkingLots.length; j++) {

            let dropdown = false
            let dropdownIcon
            let checked = false

            if (selectedAreas[0] == subParkingLots[j].id) {
              checked = true
            }

            for (let x = 0; x < areaParkingLots.length; x++) {
              if (subParkingLots[j].id == areaParkingLots[x].parentAreaId) {
                dropdown = true

                if (showLevelSub[j] == true) {
                  dropdownIcon = dd2
                }

                else {
                  dropdownIcon = dd1
                }
              }
            }

            if (mainParkingLots[i].id == subParkingLots[j].parentAreaId) {

              children.push(
                showLevelMain[i] == true && (

                  <div className="park-container" onClick={(e) => selectArea(subParkingLots[j].carParkId, subParkingLots[j].id, subParkingLots[j].name, e)} id={subParkingLots[j].id} key={"sub" + j} name={subParkingLots[j].name}>
                    <div className="flex-iac" style={{ height: '25px', paddingLeft: '70px' }}>
                      {dropdown == true && (
                        <img className="dropdown-icon" src={dropdownIcon} onClick={() => setShowLevelSub({ ...showLevelSub, [j]: (!showLevelSub[j]) })} />
                      )}
                      <div style={ checked ? { backgroundColor: "#20a0ff", color: 'white', marginLeft: '5px' } : {marginLeft: '5px'}} >{subParkingLots[j].name}</div>
                    </div>

                  </div>)
              )

              for (let x = 0; x < areaParkingLots.length; x++) {
                let checked = false

                if (selectedAreas[0] == areaParkingLots[x].id) {
                  checked = true
                }

                if (subParkingLots[j].id == areaParkingLots[x].parentAreaId) {

                  children.push(
                    showLevelMain[i] == true && (
                      showLevelSub[j] == true && (

                        <div id={areaParkingLots[x].id} key={"area" + x} name={areaParkingLots[x].name} className="park-container" onClick={(e) => selectArea(areaParkingLots[x].carParkId, areaParkingLots[x].id, areaParkingLots[x].name, e)}>
                          <div className="flex-iac" style={{ height: '25px', paddingLeft: '90px' }}>
                            <div style={ checked ? { backgroundColor: "#20a0ff", color: 'white' } : {}} >{areaParkingLots[x].name}</div>
                          </div>
                        </div>

                      )
                    )
                  )

                }
              }

            }
          }
        }
      }

      return children

    }

    tree.push(
      <div>
        {loaderShow == true && (
          <div className="loader-mini">
            <ReactLoading type={"bars"} color={"#2a3042"} height={50} width={50} name={"Loading"} />
          </div>)}

        {sites.map((item, idx) => {

          let dropdownIcon
          let dropdown = false

          if (showLevelFirst[idx] == true) {
            dropdownIcon = dd2
          }
          else {
            dropdownIcon = dd1
          }

          return (
            <>
              <div className="park-container">
                <div className="flex-iac" style={{ height: '25px', paddingLeft: '30px' }}>
                {dropdown == true && (<img className="dropdown-icon" src={dropdownIcon} onClick={() => setShowLevelFirst({ ...showLevelFirst, [idx]: (!showLevelFirst[idx]) })} />)}
                  <div style={{ marginLeft: '5px' }}>{item.name}</div>
                </div>
              </div>

              {showLevelFirst[idx] == true && (
                <>
                  {renderChildren(item)}
                </>
              )}

            </>

          )
        })}

      </div>
    )

    return (tree)
}

export default withNamespaces()(AllAreasTree)


