import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";
import NumericInput from 'react-numeric-input';

import Select1 from "react-select";
import customStyles from '../../../../assets/select/customStyles'

const optionsTextFonts=[
  { value: 0, label: <div style={{fontFamily:'arial'}} className="flex-iac settings-item">Arial</div> },
  { value: 1, label: <div style={{fontFamily:'arial narrow'}} className="flex-iac settings-item">Arial Narrow</div> },
  { value: 2, label: <div style={{fontFamily:'courier new'}} className="flex-iac settings-item">Courier New</div> },
  { value: 3, label: <div style={{fontFamily:'tahoma'}} className="flex-iac settings-item">Tahoma</div> },
  { value: 4, label: <div style={{fontFamily:'times new roman'}} className="flex-iac settings-item">Times New Roman</div> },
  { value: 5, label: <div style={{fontFamily:'verdana'}} className="flex-iac settings-item">Verdana</div> },
];

let configTypes

const SettingsText = (props) =>  {

    let jwt=  localStorage.getItem('JWT')
    let link = localStorage.getItem('link')

    const [selectedConfig, setSelectedConfig] = useState(1);
    const [color, setColor] = useState();
    const [text, setText]  = useState();
    const [selectedFontName, setSelectedFontName]  = useState();
    const [selectedFontSize, setSelectedFontSize]  = useState();
    const [selectedDisplayMode, setSelectedDisplayMode]  = useState();
    const [selectedOrientation, setSelectedOrientation]  = useState();

        function rgbToHex(r, g, b) {
         return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
         }

         function cutHex(h) {return (h.charAt(0)=="#") ? h.substring(1,7):h}
         function hexToR(h) {return parseInt((cutHex(h)).substring(0,2),16)}
         function hexToG(h) {return parseInt((cutHex(h)).substring(2,4),16)}
         function hexToB(h) {return parseInt((cutHex(h)).substring(4,6),16)}
         

         function getFontName(data) {
          for (let i=0; i < optionsTextFonts.length;i++) {
             if (data==optionsTextFonts[i].value) {
              setSelectedFontName(optionsTextFonts[i])
             }
          }
         }

         function updateText(data) {
          setSelectedFontSize(data.fontSize)
          setColor(rgbToHex(data.colorR, data.colorG, data.colorB))
          setText(data.text)
          getFontName(data.fontId)
          setSelectedDisplayMode(data.displayMode)
          setSelectedOrientation(data.orientation)
         }


         useEffect(() => {

    axios.get(link+'/api/TextDisplay/Config?elementId='+props.id, {
      
      headers: {
        Authorization:'Bearer ' + jwt
     }
    
    })  .then((response) => {  

        configTypes= response.data

       for (let i=0; i < configTypes.length;i++) {
        if (configTypes[i].configType==selectedConfig) {
           updateText(configTypes[i])
        }
       }  
    })
  
    .catch(error => {
   
   })
         
      }, [props.data]);


   

      function updateTextRest() {


        for (let i=0; i < configTypes.length;i++) {
          if (configTypes[i].configType==selectedConfig) {

              let data = configTypes[i]

             if (data.displayMode != selectedDisplayMode) {

              axios.put(link+'/api/TextDisplay/DisplayMode?elementId='+props.id+'&configType='+selectedConfig+'&mode='+selectedDisplayMode, {
                headers: {
                  Authorization:'Bearer ' + jwt
               }
              })  .then((response) => {  
                configTypes[i].displayMode=selectedDisplayMode
    
              }
              ).catch(error => { })  }

             if (data.orientation != selectedOrientation) {

              axios.put(link+'/api/TextDisplay/Orientation?elementId='+props.id+'&configType='+selectedConfig+'&orientation='+selectedOrientation, {
                headers: {
                  Authorization:'Bearer ' + jwt
               }
              })  .then((response) => {  
                configTypes[i].orientation=selectedOrientation
              }
              ).catch(error => { })  }

            

            if (data.text != text) {

              axios.put(link+'/api/TextDisplay/Text?elementId='+props.id+'&configType='+selectedConfig, text, {
                headers: {
                  Authorization:'Bearer ' + jwt,
                  'Content-Type': 'text/plain',
               }
              })  
              .then((response) => {  
                configTypes[i].text=text
              }
              ).catch(error => { })
                 
            }

            if (data.fontId != selectedFontName.value) {

              axios.put(link+'/api/TextDisplay/Font?elementId='+props.id+'&configType='+selectedConfig+'&font='+selectedFontName.value+'&fontSize='+selectedFontSize, {
                   headers: {
       Authorization:'Bearer ' + jwt
      }
              })  .then((response) => {  
               configTypes[i].fontName=selectedFontName.value
              }
              ).catch(error => { })  }


            if (data.fontSize != selectedFontSize) {

              axios.put(link+'/api/TextDisplay/Font?elementId='+props.id+'&configType='+selectedConfig+'&font='+selectedFontName.value+'&fontSize='+selectedFontSize, {
                   headers: {
       Authorization:'Bearer ' + jwt
      }
              })  .then((response) => {  
              configTypes[i].fontSize=selectedFontSize
              }
              ).catch(error => { })  }


            if (rgbToHex(data.colorR, data.colorG, data.colorB) != color) {

              let r= hexToR(color)
              let g= hexToG(color)
              let b= hexToB(color)

              axios.put(link+'/api/TextDisplay/Color?elementId='+props.id+'&configType='+selectedConfig+'&r='+r+'&g='+g+'&b='+b, {
                headers: {
                  Authorization:'Bearer ' + jwt
               }
              })  .then((response) => {  
                configTypes[i].colorR=r
                configTypes[i].colorG=g
                configTypes[i].colorB=b
              }
              ).catch(error => { })


            }

          }
         }  
      }


    


    function render() {

      let displayModeNum=false
      let displayModeText=false

        function selectTab(config) {    
          for (let i=0; i < configTypes.length;i++) {
            if (configTypes[i].configType==config) {
              setSelectedConfig(config)
               updateText(configTypes[i])
            }
           }  }
       
           function selectAlignButton(orientation) {    
            setSelectedOrientation(orientation)
           }

           function selectMode(mode) {
            setSelectedDisplayMode(mode)
           }

           if (selectedDisplayMode==0) {
             displayModeNum=true
             displayModeText=false
           }

           else if (selectedDisplayMode==1) {
            displayModeNum=false
            displayModeText=true
          }
          

           $('.ab').removeClass('selected-align-button')
           $('#orientation'+selectedOrientation).addClass('selected-align-button')

           $('.tab').removeClass('selected-tab')
           $('#tab'+selectedConfig).addClass('selected-tab')

           function handleChangeTC(e){
             setColor(e.target.value)  
          }

          function handleChangeFontName(props){
             setSelectedFontName(props)    
          }

          function handleChangeText(e){
            setText(e.target.value)    
         }

        
            return (
              <div id="settings-text"  style={{margin:'auto'}}> 
           
          <div className="settings-name">{props.t('textDisplay.anzeige')}</div> 
            <nav>
        <a className="tab" id="tab1" onClick={()=>selectTab(1)}>{props.t('lot.frei')}</a>
        <a className="tab" id="tab2" onClick={()=>selectTab(2)}>{props.t('lot.besetzt')}</a>
        <a className="tab" id="tab3" onClick={()=>selectTab(3)}>{props.t('lot.modusGesperrt')}</a>         
       </nav>
       <main>
       <div className="label-container">
          <label class="input-container">
          <input onClick={()=>selectMode(0)} class="input-element" checked={displayModeNum} type="radio" name="radio-display" style={{marginRight:'5px'}} /> <div>{props.t('textDisplay.belegungszahl')}</div>
          </label>
          </div>
          <div className="label-container">
          <label class="input-container">
          <input  onClick={()=>selectMode(1)} class="input-element" checked={displayModeText} type="radio" name="radio-display" style={{marginRight:'5px'}}/> <div style={{marginRight:'5px'}}>{props.t('textDisplay.text')}*</div>
          <input type="text" value={text} onChange={handleChangeText} />
          </label>
          </div>
           <div className="flex-sb">
           <Select1 className="select select-text" styles={customStyles} isSearchable={false} value={selectedFontName} onChange={handleChangeFontName}
            options={optionsTextFonts} theme={theme => ({...theme, borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff'}})}/>
       
            <NumericInput className="input-numeric input70" mobile={"auto"} min={1} max={50} value={selectedFontSize} onChange={e => setSelectedFontSize(e)} />
        

            <input type="color" value={color} onChange={handleChangeTC} style={{width:'30px'}}/>
       
            <button id="orientation0" className="align-button ab" title="Left" onClick={()=>selectAlignButton(0)}><i class="fa fa-align-left"></i></button>
            <button id="orientation1" className="align-button ab" title="Center" onClick={()=>selectAlignButton(1)}><i class="fa fa-align-center"></i></button>
            <button id="orientation2" className="align-button ab" title="Right" onClick={()=>selectAlignButton(2)}><i class="fa fa-align-right"></i></button>
            </div> 
       
            <button onClick={updateTextRest} class="button" style={{marginTop:'10px'}}>{props.t('allgemein.speichern')}</button> 
       </main>
       
           <p className="hinweis-text"> {props.t('textDisplay.hinweisFree')}</p>
            </div>
            )}

    return(
        <div style={{width:'90%',marginTop:'10px'}} >
     {render()}
     </div>
     )
   }
 
 
   export default withNamespaces()(SettingsText)


