import React, { useState } from "react";
import Quill from "quill";
import TokenBlot from "./TokenBlot";

const CONFIG = {
  formats: [
  // "font",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "image",
  "color",
  "code-block",],

  theme: "snow",
  modules: {toolbar: [
    [{ 'color': [] }, { 'background': [] }],
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['image'],
  ],}
};

const TOKEN_MATCHER = /\{\{(\w*)\|(\w*)\}\}/gi;

const htmlToTokens = htmlString => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlString, "text/html");
  dom.querySelectorAll(`.${TokenBlot.className}`).forEach(token => {
    const {
      dataset: { id, slug }
    } = token;
    token.replaceWith(`{{${slug}|${id}}}`);
  });
  return dom.body.innerHTML;
};

const tokensToHtml = (tokenizedString, tokensById) => {
  return tokenizedString.replace(TOKEN_MATCHER, (match, slug, id) => {
    const token = tokensById[id];
    if (!token) {
      return match;
    }
    const { title } = token;
    return `<${TokenBlot.tagName} class="${TokenBlot.className}" contenteditable="false" data-title="${title}" data-slug="${slug}" data-id="${id}">${title}</${TokenBlot.tagName}>`;
  });
};

const Editor = ({ value, onChange, quillRef, tokensById }) => {
  const [editor, setEditor] = useState(null);

  const onValueChange = html => onChange(htmlToTokens(html));

  const onMount = container => {
    if (!editor && container) {
      container.innerHTML = tokensToHtml(value, tokensById);
      const quill = new Quill(container, CONFIG);
      quill.on("text-change", () => onValueChange(quill.root.innerHTML));
      setEditor(quill);
      quillRef.current = quill;
    }
  };

  return <div ref={onMount} />;
};

export default Editor;