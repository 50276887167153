import React from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import axios from "axios";
import { Provider } from 'react-redux'

//decode jwt token
import jwt_decode from "jwt-decode";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import AuthLayout from "./layouts/AuthLayout";
import NonAuthLayout from "./layouts/NonAuthLayout";

// Import scss
import "./assets/scss/app.scss";

// Import css
import "./assets/css/all.css";

let link = process.env.REACT_APP_API_URL;
console.debug('API URL: %s', link);

const App = (props) => {

	// Put link and project name into the local storage

	localStorage.setItem('link', link)

	// Get a new token 1 minute before expiration

	async function token() {
		console.debug("token() called");

		while (localStorage.getItem('JWT') == null) 
		{
			console.debug("Could not read JWT from localStorage, delaying");
			await delay(50);
		}

		let decodedjwt = jwt_decode(localStorage.getItem('JWT'));
		let jwt_expired_at = (decodedjwt.exp * 1000)
		let jwt_expirering_in = Date.now() - jwt_expired_at
		let expired = Date.now() >= jwt_expired_at;
		let timeOut
		let expireringBuffer = 600000

		console.debug("token(): ExpAt: %o/%o; ExpIn: %o; exp?: %o", jwt_expired_at, new Date(jwt_expired_at), jwt_expirering_in, expired);
		if (expired) 
		{
			refreshToken()
		}
		else 
		{
			timeOut = setTimeout(refreshToken, (jwt_expirering_in - expireringBuffer))
		}

		function refreshToken() {
			console.debug("Getting new Refresh Token");
			axios.post(link + '/api/Authentication/refresh-token', { refreshToken: localStorage.getItem('JWTR') },)
				.then((response) => {
					localStorage.setItem('JWT', response.data.token)
					localStorage.setItem('JWTR', response.data.refreshToken)
					decodedjwt = jwt_decode(response.data.token);

					jwt_expirering_in = decodedjwt.exp * 1000 - Date.now()
					clearTimeout(timeOut)
					timeOut = setTimeout(refreshToken, (jwt_expirering_in - expireringBuffer))
				}).catch((err) => {
					localStorage.clear()
					window.location.href = '/login'
				})
		}

		function delay(timer) {
			return new Promise(resolve => {
				setTimeout(function () {
					resolve()
				}, timer);
			});
		};
	};

	token()


	const NonAuthmiddleware = ({
		component: Component,
		layout: Layout,
	}) => (
		<Route
			render={props => {
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

	return (
	<React.Fragment>
			<Router>
				<Switch>
					{authRoutes.map((route, idx) => (
						<NonAuthmiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
						/>
					))}
					{userRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={AuthLayout}
							component={route.component}
							key={idx}
						/>
					))}
				</Switch>
			</Router>
	</React.Fragment>

	);
}

export default App;
