import React from "react";
import { MenuItem, SubMenu } from "react-contextmenu";
import updateZoneDisplays from "../../Functions/updateZoneDisplays";
import NumericInput from "react-numeric-input";
import $ from "jquery";
import { withNamespaces } from "react-i18next";
import { handleUpdateLightIntensity } from "./utils";

const ArrowSubMenu = ({ contextId, contextName, title, arrowElement, t }) => {
  const modes = ["Automatic", "ManualOn", "ManualOff"];

  return (
    <SubMenu
      title={
        <div className="submenu-title">
          {" "}
          {t(title)} <i className="fas fa-chevron-right submenu-icon"></i>{" "}
        </div>
      }
      className="submenu"
    >
      {modes.map((mode, index) => (
        <React.Fragment key={index}>
          <MenuItem
            onClick={() =>
              updateZoneDisplays({
                Id: [contextId],
                Name: contextName,
                Mode: index.toString(),
                ArrowElement: arrowElement,
                Type: "ZoneDisplay",
              })
            }
          >
            {t(`zoneDisplay.modes.${mode}`)}
          </MenuItem>
          <MenuItem divider />
        </React.Fragment>
      ))}
    </SubMenu>
  );
};

const ZoneDisplay = ({
  contextId,
  contextName,
  userCanChange,
  contextLightIntensityDisplay,
  showSettings,
  getData,
  t,
}) => {
  const arrowSubMenuItems = [
    "zoneDisplay.leftArrow",
    "zoneDisplay.aheadArrow",
    "zoneDisplay.rightArrow",
    "zoneDisplay.symbol",
  ];

  return (
    <>
      <div id="contextmenu" className="contextmenu">
        <SubMenu
          title={
            <div className="submenu-title">
              {" "}
              {t("allgemein.helligkeit")}{" "}
              <i className="fas fa-chevron-right submenu-icon"></i>{" "}
            </div>
          }
          className="submenu"
        >
          <MenuItem disabled>
            <div className="inputwrapper">
              <NumericInput
                id="Light-Zone"
                className="react-contextmenu-numeric react-contextmenu-item"
                defaultValue={contextLightIntensityDisplay}
                mobile="auto"
                min={0}
                max={100}
              />
              <div className="inputtext contextmenu-item">%%%%%%%%</div>
            </div>
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            className="react-contextmenu-item-send"
            onClick={() =>
              handleUpdateLightIntensity({
                contextId,
                contextName,
                value: $("#Light-Zone").val(),
                type: "ZoneDisplay",
              })
            }
          >
            {t("allgemein.senden")}
          </MenuItem>
        </SubMenu>
        <MenuItem divider />

        {arrowSubMenuItems.map((title, index) => (
          <ArrowSubMenu
            key={index}
            contextId={contextId}
            contextName={contextName}
            title={title}
            arrowElement={index.toString()}
            t={t}
          />
        ))}

        <MenuItem divider />
        <MenuItem
          onClick={() =>
            showSettings({ Data: [getData(contextId)], Type: "ZoneDisplay" })
          }
          className="activeitem"
        >
          {t("allgemein.einstellungen")}
        </MenuItem>
        <MenuItem divider />
        <MenuItem disabled>{contextName}</MenuItem>
        {userCanChange === "true" && (
          <>
            <MenuItem divider />
            <MenuItem disabled>ID: {contextId}</MenuItem>
          </>
        )}
      </div>
      <div>Test div</div>
    </>
  );
};

export default withNamespaces()(ZoneDisplay);
