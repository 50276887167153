import axios from "axios";

const updateReserved = (data) => { 

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')

        axios.put(link+'/api/'+data.Type+'/Reserved?reserved='+data.Reserved, data.Id, {
 
          headers: {
            Authorization:'Bearer ' + jwt
         }
        
        })  .then((response) => {         
          // updateSvg()
        });

  
   //multiple ids

   //
 

}


export default (updateReserved);