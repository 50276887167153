import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import NumericInput from 'react-numeric-input';

let timeLimitInMinutes

const SettingsTimeMonitoring = (props) => {

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')
  let data = props.data
  let mode = props.mode

  const [timeHH, setTimeHH] = useState();
  const [timeMM, setTimeMM] = useState();
  const [timeDD, setTimeDD] = useState();
  const [timeActive, setTimeActive] = useState(false);


  function updateTimeMonitoring() {

    let DD = 0
    let HH = 0
    let MM = 0

    timeDD == null ? DD = 0 : DD = timeDD
    timeHH == null ? HH = 0 : HH = timeHH
    timeMM == null ? MM = 0 : MM = timeMM

    timeLimitInMinutes = (DD * 1440) + (HH * 60) + MM

    let subType = 'Lot';
    switch (mode) {
      case "area":
        subType = 'Area';
        break;
      case "lot":
      default:
          subType = 'Lot';
          break;
    }
    axios.put(link + '/api/'+subType+'/ParkingTimeRestriction?elementId=' + data.id + '&restriction=' + timeLimitInMinutes + '&enabled=' + timeActive, {
      headers: {
        Authorization: 'Bearer ' + jwt
      }

    }).then((response) => {
    })
      .catch(error => {
      })
  }

  useEffect(() => {
    if (data.parkingTimeRestrictionInMinutes != undefined) {
      if (data.parkingTimeRestrictionInMinutes > 0) {
        function TimeLimit(num) {
          let TimeDD = Math.floor(num / 1440);
          let TimeHH = Math.floor((num - (TimeDD * 1440)) / 60);
          let TimeMM = Math.round(num % 60);
          setTimeHH(parseInt(TimeHH));
          setTimeMM(parseInt(TimeMM));
          setTimeDD(parseInt(TimeDD))
        }
        TimeLimit(data.parkingTimeRestrictionInMinutes)
        setTimeActive(data.parkingTimeRestrictionEnabled)
      }
    }
  }, [props.data]);

  return (
    <div className="settings-groups">
      <div className="settings-name">{props.t('elementeAllgemein.zeitueberwachung')}</div>

      <div className="flex-sb">
        <div className="select-switch">
          {props.t('allgemein.aktiv')}
          <label class="switch">
            <input type="checkbox" checked={timeActive} class="switch-input" onChange={() => setTimeActive(current => !current)} />
            <span class="switch-label" data-on={props.t('allgemein.an')} data-off={props.t('allgemein.aus')}></span>
            <span class="switch-handle"></span>
          </label>
        </div>
        <div className="flex-sb" >
          <div className="flex-sb ">
            <div style={{ marginRight: '5px' }}>DD</div>
            <NumericInput value={timeDD} onChange={e => setTimeDD(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={999} />
          </div>
          <div className="flex-sb ">
            <div style={{ marginRight: '5px', marginLeft: '5px' }}>HH</div>
            <NumericInput value={timeHH} onChange={e => setTimeHH(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={23} />
          </div>
          <div className="flex-sb ">
            <div style={{ marginRight: '5px', marginLeft: '5px' }}>MM</div>
            <NumericInput value={timeMM} onChange={e => setTimeMM(e)} className="input-numeric input-we2" mobile={"auto"} min={0} max={59} />
          </div>

        </div>

      </div>
      <button class="button" style={{ marginTop: '10px' }} onClick={updateTimeMonitoring} > {props.t('allgemein.speichern')}</button>
    </div>)

}

export default withNamespaces()(SettingsTimeMonitoring)


