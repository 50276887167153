

import React, {useState, useEffect} from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import ReactLoading from 'react-loading';

import $ from "jquery";
import Checkbox from '@material-ui/core/Checkbox';

import TreeArea from "../../CommonComponents/TreeArea.js";

let pageLoaded=false

let numericalDisplays=[]
let zoneDisplays=[]
let selectedArea=[]
let freeOccDisplays=[]
let textDisplays=[]

let selectedOptionModus


  const Tree= (props) =>  {

    selectedOptionModus=props.modus

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')
    
        const [makeUpdate, setMakeUpdate] = useState(false);
    
        const [loaderShow, setLoaderShow] = useState(false);
    

   useEffect(() => {   
     
  if (pageLoaded) {
    setLoaderShow(false)
  }
  else {
    setLoaderShow(true)   
  }   


  if (pageLoaded==false) {

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');

  axios.get(link+'/api/display', {
  
  })  .then((response) => {

       for (let i=0;i < response.data.length;i++) {
          if (response.data[i].DisplayType=="NumericalDisplay") {
             numericalDisplays.push(response.data[i])
          }

          if (response.data[i].DisplayType=="ZoneDisplay") {
            zoneDisplays.push(response.data[i])
          }

          if (response.data[i].DisplayType=="TextDisplay") {
            textDisplays.push(response.data[i])
          }        
      }

      pageLoaded=true 
      setLoaderShow(false)
   
  });
}

 
   }, []);

  
   
   function selectArea(id,e) {
    if($(e.target).is('img')) {
      return
    }
  
    if (id==selectedArea[0]) {
      selectedArea=[]
    }
    else {
      selectedArea=[]
      selectedArea[0]=id
    }

    setMakeUpdate(current => !current)
  }

// generate tree for mode

  const treeModus= () => {

    let children=[]
    let tree=[]
    let chosenType=[]

     if (selectedOptionModus=="area") {
         return (
         <TreeArea multi={false} page={"calendar"}></TreeArea>
         )
    }

    else {

    if (selectedOptionModus=="numerical") {
      chosenType=numericalDisplays
    }

    else if (selectedOptionModus=="freeOcc") {
      chosenType=freeOccDisplays
    }

    else if (selectedOptionModus=="text") {
      chosenType= textDisplays
    }

    else if (selectedOptionModus=="zone") {
      chosenType= zoneDisplays
    }

    for (let i = 0; i < chosenType.length; i++) {

      let checked=false
      if (selectedArea.includes(chosenType[i].Id)) {
        checked=true
       }

        children.push( 
          <div id={chosenType[i].Id} name={chosenType[i].Name} key={"modus"+chosenType[i].Id} className="park-container" onClick={(e)=>selectArea(chosenType[i].Id, e)}>
          <div className="flex-iac" style={{height:'25px', paddingLeft:'15px'}}>
          <Checkbox checked={checked} color="primary" size="small" style={{position:'absolute',right:'15px'}} id={chosenType[i].Id+"cb"}/>
          <div >{chosenType[i].Name}</div>
          </div>
          </div>
        )   
    }
  
  tree.push(
  <div>
  {children}
  </div>)
  return (tree)
  }
}

// generate tree for text

const treeText= () => {

  let children=[]
  let tree=[]

  for (let i = 0; i < textDisplays.length; i++) {

    let checked=false
    if (selectedArea.includes(textDisplays[i].Id)) {
      checked=true
     }

      children.push( 
        <div id={textDisplays[i].Id} name={textDisplays[i].Name} key={"text"+textDisplays[i].Id} className="park-container" onClick={(e)=>selectArea(textDisplays[i].Id, e)}>
        <div className="flex-iac" style={{height:'25px', paddingLeft:'15px'}}>
        <Checkbox checked={checked} color="primary" size="small" style={{position:'absolute',right:'15px'}} id={textDisplays[i].Id+"cb"}/>
        <div >{textDisplays[i].Name}</div>
        </div>
        </div>
      )   
  }

tree.push(
<div>
{children}
</div>)

return (tree)
}



function renderView() {
    if (props.type=="Light") {
        return(
    <TreeArea multi={false}  page={"calendar"}></TreeArea>
        )
    }

    else if (props.type=="Modus") {
        return(
        treeModus()
        )
    }


    else if (props.type=="Text") {
        return(
            treeText()
        )
        
    }
}

return (
    <div>
           {loaderShow==true && (
        <div className="loader-mini">
        <ReactLoading type={"bars"} color={"#2a3042"} height={50} width={50} name={"Loading"} />
         </div> )}  
         {loaderShow==false && (   
   renderView()
   ) }
    </div>
)




  }

export default withNamespaces()(Tree)
